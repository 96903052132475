import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import $ from 'jquery';
import 'src/assets/lib/datatables/jquery.dataTables.js'; // using the one from npm presents issues
import { SesionstorageService } from '../../../services/sesionstorage.service';

import { dbFullService } from '../../../services/dbFull.service';
@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent implements OnInit {
  @Input() Field: any;
  @Input() TitleSow: any;
  @Input() idSelected: string = '';
  @Input() defaultValue: string = "";
  @Output() ValueSalida = new EventEmitter<string>();
  @Input() disabledInput: boolean | number = 0;
  public idUserEmp: string = '';
  public tableId: string = '';
  public tableClasses: string[] = [];
  public ArrayDataIn: any[]=[]
  public thead: any[]=[]
  public TempTestTableData: any
  public valueUserEmp: string = '';



  constructor( private sesionStorage: SesionstorageService, private dbService: dbFullService) { }

  ngOnInit(): void {
    // console.log(this.Field)
    // console.log(this.TitleSow)
    // console.log(this.idSelected)
    // console.log(this.defaultValue)

    if(this.defaultValue) this.valueUserEmp = this.defaultValue;

    this.ArrayDataIn = this.Field;
    const dato =this.sesionStorage.GetSesionStorage('login')
    if (dato.Data.Type !== 0){
      this.filtradoByEmpresa()
    }

    this.gerUserEmp(this.ArrayDataIn);
    let ArgValues: any[]=[]
    const argHead = ["First Name2", "Last name", "Position", "Start date", "Salary", "E-mail"]
    const value = ["Tiger", "Nixon", "System Architect", "2011/04/25", "$320,800", "t.nixon@datatables.net"]
    const value2 = ["Tiger", "Nixon", "System Architect", "2010/04/25", "$320,800", "t.nixon@datatables.net"]
    ArgValues.push(value)
    ArgValues.push(value2)
    const DatosSend = { title: "El titulo", head: argHead, data: ArgValues }

    this.TempTestTableData = DatosSend
    this.thead = ["ID", "Nombre", "Rif", "Vendedor", "URL", "Status", "Prioridad"]

    /* $("#selectList").select2({
      theme: "bootstrap4",
      placeholder: "Select an option"
    });
    $("#selectList").on("change", () => {
      console.log($(this).val());
    }); */
  }

  // 9b3LEa0Dlp

  

  async filtradoByEmpresa(){      
  
    const storage = this.sesionStorage.GetSesionStorage('login')
    const resp = this.ArrayDataIn.filter((elemento) => {
      return elemento.element.idEmpConv === storage.Data.idEmpConv || elemento.pk.Campo === 'idEmpCat'; 
    });
     
    this.ArrayDataIn = resp;
    // console.log(this.ArrayDataIn);
    // const aver =this.sesionStorage.GetSesionStorage('login')
    // console.log()
    // console.log(aver)
    // const andi = await this.dbService.getDataByEmpresa('cb_EmpCategoria', aver.Data.idEmpConv ) ;
    // console.log(andi)
    // this.ArrayDataIn = [];
    // this.ArrayDataIn.push(andi);
    // console.log(andi)
    // console.log(this.ArrayDataIn[0]);
  }

  setValue(Value: string) {
    this.ValueSalida.emit(Value);
  }

  setValueNgSelect($event: any) {
    console.log($event.value,"$event.value");
     this.ValueSalida.emit($event.value);
  }
  gerUserEmp( data: any[] ) {
    if( data.length > 0 ) {
      for( let enterprise of data ) {
        if( enterprise.EmpresaUser && enterprise.EmpresaUser.length > 0 ) {
          const test = enterprise.EmpresaUser.filter(( user: any ) => user.idUserEmp === this.idSelected );
          test.length === 1 ? this.valueUserEmp = enterprise.value : this.valueUserEmp = '';
        } else {
          if( enterprise.value === this.idSelected ) {
            this.valueUserEmp = enterprise.value;
          }
        }
      }
    }
  }
}
