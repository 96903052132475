import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

@Component({
  selector: 'app-report-allies',
  templateUrl: './reportAllies.component.html',
  styleUrls: ['./reportAllies.component.scss'],
})
export class ReportAlliesComponent implements OnInit, AfterViewInit, OnDestroy{
  @ViewChild("datatable3", { static: true}) datatable3!: ElementRef;
	showMenu: boolean = false;
	showOptions: boolean = false;
	loading: boolean = true;
    currentTable: any[] = []
	private inicializedTable: boolean = false;
	public showFilter: boolean = false;
	public isfilteredTable: boolean = false;
	public errorFechaMax: boolean = false;
	constructor(private dbFull: dbFullService, private fb: FormBuilder, private helper: HelperService,private _sessionStorage:SesionstorageService){

	}
	public data: any[] = [];
	public dataOrganizada: any[] =[];
	public selectEmterprise: any[] = [] 
	selectProm: any[] = ['educación y deportes','entretenimiento','gastronomía','retail','salud y bienestar','tecnología','turismo'];
	public dataAgrouped: any[]=[];
    
	public rowHead: any[] = ["Aliado","FECHA DE INGRESO","RIF", 'DIRECCION FISCAL',"RAZON SOCIAL", "CIUDAD","CATEGORIA","ESTADO ALIADO"];
  	public  agroupByField: string = "ciudad";

	public formG: FormGroup = this.fb.group({
		minD: ['',],
		maxD: ['',],
		selectEnt: ['',],
		selectProm: ['',]
	});



	public obsEnt!: Subscription;
	public table!: any;
	
	public timerFilter!: NodeJS.Timeout; 
	
	ngOnInit(): void {

	}
	ngAfterViewInit(): void {
	this.initData();
	const control =this.formG.get("selectEnt");
		if(control){
		this.obsEnt = control.valueChanges.subscribe(resp=>{
			if(resp.length>0){
			this.selectProm = Object.keys(this.groupBy(this.dataAgrouped[resp],'Categoria'));
			}
		})
		}
	}	
  ngAfterContentInit(): void {
    
  }
    
	initTable() {
		($(this.datatable3.nativeElement) as any).DataTable({
			language: {
				searchPlaceholder: 'Buscar...',
				sSearch: '',
				
				info:'Mostrando _START_ - _END_ de _TOTAL_ registros',
				lengthMenu: 'Mostrar _MENU_',
				paginate:{
					previous:'Anterior',
					next:'Siguiente'
				},
			},
			
			dom: 'Bfrtip',
    buttons: [
        'excelHtml5'
    ]
        });
		
	}
    toOpenMenu() {
        setTimeout(() => {
                document.body.addEventListener("click", this.toCloseMenu);
        }, 200)
        this.showMenu = true;
    }

    toCloseMenu = () => {
        document.body.removeEventListener("click", this.toCloseMenu)
        if(!this.showOptions){
            this.showMenu = false;
        }
        this.showOptions = false;
    }

    getStatistics(): Promise<{status: boolean}>{
        return new Promise(async(resolve,reject)=>{
			const {idEmpConv} = this._sessionStorage.GetSesionStorage("login").Data;
			const query = idEmpConv ? `WHERE idEmpConv='${idEmpConv}'` : ''
            const data: any = <any[]> await this.dbFull.getAnyQuery(environment.bd,`SELECT * FROM ${environment.bd}.Reporte_Aliados ${query}`);
            if(data && data.length>0){
                this.data=data;
				const grupByRIF = this.groupBy(data,'idEmpConv');
				const databyRif: any[][] = Object.values(grupByRIF);
				console.log( databyRif[0][0].Empresa);
				
				const newData: any[] = [];
				console.log( );
				const dataAgrouped: any =  Object.keys(grupByRIF).forEach((element,index)=>{
					let categorias: string[] = this.obtenerValoresUnicos(databyRif[index].map(element=>element.Categoria));
					let arrayStatus: string[] =  databyRif[index].map(element=>element.aliadoStatus); 
					let status: any = arrayStatus.includes("ACTIVO") ? "ACTIVO":  "INACTIVO";
					let dataAgrouped = {
						'Empresa':databyRif[index][0].Empresa,
						'fechaIngreso': new Date(databyRif[index][0].fechaIngreso).toLocaleString('es-VE', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'America/Caracas' }),
						'rif':  databyRif[index][0].rif,
						'direccionFiscal': databyRif[index][0].direccionFiscal,
						'razonSocial': databyRif[index][0].razonSocial,
						'ciudad': databyRif[index][0].ciudad,
						'Categoria': categorias.join(', '),
						'aliadoStatus': status
					}
					this.dataOrganizada.push(dataAgrouped);	
				});
				console.log(this.dataOrganizada);
				resolve({status: true});
            } 
            
            else{reject({status: false})}
        })
        
    }

    export() {  
        if (this.currentTable) {
        	const headers:string[] = []
        	const refHeaders:string[] = []
        	const form = this.formG;
		    headers[0]
			const formCiudades =  String(form.get("selectEnt")?.value.toLowerCase());
			const formCat =  String(form.get("selectProm")?.value.toLowerCase()); 
		
			
		
			let sheets: any[][] = []
		

            //To set row headers
            
        	for (const header of this.rowHead) {
        		headers.push(header)
        	}
          
            for (const refHeader in this.data[0]) {
                refHeaders.push(refHeader)
            }
			let dataTable: any [] = this.currentTable;
			if(formCat.length>0){
				dataTable=  this.data;
			}
            sheets.push(headers)
            for (let i = 0; i < dataTable.length; i++) {
        		let column: any[] = []
                let isvalidSelectFilter = false;
				
				// console.log(refHeaders);
        		for (const header of refHeaders) {   
                    // console.log("CANJEADO "+typeof(this.currentTable[i]['FechaCanjeado']))
					const ciudades: string = dataTable[i]['ciudad'].trim().toLowerCase();
					
					const categorias =dataTable[i]['Categoria'].trim().toLowerCase();
		
						if (
							((formCiudades === "" || formCiudades === null) && (formCat === "" || formCat === null)) ||
							(formCiudades === ciudades && (formCat === null || formCat==""))||
							(formCiudades === null || formCiudades=="") &&  categorias.includes(formCat)||
							(formCiudades === ciudades &&  categorias.includes(formCat)  ) 
					   ){

                         //console.log("la empresa "+form+"Es "+formEmpresa); 
						 isvalidSelectFilter =true;
                    }
					if( header.hasOwnProperty("fechaIngreso") && header == "fechaIngreso"){
						console.log(this.currentTable[i][header]);
						column.push(String(this.helper.getDate(this.currentTable[i][header]) ).toUpperCase())	
					}	
					else{
					if(header !== "idEmpConv") column.push(String(dataTable[i][header]).toUpperCase())
					}
					
				
              }
              
        		
				if(isvalidSelectFilter){
					sheets.push(column)
				}
        		
        	}
			// console.log(this.currentTable);
        	const workbook = XLSX.utils.book_new();
			const tabl_sort = sheets;
        	const worksheet = XLSX.utils.aoa_to_sheet(tabl_sort);
          const max_width = tabl_sort[0].reduce((w, r) => Math.max(w, r[0].length), 20);
          worksheet["!cols"] = [ { wch: max_width },
            {wch:10},  // Ancho de la columna 1
            {wch:45},  // Ancho de la columna 2
            {wch:30}   // Ancho de la columna 3
          ];

        	XLSX.utils.book_append_sheet(workbook, worksheet);
        	var wopts = { bookType:"xlsx", bookSST:false, type:"array" } as const;
        	var wbout = XLSX.write(workbook, wopts);

        	const blob = new Blob([wbout], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"});
        	var reader = new FileReader();

        	reader.onload = (ev) => {
        		const base64URL = reader.result as string;

        		const linkElement = document.createElement("a");
        		linkElement.style.display = "none";
        		linkElement.href = base64URL;
        		linkElement.download = "reportes.xlsx";
        		linkElement.click();
        	}
        	reader.readAsDataURL(blob);
        }
    }

	async initData(){
    try {
      const response: {status: boolean} = await this.getStatistics()
      this.loading = false
     
      response.status 
      ? this.currentTable = this.dataOrganizada
      : this.currentTable = []        
     
      //this.data va a ser tu data original no la modifiques
    setTimeout(() => {
    if(!this. inicializedTable) {
      this. inicializedTable = true;				
  this.table = 	($(this.datatable3.nativeElement) as any).DataTable({
    language: {
      searchPlaceholder: 'Buscar...',
      sSearch: '',
      
      info:'Mostrando _START_ - _END_ de _TOTAL_ registros',
      lengthMenu: 'Mostrar _MENU_',
      paginate:{
        previous:'Anterior',
        next:'Siguiente'
      },
    },
    columnDefs: []
      });
      
      }				 
       
    },500)	
    } catch (error) {
      console.error(error);
    }
     
	}

	filterByCity(){
		
		const formG = this.formG;
		let nombreCiud = formG.get("selectEnt")?.value;
		let nombreCat  = formG.get("selectProm")?.value;	
		typeof(nombreCiud)==='string' ? nombreCiud.toLowerCase() : nombreCiud ;
		($ as any).fn.dataTableExt.afnFiltering.length = 0;
		($ as any).fn.dataTableExt.afnFiltering.push(function (settings:any, data:any, dataIndex:any) { 
			
					

			const dataCiud =  String(data[5].toLowerCase());
			// console.log(dataEmpr);
			const dataCat = String(data[6]).toLowerCase();
			console.log(dataCat);
			console.log(nombreCat); 
			console.log(' son iguales: '+dataCiud.includes(nombreCiud));
			// console.log(nombreEmp, nombrePromo);
		 		if (
				 ((nombreCiud === "" || nombreCiud === null) && (nombreCat === "" || nombreCat === null)) ||

				 (nombreCiud === dataCiud && (nombreCat === null || nombreCat==""))||
				 (nombreCiud === null || nombreCiud=="") &&  dataCat.includes(nombreCat)||
				 (nombreCiud === dataCiud &&  dataCat.includes(nombreCat)  ) 
				){return true;
				}
				else return false;
		});
	
		
		this.table.draw();
		
	}
	showModalFilter(){
		
		this.showFilter=true;
		this.obtenerGrupo();
	}
	hideModalFilter(){
		this.showFilter=false; 
		
		if(this.isfilteredTable){
			
		if(this.timerFilter){
			clearTimeout(this.timerFilter);
		}	
		this.timerFilter = setTimeout(()=>{
			 
				this.filterByCity();
				
			},1000)	
		}else{
			this.formG.reset();
		}
		
	}
	cancelFilter(){
		this.showFilter=false; 
	}
	obtenerGrupo(){
       
		this.dataAgrouped = this.groupBy(this.data,this.agroupByField);
       
		const groupList: any[] = Object.keys(this.dataAgrouped);
		this.selectEmterprise = groupList;
	
		}
	groupBy(array: any[], key: any) {
			return array.reduce((result, currentValue) => {
			if(typeof(currentValue)=="string"){
				(result[currentValue[key].toLowerCase().trim()] = result[currentValue[key].toLowerCase().trim()] || []).push(currentValue);
			}
			else (result[currentValue[key].toLowerCase().trim()] = result[currentValue[key].toLowerCase().trim()] || []).push(currentValue);
		  return result;
		}, {});
	}	
	confirmFilter(){
	const form = this.formG;

	if(form.valid && form.touched){
		this.isfilteredTable=true;
		this.hideModalFilter();
	}else if(form.untouched){ 
		
		this.hideModalFilter();}
     
	}
	stopFilter(){
		
		($ as any).fn.dataTableExt.afnFiltering.length = 0;
		this.isfilteredTable = false;
		this.formG.reset({
			minD: '',
			maxD: '',
			selectEnt: '',
			selectProm: '',
		});
		this.filterByCity();
		this.selectProm=  ['educación y deportes','entretenimiento','gastronomía','retail','salud y bienestar','tecnología','turismo'];
	}
	sortMatriz(matriz: any[][])
	{
	const matrizOrdenada = matriz.slice(1);
	
	// matrizOrdenada.sort((a:any, b:any) => {return a[0].localeCompare(b[0].trim)});
	console.log(matrizOrdenada[0])
		return [matriz[0], ...matrizOrdenada]
	}
	obtenerValoresUnicos(arr: any[]) {
		return [...new Set(arr)];
	  }
	ngOnDestroy(): void {
   // this.stopFilter();
   ($ as any).fn.dataTableExt.afnFiltering.length = 0;
   this.obsEnt.unsubscribe();
  }
}