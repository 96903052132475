<div class='{{"extensible-select-input" + (opened ? " opened" : "")}}' (focusin)="Focus()" (focusout)="OnBlur()">
    <input 
        readonly 
        type="text" 
        class="form-control" 
        (click)="Click($event)"
        [value]="getValue()"
        [disabled]="disabledInput"
    />
    <i class="aside-icon"></i>
    <div class="main-menu" #options_display>
        <button type="button" class="menu-add-item-extensible" (click)="OpenModal()">
            <i class="fa fa-plus"></i> Agregar
        </button>
        <div *ngIf="!Field.length" class="menu-item-extensible-text">
            No existen valores para seleccionar 
        </div>
        <button *ngFor="let opt of Field; let i = index" 
            type="button" 
            class="{{'menu-item-extensible' + (isSelected(opt.value) ? ' selected' : '')}}" 
            (mouseup)="SelectItem(opt.value)"
        >
            <div style="display: flex; gap: 3px; align-items: center;">
                <input 
                    type="checkbox" 
                    [checked]="isSelected(opt.value)" 
                    style="display: inline-block; width: auto;" 
                >
                {{opt.datoShow}}
            </div>
            <div class="dropdown" (mouseup)="$event.stopPropagation()">
                <button type="button" data-toggle="dropdown" class="btn btn-outline-primary" style="float: right;">
                    <i class="icon ion-more" style="cursor: pointer;"></i>
                    <app-table-actions [options]="optionsDropdown" (emitParam)="onSelectAction( opt, i, $event )"></app-table-actions>
                </button>
            </div>
            <!-- <div style="text-align: right; width: 100%"><i class="fa fa-trash delete-icon" (click)="alert(); RemoveItemData.emit(i)"></i></div> -->
        </button>
    </div>

    <app-portal>
        <div *ngIf="modal" class="modal-background" (click)="CloseModal()">
            <div class="modal-container" (click)="ClickContainer($event)" (submit)="AddValueForm($event)">
                <app-new-user-sidebar 
                    [param]='{TitleHead: TitleShow,  Table: TableName,  Inputs: [],  Value:[valueSelected], Type: typeSave}' 
                    [onSubmit]="RegisterToSubmiInEnd"
                    [dataSelected]="selectedElement"
                    (SalidaClickMenu)="Salida($event)"
                ></app-new-user-sidebar>
            </div>
        </div>
    </app-portal>
</div>