import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { SesionstorageService } from './sesionstorage.service';
import { isNull } from 'lodash';
import { ApiClubService } from './api-club.service';
import { TipoInput } from '../models/tipo.input';


interface tm_codigo_tarjeta{
  id_abonado: string;
  name_client?: string;
  codigo: string;
  status_uso?: number;
  updatedAt?: string;
}
interface IData_Cache{
  data: any;
  startB: number;
  len: number;
  totalRecords: number  
  dataFiltered: any
}
interface DataTablesParameters {
	draw: number;
	start: number;
	length: number;
	search: {
	  value: string;
	  regex: boolean;
	};
	order: Array<{
	  column: number;
	  dir: 'asc' | 'desc';
	}>;
	columns: Array<{
	  data: string | number;
	  name: string;
	  searchable: boolean;
	  orderable: boolean;
	  search: {
		value: string;
		regex: boolean;
	  };
	}>;
  filterData: any; 
  }
@Injectable({
  providedIn: 'root'
})

export class DataTableService {
  private cache: { [key: string]: any } = {};
  private cacheExpiryTime = 15 * 60 * 1000; // 15 minutos en milisegundos
  private cacheData: any;
  private couponCacheData: any;
  private url: string = environment.URLApiLocal;
  constructor(private http: HttpClient, private _sessionStorage: SesionstorageService, private apiClub: ApiClubService) {}

  getUsabilityGiftCards = async (datatablesParameter: DataTablesParameters,headerData: any[] =[] ,limit = 100): Promise<any> => {
    const start = datatablesParameter.start;
    const length = datatablesParameter.length;
    const startBatch = Math.floor(start / limit) * limit;
    const searchValue = datatablesParameter.search.value;
    const filterData = datatablesParameter.filterData;
    const areFiltertingData = (searchValue.length > 0);
    try {

      console.log(this.cacheData,"cacheData");
      console.log("datatablesParameter", datatablesParameter); 
      
      // Realiza la búsqueda en el frontend
      if (this.cacheData &&  this.cacheData.startB === startBatch && this.cacheData.len === (startBatch + limit)) {
            const dataCache = areFiltertingData && this.cacheData.dataFiltered ? this.cacheData.dataFiltered : this.cacheData.data; 
    
            let data =  this.searchCoupons(searchValue, headerData, dataCache, filterData);
            let hasValues = data.length>0 && data.length<=10;
            let recordsFiltered = areFiltertingData ? this.cacheData.filteredRecords : this.cacheData.totalRecords ;
            console.log(data);
            console.log(areFiltertingData, "areFiltertingData");
            console.log(recordsFiltered, "recordsFiltered");
            
            this.sortDataByColumn(datatablesParameter.order[0],data,datatablesParameter.columns)
            if(data.length>10){
              const result = this.processCouponData(data, start, length, limit, this.cacheData.totalRecords, recordsFiltered);
              return result;
            }
      }
      if (!this.cacheData ) {
        // const data = this.cacheData.data as any[];
        // const result = this.processGiftCardData(data, start, length, limit, this.cacheData.totalRecords);
        // // Si hay datos en el caché, retornar los datos filtrados
        // return result;
        const responseCouponGiftCard = await this.apiClub.fetchUsabilityGiftCards(this.url,  startBatch,limit, searchValue);
        console.log(responseCouponGiftCard);

        if (responseCouponGiftCard.status) {
          const dataResponse = responseCouponGiftCard;
          this.cacheData = {
            startB: startBatch,
            len: startBatch + limit,
            filteredRecords: dataResponse.filteredRecords
          };
          this.cacheData.startB 
          if(!this.cacheData.totalRecords) this.cacheData.totalRecords = dataResponse.totalRecords;
          if(areFiltertingData) this.cacheData.dataFiltered = dataResponse.data;
          else this.cacheData.data = dataResponse.data;
          let data = dataResponse.data;
          console.log(dataResponse);
            
          this.sortDataByColumn(datatablesParameter.order[0],data,datatablesParameter.columns)
          return this.processCouponData(data, start, length, limit, dataResponse.totalRecords, dataResponse.filteredRecords);
        }
      
        else {
          return {
            data: [],
            filteredRecords: 0,
            totalRecords: 0
          };
        }
      } 
      else if(this.cacheData && (areFiltertingData || this.cacheData.startB !== startBatch  ||  this.cacheData.len == (startBatch + limit) )){
        const responseGiftCard = await this.apiClub.fetchUsabilityGiftCards(this.url, startBatch, limit, searchValue);
        if (responseGiftCard.status) {
          const dataResponse = responseGiftCard;

          this.cacheData.startB = startBatch;
          this.cacheData.len = startBatch + limit;
          this.cacheData.filteredRecords = dataResponse.filteredRecords;
          
          if(!this.cacheData.totalRecords) this.cacheData.totalRecords = dataResponse.totalRecords;
          if(areFiltertingData) this.cacheData.dataFiltered = dataResponse.data;
          else this.cacheData.data = dataResponse.data;
          
          console.log(this.cacheData);
          let data = dataResponse.data;
          this.sortDataByColumn(datatablesParameter.order[0],data,datatablesParameter.columns)
          return this.processCouponData(data, startBatch, length, limit, dataResponse.totalRecords, dataResponse.filteredRecords);
        } 
        else {
          return {
            data: [],
            filteredRecords: 0,
            totalRecords: 0
          };
        }
      }
      let data = this.cacheData.data as any[];
      // if(filterData){
      //   data = this.filterData(data, headerData, filterData);
      // }

      this.sortDataByColumn(datatablesParameter.order[0],data,datatablesParameter.columns)
      // Actualizar caché si es necesario
      const result = this.processCouponData(data, start, length, limit, this.cacheData.totalRecords, this.cacheData.totalRecords);
      return result;
    } catch (error) {
      console.error('Error en getUsabilityGiftCards:', error);
      return {
        data: [],
        filteredRecords: 0,
        totalRecords: 0
      };
    }
  };
  
  
  // async getAllData(parameters: DataTablesParameters,columnsInputs:TipoInput[]): Promise<any[]> {
  //   const batchSize = 100000;
  //   // parameters.length = batchSize;
  //   let allData: any[] = [];
  //   let start = 0;
  
  //   while (true) {
  //     const response = await this.getUsabilityGiftCards(parameters,columnsInputs,batchSize);
  //     const responseData: tm_codigo_tarjeta[] = response.data;
  //     allData = allData.concat(responseData.map((data: any) => {
  //       return {
  //         id_abonado: data.id_abonado,
  //         name_client: data.name_client ?? 'S/I',
  //         codigo: data.codigo,
  //         status_uso: data.status_uso ? 'ACTIVO' : 'INACTIVO',
  //         updatedAt: data.updatedAt ? new Date(data.updatedAt).toLocaleDateString('es-VE', { day: '2-digit', month: '2-digit', year: 'numeric' }) : ''
  //       }; 
  //     }));
  
  //     if (response.data.length < batchSize) {
  //       break;
  //     }
  
  //     start += batchSize;
  //   }
  //   return allData;
  // }
  async getAllData(parameters: DataTablesParameters,columnsInputs:TipoInput[]) {
		const batchSize = 10000;
		let allData: any[] = [];
		let start = 0;
		parameters.length = batchSize;
		while (true) {
			parameters.start = start;
			const response = await this.apiClub.fetchUsabilityGiftCards(environment.URLApiLocal,start, batchSize,  parameters.search.value);
			const responseData = response.data;

			allData = allData.concat(responseData.map((data: any) => {
				const row: any = {};
				const columns = columnsInputs.filter((input: TipoInput) => input.ShowList== 1) ;
				columns.forEach((input: TipoInput) => {
					if (this.TipoDato[input.TipoDato].hasOwnProperty('export')) {
            row[input.Campo] = data[input.Campo] !== undefined ||  data[input.Campo] !== null ? (this.TipoDato[input.TipoDato]?.export || (this.TipoDato[input.TipoDato]?.render))(data[input.Campo]) : '';
          } else {            
						row[input.Campo] = data[input.Campo] !== undefined ||  data[input.Campo] !== null ? this.TipoDato[input.TipoDato].render(data[input.Campo]) : '';
					}
				});
				return row;
			}));

			if (responseData.length < batchSize) {
				break;
			}

			start += batchSize;
		}
		return allData;
	}
  getLengthDataGiftcard(){
      return new Promise(async (resolve, reject) => {
        const headers = {
          'x-data-query': `SELECT COUNT(*) as dataLen FROM tm_codigo_tarjeta`
        };
  
        axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.dbGiftCard}`, { headers })
          .then((resp: any) => {
            const response = resp.data[0];
            console.log(response);
            
            if(response.datalen) resolve(Number(response.datalen))
              throw 'No se encuentra la tabla '
          })
          .catch((error: any) => reject(error));
      })
  } 
  
  processGiftCardData(data: any[], start: number, length: number, limit: number, totalRecords: number): any {
    console.log(data);
    return {
      data: data.slice(start % limit, (start % limit) + length),
      filteredRecords: data.length,
      totalRecords
    };
  }
  getCouponReportPaginated(dataTablesParameters: any): Promise<any> {
    const params = {
      idEmpConv: dataTablesParameters.search.value,
      lim: 100,
      off: dataTablesParameters.start
    };
    const token = this._sessionStorage.GetSesionStorage('token');
    return axios.get(`${environment.URLApiLocal}/ReportCoupons`, { params, headers: { 'tknlg': `Bearer ${token}` } }).then((response) => {
      return response.data;
    });
  }

  // Método para obtener reporte de cupones con caché
  async getCouponReportCached(dataTablesParameters: DataTablesParameters, headerData: any[], limit = 100): Promise<any> {
    const start = dataTablesParameters.start;
    const length = dataTablesParameters.length;
    const startBatch = Math.floor(start / limit) * limit;
    const searchValue = dataTablesParameters.search.value;
    const idEmpConv = "";
    const filterData = dataTablesParameters.filterData;
    try {
      console.log(dataTablesParameters);
      const areFiltertingData = (searchValue || Object.keys(filterData).length>0);
      // Realiza la búsqueda en el frontend
      if (this.couponCacheData &&  this.couponCacheData.startB === startBatch && this.couponCacheData.len === (startBatch + limit)) {
        const dataCache = areFiltertingData && this.couponCacheData.dataFiltered ? this.couponCacheData.dataFiltered : this.couponCacheData.data; 

        let data =  this.searchCoupons(searchValue, headerData, dataCache, filterData);
        let hasValues = data.length>0 && data.length<=10;
        let recordsFiltered = areFiltertingData ? this.couponCacheData.filteredRecords : this.couponCacheData.totalRecords ;
        console.log(data);
        console.log(areFiltertingData, "areFiltertingData");
        console.log(recordsFiltered, "recordsFiltered");
        
        this.sortDataByColumn(dataTablesParameters.order[0],data,dataTablesParameters.columns)
        if(data.length>10){
          const result = this.processCouponData(data, start, length, limit, this.couponCacheData.totalRecords, recordsFiltered);
          return result;
        }
        
        // Si no se encuentran datos localmente, llama al backend
        // if (data.length === 0 || hasValues || this.couponCacheData.len !== (startBatch + limit) ) { 
        //   // Realiza la búsqueda en el backend
        //   let start = hasValues ? data.length : startBatch ;
         
        //   const responseCouponReport = await this.apiClub.fetchCouponReport(this.url, limit, start, searchValue,filterData ,idEmpConv);
        //   if (responseCouponReport.status) {
        //     if(!hasValues) 
        //       data = responseCouponReport.data; 
        //     else {
        //       data = [...data, ...responseCouponReport.data]; 

        //     }
        //     // Actualizar caché si es necesario
        //     if(areFiltertingData) this.couponCacheData.dataFiltered = data;
        //     else this.couponCacheData.data = [...this.couponCacheData.data, ...data];
        //     recordsFiltered = responseCouponReport.filteredRecords ?? data.length;
        //     this.couponCacheData.filteredRecords = responseCouponReport.filteredRecords;
        //   }
        // }
        
        // const result = this.processCouponData(data, start, length, limit, this.couponCacheData.totalRecords, recordsFiltered);
        // // if(areFiltertingData && result.data.length < length) {
        // //   const responseCouponReport = await this.apiClub.fetchCouponReport(this.url, limit, startBatch, searchValue,filterData ,idEmpConv);
        //   // if (responseCouponReport.status) {
        //   //   const dataResponse = responseCouponReport;
        //   //   let data = dataResponse.data;
        //   //   this.couponCacheData.dataFiltered = data;
        //   //   return this.processCouponData(data, start, length, limit, dataResponse.totalRecords, dataResponse.filteredRecords);
        //   // }
        // // }
        // return result;
      }
      // Si no se ha realizado una búsqueda o no hay datos en caché, consulta el backend
      if (!this.couponCacheData) {
        console.log(start);

        const responseCouponReport = await this.apiClub.fetchCouponReport(this.url, limit, startBatch, searchValue,filterData);
        if (responseCouponReport.status) {
          const dataResponse = responseCouponReport;
          this.couponCacheData = {
            startB: startBatch,
            len: startBatch + limit,
            filteredRecords: dataResponse.filteredRecords
          };
          this.couponCacheData.startB 
          if(!this.couponCacheData.totalRecords) this.couponCacheData.totalRecords = dataResponse.totalRecords;
          if(areFiltertingData) this.couponCacheData.dataFiltered = dataResponse.data;
          else this.couponCacheData.data = dataResponse.data;
          let data = dataResponse.data;
                  console.log(this.couponCacheData);
                  
          this.sortDataByColumn(dataTablesParameters.order[0],data,dataTablesParameters.columns)
          return this.processCouponData(data, start, length, limit, dataResponse.totalRecords, dataResponse.filteredRecords);
        }
      
        else {
          return {
            data: [],
            filteredRecords: 0,
            totalRecords: 0
          };
        }
      }
      else if(this.couponCacheData && (areFiltertingData || this.couponCacheData.startB !== startBatch || this.couponCacheData.len !== (startBatch + limit))){

        const responseCouponReport = await this.apiClub.fetchCouponReport(this.url, limit, startBatch, searchValue,filterData);
        if (responseCouponReport.status) {
          const dataResponse = responseCouponReport;

          this.couponCacheData.startB = startBatch;
          this.couponCacheData.len = startBatch + limit;
          this.couponCacheData.filteredRecords = dataResponse.filteredRecords;
          
          if(!this.couponCacheData.totalRecords) this.couponCacheData.totalRecords = dataResponse.totalRecords;
          if(areFiltertingData) this.couponCacheData.dataFiltered = dataResponse.data;
          else this.couponCacheData.data = dataResponse.data;
          
          console.log(this.couponCacheData);
          let data = dataResponse.data;
          this.sortDataByColumn(dataTablesParameters.order[0],data,dataTablesParameters.columns)
          return this.processCouponData(data, startBatch, length, limit, dataResponse.totalRecords, dataResponse.filteredRecords);
        } 
      }    
      // Si hay datos en caché, úsalos
      let data = this.couponCacheData.data as any[];
      // if(filterData){
      //   data = this.filterData(data, headerData, filterData);
      // }

      this.sortDataByColumn(dataTablesParameters.order[0],data,dataTablesParameters.columns)
      // Actualizar caché si es necesario
      const result = this.processCouponData(data, start, length, limit, this.couponCacheData.totalRecords, this.couponCacheData.totalRecords);
      return result;
    } catch (error) {
      console.error('Error en getCouponReportCached:', error);
      return {
        data: [],
        filteredRecords: 0,
        totalRecords: 0
      };
    }
  }
  

  searchCoupons(searchValue: string, headerData: TipoInput[], data: any[], filterObject: { [key: string]: { min?: any, max?: any, value?: any } }): any[] {

    
    const lowerSearchValue = searchValue.toLowerCase();
    
    // Filtramos los datos basados en los filtros proporcionados
    const filteredData =  filterObject && Object.keys(filterObject).length >0 ? this.applyFilter(data, headerData, filterObject) : data;
  
    // console.log("Datos filtrados:", filteredData);
    // Luego realizamos la búsqueda en los datos filtrados
    const searchableFields = headerData.filter(field => ['String', 'Area'].includes(field.TipoDato));
    
    
    if (searchableFields.length === 0) {
      return filteredData;
    }
  
    const resultData = filteredData.filter(item => {
      return searchableFields.some(field => {
        const value = item[field.Campo];
        return value && value.toString().toLowerCase().includes(lowerSearchValue);
      });
    });
  
    // console.log("Datos filtrados:", resultData);
    
    return resultData;
  }
  

  applyFilter(data: any[], headerData: TipoInput[], filterObject: { [key: string]: { min?: any, max?: any, value?: any } }): any[] {
    return data.filter(item => {
      return headerData.every(field => {
        const value = item[field.Campo] ?? null;

        const filter:any = filterObject[field.Campo];
        // console.log(value, typeof value, filter);
        
        if (!filter || filter === undefined) return true; 
        if (field.TipoDato === 'Number') {
          return (!filter.min || value >= filter.min) && (!filter.max || value <= filter.max);
        } else if (field.TipoDato === 'Date' ) {
          const dateValue = new Date(value).getTime();
          const minDateValue = filter.min ? new Date(filter.min).getTime() : null;
          const maxDateValue = filter.max ? new Date(filter.max).getTime() : null;
          return (!minDateValue || dateValue >= minDateValue) && (!maxDateValue || dateValue <= maxDateValue);
        } else {
          console.log(value);
          return value && value.toString().toLowerCase().includes(filter.toLowerCase());
        }
      });
    });
  }
  
  // Método para procesar datos de cupones
  processCouponData(data: any[], start: number, length: number, limit: number, totalRecords: number, filteredRecords: number): any {
    return {
      data: data.slice(start % limit, (start % limit) + length),
      filteredRecords,
      totalRecords
    };
  }
  //Crea un metodo para poder realizar un ordenamiento de los datos segun la columna y el objeto order
  sortDataByColumn( dataOrder: {column: number,dir: string}, data: any[], columnData:{ data: any, name: string, orderable: boolean, search: { value: string, regex: boolean }, searchable: boolean }[] ) {
    const columnName = columnData[dataOrder.column].data;
    console.log(columnName);
    console.log(data);
    const sortedData = data.sort((a, b) => {
      const aValue = a[columnName];
      const bValue = b[columnName];
      if (aValue < bValue) {
        return dataOrder.dir === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
        return dataOrder.dir === 'asc' ? 1 : -1;
      }
      return 0;
    });
    // return sortedData;
  }
  onCleanData(){
    this.cacheData = {};
    this.couponCacheData = {};
  }  

  public TipoDato: { [key: string]: { descripcion: string, render: (data: any) => string , export?: (data: any) => string } } = {
		Area: {
		  descripcion: 'Área de texto',
		  render: (data: any) => data ? data : 'N/A'
		},
    Codigo:{
      descripcion: 'Codigo',
		  render:  (data: any) => data ? data.slice(-2).padStart(data.length, '*') : 'N/A',
      export: (data: any) => data ? data.toString() : 'N/A'
    },
		AutoIncrement: {
		  descripcion: 'Autoincremento',
		  render: (data: any) => Number.isInteger(data) ? data : 'N/A'
		},
		Avatar: {
		  descripcion: 'Imagen de avatar',
		  render: (data: any) => data ? `<img src="${data}" alt="Avatar" style="width:50px; height:50px;"/>` : 'N/A'
		},
		Boll: {
		  descripcion: 'Booleano',
		  render: (data: any) => data ? 'Sí' : 'No'
		},
		Date: {
		  descripcion: 'Fecha',
		  render: (data: any) => data ? new Date(data).toLocaleDateString('es-VE') : 'N/A'
		},
		TimeHour: {
			descripcion: 'Hora',
			//  render: (data: any) => data 
			render: (data: any) => { return data ?? "N/A"; }
				
		  },
		DateHour: {
	    	descripcion: 'Hora',
			//  render: (data: any) => data 
			render: (data: any) => {
				
				const date = new Date(data);
				console.log(data);
				const hours = date.getUTCHours().toString().padStart(2, '0');
				const minutes = date.getUTCMinutes().toString().padStart(2, '0');
				const seconds = date.getUTCSeconds().toString().padStart(2, '0');
				if(hours === 'NaN' || minutes === 'NaN' || seconds === 'NaN') return data ?? "N/A";
				const timeString = `${hours}:${minutes}:${seconds}`;
				return timeString;
			}
				
		  },
		Diccionario: {
		  descripcion: 'Diccionario',
		  render: (data: any) => data ? JSON.stringify(data) : 'N/A'
		},
		eAvatar: {
		  descripcion: 'Avatar extendido',
		  render: (data: any) => data ? `<img src="${data}" alt="Avatar" style="width:50px; height:50px;"/>` : 'N/A'
		},
		Email: {
		  descripcion: 'Correo electrónico',
		  render: (data: any) => data ? `<a href="mailto:${data}">${data}</a>` : 'N/A'
		},
		Map: {
		  descripcion: 'Mapa',
		  render: (data: any) => data ? data : 'N/A'
		},
		Money: {
		  descripcion: 'Dinero',
		  render: (data: any) => data ? `$${parseFloat(data).toFixed(2)}` : 'N/A'
		},
		Movil: {
		  descripcion: 'Número de móvil',
		  render: (data: any) => data ? data : 'N/A'
		},
		Name: {
		  descripcion: 'Nombre',
		  render: (data: any) => data ? data : 'N/A'
		},
		Number: {
		  descripcion: 'Número',
		  render: (data: any) => !isNaN(data) ? data : 'N/A'
		},
		Numeric: {
		  descripcion: 'Numérico',
		  render: (data: any) => !isNaN(data) ? data : 'N/A'
		},
		Password: {
		  descripcion: 'Contraseña',
		  render: (data: any) => data ? '******' : 'N/A'
		},
		String: {
		  descripcion: 'Cadena de texto',
		  render: (data: any) => data ? data : 'N/A'
		},
		Switch: {
		  descripcion: 'Interruptor',
		  render: (data: any) => (typeof data === 'number' && data === 1) || data === true ? 'ACTIVO' : 'INACTIVO'
		}
	  
	};
}
