<app-card [cardData]="cards"></app-card>

<div class="grid-charts">
  <!-- <app-charts [dataSimpleToChart]="empresaDataChart" [idChart]="configEmpresaChart"></app-charts> -->
  <!-- <app-charts [dataSimpleToChart]="productosDataChart" [idChart]="idProductosChart"></app-charts>
    <app-charts [dataDoubleChart]="cuponesDataChart" [idChart]="idCuponesChart"></app-charts> -->
  <div *ngIf="!charts.length" class="alerta">
    No posee datos para mostrar
  </div>
   
  <!-- <app-chart *ngFor="let chartItem of charts"
    [type]="'area'"
    [title]="chartItem.title"
    [data]="chartItem.data"
    [formatterText]="chartItem.formatter || formatterToInt"
  ></app-chart> -->


    <!-- (select)="" -->
</div>
<!-- <div class="espaciador"></div> -->
<!-- <select #selectList    
[(ngModel)]="defaultValue">
 <option value="0">30 DIAS</option>
 <option value="1">3 MESES</option>
 <option value="2">1 AÑO</option>
 <option value="3">TODO</option>
</select> -->
<div class="content_tab">
  <div  class="tab_item" [ngClass]="{'active-item': chartItem.title == currentChart?.title}" *ngFor="let chartItem of charts" (click)="switchButton(chartItem.title)">{{chartItem.title}}

  </div>
</div>

<app-chart  *ngIf="currentChart" 
[type]="'area'"
[rangeDate]="defaultValue"
[title]="currentChart.title"
[data]="currentChart.data"
[formatterText]="currentChart.formatter || formatterToInt"
></app-chart>
<!-- <app-map></app-map> -->