import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-movil',
  templateUrl: './movil.component.html',
  styleUrls: ['./movil.component.scss']
})
export class MovilComponent {
  @Input() data: TipoInput = {} as any;
  @Input() value: any = "";
  @Input() formData: IFormData = new IFormData();
  // @Output() outdata = new EventEmitter<{name: string, value: string, isInv
  
  ngOnInit() {
    this.formData.vars[this.data.Campo] = this.formData.vars[this.data.Campo] || "";
  }
  
  HandlerOnKey() {
    delete this.formData.error[this.data.Campo];
  }
  allowOnlyNumbers(event: KeyboardEvent): void {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) { // Solo permite números (códigos ASCII 48-57)
        event.preventDefault();
    }
  }
  onPaste(event: ClipboardEvent): void {
    event.preventDefault(); // Previene el comportamiento predeterminado de pegado
    const pastedText = event.clipboardData?.getData('text/plain'); // Obtiene el texto pegado
    if (pastedText) {
        const sanitizedText = pastedText.replace(/[^0-9]/g, ''); // Elimina caracteres no numéricos
        (event.target as HTMLInputElement).value = sanitizedText; // Actualiza el valor del campo
    }
    this.formatPhoneNumber(event);
  }
// Función para permitir solo números y guiones al escribir
allowOnlyNumbersAndHyphen(event: KeyboardEvent): void {
  const charCode = event.which ? event.which : event.keyCode;
  // Permite números (48-57) y el guion (-), código ASCII 45
  if ((charCode < 48 || charCode > 57) && charCode !== 45) {
      event.preventDefault();
  }
}

// Función para formatear el número de teléfono según las reglas específicas
formatPhoneNumber(event: Event): void {
  const inputElement = event.target as HTMLInputElement;
  let value = inputElement.value.replace(/[^0-9]/g, ''); // Elimina cualquier carácter no numérico

  // Determina si el número inicia con "0" o "58"
  if (value.startsWith('0')) {
      // Prefijo "0": Guion después de los primeros 4 dígitos
      if (value.length > 4) {
          value = value.slice(0, 4) + '-' + value.slice(4);
      }
  } else if (value.startsWith('58')) {
      // Prefijo "58": Guion después de los primeros 6 dígitos
      if (value.length > 5) {
          value = value.slice(0, 5) + '-' + value.slice(5);
      }
  }

  // Actualiza el valor del campo con el formato correcto
  inputElement.value = value;
}
// Función para sanitizar el input en tiempo real
  sanitizeInput(event: Event): void {
      const inputElement = event.target as HTMLInputElement;
      inputElement.value = inputElement.value.replace(/[^0-9]/g, ''); // Elimina caracteres no numéricos
  }
  HanderOnChange(event: any) {
    // console.log(event.target.value)
    
    let value: string = event.target.value;

    // const invalidRegExp = Boolean(this.data.ExpresionReg && RegExp(this.data.ExpresionReg).test(value) === false);
    // const invalidMaxLength = Boolean(this.data.MaxLen !== null && value.length > this.data.MaxLen);
    // const invalidRequired = Boolean(this.data.Requerido && value.length === 0);

    // // fix data
    // if(invalidMaxLength) {
    //   value = value.slice(0, this.data.MaxLen);
    // }
    
    // event.target.value = value;
    
    // if(invalidRegExp) {
    //   this.msgInvalid = `El valor no cumple con la estructura deseada`;
    // }
    // else if(invalidRequired) {
    //   this.msgInvalid = `Campo requerido*`
    // }
    // else {
    //   delete this.msgInvalid;
    // }

    // console.log({name: this.data.Campo, value: value, isInvalid: Boolean(this.msgInvalid)})
    // this.outdata.emit({name: this.data.Campo, value: event.target.value, isInvalid: Boolean(this.msgInvalid)});
    delete this.formData.error[this.data.Campo];
    this.formData.vars[this.data.Campo] = value.trim();
  }
}
