<!-- Select -->
<!-- <div class="col-lg-12 mg-t-20 mg-lg-t-0"> -->

  <!-- <select #selectList class="form-control select2-show-search" id="select2-state" data-placeholder="Choose one (with searchbox)" (change)="setValue(selectList.value)"
  [disabled]="disabledInput"
  >
  <input  class="form-control"  type="text" name="" id="">
    <option *ngFor="let title of ArrayDataIn" [value]="title.value" [selected]="title.value === valueUserEmp">{{title.datoShow}}</option> 
  </select> -->
  <!-- NUEVO SELECT CON BUSCADOR INTEGRADO -->
  <ng-select [ngClass]="'custom-class'" #selectList 
    [(ngModel)]="defaultValue"
    [items]="ArrayDataIn"
    bindLabel="datoShow"
    bindValue="value"
    (change)="setValueNgSelect($event)"
  >
  </ng-select>
<!-- </div> -->
 <!--
<app-list-table1 [FieldAndHead]="'TempTestTableData'"></app-list-table1>-->


