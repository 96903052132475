import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-numeric',
  templateUrl: './numeric.component.html',
  styleUrls: ['./numeric.component.scss']
})
export class NumericComponent {

  @Input() data: TipoInput = {} as any;
  @Input() value: any = "Value test";
  @Input() formData: IFormData = new IFormData();
  @Output() emitter: EventEmitter<any> = new EventEmitter();
  // @Output() outdata = new EventEmitter<{name: string, value: string, isInv
  
  ngOnInit() {
    this.formData.vars[this.data.Campo] = this.formData.vars[this.data.Campo] ?? 0;
    // console.log(this.value)
  }
  
  HandlerOnKey(event: any) {
    // console.log(event.target.value)
    delete this.formData.error[this.data.Campo];
  }
  
  HanderOnChange(event: any) {
    
    const inputElement = event.target as HTMLInputElement;
    delete this.formData.error[this.data.Campo];
    let value: string = event.target.value;
    if (value.length > 1 && value.startsWith('0')) {
      value = value.replace(/^0+/, '');
  }
    this.formData.vars[this.data.Campo] = value;
    
    inputElement.value = value;
    console.log(value);
    
  }
}
