import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';



interface FormDataActive {
  vars: Record<string, any>;
  error: Record<string, string>;
}

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss']
})
export class FilterTableComponent implements OnInit{
  @Input () columnsInput: TipoInput[] = [];
  @Input () formDatas: IFormData[] = [];
  @Output () outdata = new EventEmitter<any>();
  public step: number = 0;
  @Output () SalidaClickMenu =new EventEmitter<any>();


  ngOnInit(): void {
    console.log(this.formDatas[this.step]);
    if(Object.keys(this.formDatas[this.step].vars).length>0) {
            
    }
  }

 get FormDataActive() {
  if(!this.formDatas[this.step]) {
    this.formDatas[this.step] = new IFormData();
  }
  return this.formDatas[this.step];
}

EventoDinamic(event: any, itemForm: any) {

  const campo = itemForm.Campo;
  console.log(this.FormDataActive);
  this.FormDataActive.vars[campo] = event;
}
emitDate($event: any, campo: string, typeRangeDate: string) {
  if (!this.FormDataActive.vars[campo]) {
    this.FormDataActive.vars[campo] = {};
  }
  // this.FormDataActive.vars[campo][typeRangeDate] = $event.value;
  this.FormDataActive.vars[`${campo}_${typeRangeDate}`] = $event.value;
  console.log(this.FormDataActive.vars);
}
getRangeInputValue(value: any,key: string){
  if(!value) return "";
  return value.hasOwnProperty(key) ? value[key] : value; 
}
onSubmit($event: any) {
  if (!this.columnsInput || !this.FormDataActive?.vars) {
    console.error('Datos de entrada no válidos');
    return;
  }

  const formValues = { ...this.FormDataActive.vars };
  const processedOutput: Record<string, any> = {};
  const errors: Record<string, string> = {};
  let hasErrors = false;

  //Validacion y asignacion de los datos que se enviaran al output
  this.columnsInput.forEach(item => {
    if (item.TipoDato === 'Date' || item.TipoDato === 'Number') {
      this.processRangeField(item, formValues, processedOutput, errors);
    } else {
      this.processSimpleField(item, formValues, processedOutput);
    }
  });

  this.FormDataActive.error = errors;
  hasErrors = Object.keys(errors).length > 0;
  
  if (hasErrors) {
    return;
  }

  this.outdata.emit(processedOutput);
}

private processRangeField(
  item: TipoInput,
  formValues: Record<string, any>,
  output: Record<string, any>,
  errors: Record<string, string>
): void {
  const min = formValues[`${item.Campo}_min`];
  const max = formValues[`${item.Campo}_max`];

  if (min && max) {
    if (this.validateRange(item, formValues)) {
      output[item.Campo] = { min, max };
    } else {
      errors[item.Campo] = item.TipoDato === 'Date'
        ? 'El rango de fechas es incorrecto'
        : 'El rango de números es incorrecto';
    }
  } else if (min || max) {
    errors[item.Campo] = 'Debe completar ambos valores del rango';
  }
}

private processSimpleField(
  item: TipoInput,
  formValues: Record<string, any>,
  output: Record<string, any>
): void {
  if (formValues[item.Campo] !== '' && formValues[item.Campo] !== null) {
    output[item.Campo] = formValues[item.Campo];
  }
}

private validateRange(item: TipoInput, formValues: Record<string, any>): boolean {
  const min = formValues[`${item.Campo}_min`];
  const max = formValues[`${item.Campo}_max`];

  if (item.TipoDato === 'Date') {
    return new Date(min) <= new Date(max);
  } else if (item.TipoDato === 'Number') {
    return Number(min) <= Number(max);
  }
  return true;
}
onClose() {
  this.SalidaClickMenu.emit({evento: 'cerrar'});
}
}
