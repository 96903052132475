import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ConfigurationService } from './services/configuration.service';
import { dbFullService } from './services/dbFull.service';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { HttpErrorComponent } from './pages/http-error/http-error.component';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { CoreSidebarModule } from 'src/@core/components/core-sidebar/core-sidebar.module';
import { MasterCompanyComponent } from './pages/master-company/master-company.component';
import { MasterProductsComponent } from './pages/master-products/master-products.component';
import { MasterUserCompanyComponent } from './pages/master-user-company/master-user-company.component';
import { MasterPromotionsComponent } from './pages/master-promotions/master-promotions.component';
import { ConfigCompanyCategoryComponent } from './pages/config-company-category/config-company-category.component';
import { ConfigProductsCategoryComponent } from './pages/config-products-category/config-products-category.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PromotionsTagComponent } from './pages/promotions-tag/promotions-tag.component';
import { CouponsTagComponent } from './pages/coupons-tag/coupons-tag.component';
import { PerfilComponent } from './pages/dashboard/layout/perfil/perfil.component';
import { ConfigFranquiciasComponent } from './pages/config-franquicias/config-franquicias.component';
import { MasterPromotionsNewComponent } from './pages/master-promotions-new/master-promotions-new.component';
import { MasterLogsComponent } from './pages/master-logs/master-logs.component';
import { ChartsComponent } from './shared/components/charts/charts.component';
import { HomeComponent } from './pages/home/home.component';
import { MasterFranchiseComponent } from './pages/master-franchise/master-franchise.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MasterNewCompanyComponent } from './pages/master-new-company/master-new-company.component';
import { MasterAprobacionesComponent } from './pages/master-aprobaciones/master-aprobaciones.component';
import { MasterViewAprobacionesComponent } from './pages/master-view-aprobaciones/master-view-aprobaciones.component';
import { MasterValidatorqrComponent } from './pages/master-validatorqr/master-validatorqr.component';
import { StatisticsComponent } from './pages/statistics/statistics.component';
import { BannersComponent } from './pages/banners/banners.component';
import { ReportAlliesComponent } from './pages/statistics/reportAllies/reportAllies.component';
import { ReportCouponsComponent } from './pages/statistics/reportCoupons/reportCoupons.component';
import { MasterQRComponent } from './pages/masterQR/masterQR.component';
import { MasterPromoScoreComponent } from './pages/master-promo-score/master-promo-score.component';
import { MasterFeaturedPromosComponent } from './pages/master-featured-promos/master-featured-promos.component';
import {CdkDropListGroup, CdkDropList, CdkDrag,} from '@angular/cdk/drag-drop'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportGiftcardComponent } from './pages/statistics/report-giftcard/report-giftcard.component';

export function initializeApp(configurationService: ConfigurationService) {
  return () => {
    configurationService.loadConfigurationData(configurationService.permisosRoutes)
  };
}

@NgModule({
  declarations: [
    AppComponent,
    // route views (root pages)
    PerfilComponent,
    SigninComponent,
    SignupComponent,
    HttpErrorComponent,
    MasterCompanyComponent,
    MasterProductsComponent,
    MasterUserCompanyComponent,
    MasterPromotionsComponent,
    MasterFeaturedPromosComponent,
    ConfigCompanyCategoryComponent,
    ConfigProductsCategoryComponent,
    PromotionsTagComponent,
    CouponsTagComponent,
    ConfigFranquiciasComponent,
    MasterPromotionsNewComponent,
    MasterLogsComponent,
    ChartsComponent,
    HomeComponent,
    MasterFranchiseComponent,
    MasterNewCompanyComponent,
    MasterAprobacionesComponent,
    MasterViewAprobacionesComponent,
    MasterValidatorqrComponent,
    StatisticsComponent,
    BannersComponent,
    ReportAlliesComponent,
    ReportCouponsComponent,
    ReportGiftcardComponent,
    MasterQRComponent,
    MasterPromoScoreComponent,
    MasterFeaturedPromosComponent,
    ReportGiftcardComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    CoreModule, // shared services that live across components
    AppRoutingModule,
    DashboardModule,
    CoreSidebarModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigurationService],
      multi: true
    },
    dbFullService,
    HttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
