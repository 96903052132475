import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ObservableComponentsService } from 'src/app/services/observable-components.service';
import { HelperAPIPetService } from 'src/app/services/helper-apipet.service';
import { dbFullService } from 'src/app/services/dbFull.service';
import { ICoreEstructure } from 'src/app/models/Estructure';
import { Attributes } from 'src/app/models/attributes';
import { HelperService } from 'src/app/services/helper.service';
import { SesionstorageService } from '../../..//services/sesionstorage.service';
import Swal from 'sweetalert2';
import { LogsService } from 'src/app/services/logs.service';
import { environment } from 'src/environments/environment';
import { IQrCompany } from "src/app/models/attributes";

@Component({
  selector: 'app-main-crud',
  templateUrl: './main-crud.component.html',
  styleUrls: ['./main-crud.component.scss']
})


export class MainCrudComponent implements OnInit, AfterViewInit {
  public selectedElement: {
    type: string,
    option: {id: number, name: string, icon: string, tableList: string[]},
    element: {[key: string]: any}
  } | undefined;
  public idAction: string = "";
  public CurrentidEmpConv!: IQrCompany;
  public TableDeleteSelect : string[] = [];
  @Input() TableName: string = '';
  @Input() attributes: Attributes = { title:'', description:'' };
  @Input() tableList: string[] = [];
  @Input() tableRelations: any[] = [];
  @Input() optionsDropdown: {id: number, name: string, icon: string, tableList?: string[],idAccion?: string}[] = [];
  @Input() profile: { user: any, stat: boolean } = { user: {} , stat: false}
  @Input() dragable: boolean = false;
  @Input() TableEdit: string[] = [];
  /** Permite filtrar al mostrar la tabla */
  @Input() filterBy: {name: string, value: string | number}[] | null = null; // name = Nombre del campo // value = valor que debe tener el campo
  /** Permite verificar si se puede mostrar algun campo */
  @Input() canShowInput: {name: string, check(vars: {[key: string]: any}): boolean}[] | null = null; // name = Nombre del campo || check = método que se llamará para verificar si se puede mostrar el campo asociado
  /** Parámetro para poder interseptar las variables justo antes de ser guardadas en alguna tabla */
  @Input() tableNameQR: string = "";
  @Input() beforeSubmit?: (vars: {[key: string]: unknown}, tableName: string) => Promise<{[key: string]: unknown}>;
  @Input() fnDataName: string = '';
  @Input() idColName: string = 'id';
  @Output() SalidaClickMenu: EventEmitter<any> = new EventEmitter();

  estructures: ICoreEstructure[] = [];
  estructure: ICoreEstructure[] = [];

  public modal: boolean = false;
  public detalle: boolean = false;
  public showSucursales: boolean = false;
  public showDelete: boolean = false;
  public showQR: boolean = false;
  admin: boolean = false;

  //VARIABLE PARA ALMACENAR LA DATA QUE SERA DEFAULT;
  public inputsValueDefault : {tableName: string, field:string, value: any}[] = [];

  constructor(
    private obsServer: ObservableComponentsService,
    private _helperApi: HelperAPIPetService,
    private router: Router,
    private logS: LogsService,
    private helper: HelperService,
    private _SesionstorageService:SesionstorageService,
    // private _dbFullService: dbFullService
    ){  }

  ngOnInit(): void {
    // console.log("OPTIONS LIST:", this.optionsDropdown);
    this.admin = !!this._SesionstorageService.GetSesionStorage("login")?.admin;
    if(this.profile.stat) {
      this.selectedElement = this.profile.user
      this.openStepper(1)

    }

    this.initSuscriptions();
  }

  ngAfterViewInit(): void {
    // this.initEstructuration()
  }

  async initEstructuration(): Promise<ICoreEstructure[]> {
    Swal.fire({
      icon: "info",
      title: "Cargando",
      text: "Espere un poco...",
      showConfirmButton: false
    })
    Swal.showLoading();
    let timeShowCargando = Date.now();

    // console.log(this.selectedElement)
    let tableListA:any = "";
    tableListA = this.selectedElement?.option.tableList || this.tableList;
    // console.log("SELECTED MAN::::::", tableListA);
    // if(this.selectedElement?.type=="ELIMINAR"){
    //   console.log("Entre en eliminar");
    // }else{
    //   tableListA =  this.tableList;
    // }
    //( this.selectedElement.option.tableList as string[]); //|| this.tableList;
    // console.log(tableListA);
    //if(tableListA == undefined || tableListA == null || tableListA.length==0){ tableListA = (this.tableList as any); console.log("ey entre aqui") }
    // console.log(this.tableList);
    // console.log("InitEstructuration");
    const arr: ICoreEstructure[] = [];
    for (const table of tableListA) {
    //   console.log("Table");
    //   console.log(table);
      const estructure = await this._helperApi.GetAllDataCampo("cb_Estructura", "TableName", table);
      if(table ==="cb_Featured"){
        const filtro = this.filterBy?.find(valu=>valu.name=="type")
        if(filtro){
          const valorEstruct:  any = estructure.find(value=>value.Campo==="id")!;
          console.log(filtro);
          
          switch(filtro?.value){
          
            case '0':
              valorEstruct.TipoDato = "Diccionario";
              valorEstruct.ValorDiccionario = "tec21";
              break;
            case '1':
              valorEstruct.TipoDato = "Diccionario";
              valorEstruct.ValorDiccionario = "tec22";
              break;
            default: 
              break;
          }
          console.log(estructure);  
        }
     
        
       
      }
      const core = {
        TitleHead: this.attributes.title,
        Table: table,
        Estructura: estructure,
        Inputs: [],
        Value: []
      }
      
      if (tableListA.indexOf(table) === 0) {
        this.estructure.push(core);
      }
      arr.push(core);
    }

    const timeForWait = (timeShowCargando+750) - Date.now();
    setTimeout(() => {
      Swal.close()
    }, timeForWait > 0 ? timeForWait : 0);
    return arr;
  }

  initSuscriptions() {

    this.obsServer.paramData$.subscribe(async res => {
      if (!res) {
        return;
      }
      if(this.modal) return;
      const data = await this.initEstructuration();
    //   console.log("DATOS: ", data);
    //AGREGAR UN CONDICIONAL PARA COLOCAR EL VALOR DEL LANDING BANNER DE FORMA PREDETERMINADA
      this.setDataVarsDefault(data);
      this.estructures = data;
      
      this.openModal();
    });
    //observable para obtener el id de la accion
    this.obsServer.dataIdAction$.subscribe(async res=>{
      if(res){
        this.idAction=res;
      }
    })
  }

  openModal() {
    this.modal = true;
  }

  closeModal(element?: any) {
    delete this.selectedElement;

    if (element?.data[0] instanceof Object) {
      this.reloadCurrentRoute();
    } else {
      this.showQR = false;
      this.modal = false;
      this.showSucursales = false;
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

  getDataToSideBar( event: any ) {
    console.log("Evento: "+event)
    if( event && event instanceof Object ) {
     
    //SI EXISTE EL ID DE LA ACCION, AGREGAR A DICHA VARIABLE
     if(event.option.idAccion) this.idAction=event.option.idAccion;
     console.log("ID DE LA ACCION: "+event.option.idAccion);
     console.log(event);
     this.selectedElement = event;

      // console.log(this.selectedElement);
      if(this.selectedElement != undefined)
      this.TableDeleteSelect.push(event.option.tableList);


     //console.log("ELEMENT >>>>>>>>>>>>>>>>", this.selectedElement)

      const valuesToCheck: any = {
        'EDITAR': () => this.openStepper(1),
        'SUCURSALES': () => this.openStepper(2),
        'DETALLE': () => this.openStepper(3),
        'QR': () => this.openStepper(4),
        'ELIMINAR': () => this.openDeleteModal()
      }

      valuesToCheck[event.type.trim()] ? valuesToCheck[event.type.trim()]() : null;
    }
  }

  openDeleteModal() {
    //console.log("LLEGE AQUI")
    this.showDelete = true;
  }

  async confirmDelete() {
    try {

      if(!this.selectedElement) throw new Error("No se ha seleccionado aún la opcion");



      this.estructure=[];
      const res = await this.initEstructuration();
      // console.log("res");
      // console.log(res);
      this.estructures = res.map(e => {
        const { Campo } = this.getPk(e);
        // console.log("Mapeando");
        // console.log(e);
        e.Value = [(`${Campo}_${this.selectedElement!.element[Campo]}`)];
        return e;
      });

      // console.log('this.estructures', this.estructures);

      let Pkey = "";
      // console.log('this.selectedElement', this.selectedElement);
      // console.log('this.estructure[0]', this.estructure[0]);
      // console.log("this.estructure");
      // console.log(this.estructure[0]);
      const { Campo } = this.getPk(this.estructure[0]);
      // console.log('this.getPk(this.estructure[0])', this.getPk(this.estructure[0]));
      Pkey = `${Campo}_${this.selectedElement.element[Campo]}`;
      // console.log("Mapeando2");
      // console.log('Pkey', Pkey);
      if ( Pkey.length > 0 ) {
        const FullDBObj = new dbFullService();
        // Pkey es el nombre de la llave de ka tabla que es dinamica
        // iTemDelete[Pkey] es el valor que le voy hacer update

        // console.log(Data);
        // console.log(Campo)
        // console.log(this.TableName)

        // console.log(this.selectedElement.option.tableList);


        const isBranchOffice = this.selectedElement?.element['idSucursal']


        if(isBranchOffice){
          const Data = {
            ['idSucursal']: this.selectedElement.element['idSucursal'].replace(/&quot;/ig,'\"'),
            Status: 0
          }
          const resp: any = await FullDBObj.UpdateData(this.selectedElement.element['Tablaselect'], 'idSucursal', Data);

          if (resp.rowsUpdate === 1) {

            this.reloadCurrentRoute();
            console.log('DEBERIA ACTUALIZAR SOLO LA SUCURSALLLLLL')

          }

        }else{
          const campo = this.selectedElement;
         const valueId = typeof(this.selectedElement.element[Campo]) === 'string'
         ? this.selectedElement.element[Campo].replace(/&quot;/ig,'\"')
         : this.selectedElement.element[Campo];
          const Data = {
            [Campo]: valueId,
            Status:0
          }
          const resp: any = await FullDBObj.UpdateData(this.TableName, Campo, Data);
          if (resp.rowsUpdate === 1) {

            this.reloadCurrentRoute();
            console.log('resp', resp)
          }

        }
        //AQUI SE AGREGA EL ELEMENTO DE REPORTERIA
        let message = "Se ha eliminado un registro";
        await this.logS.createLogs(this.idAction,message);
        //VEMOS TODOS LOS REGISTROS

      }

    } catch (error) {
      console.log('Error:', error);
    }
  }
  /*
    * @param (option) usado para determinar que propiedad modificar y asi mostrar el modal respectivo
  */
  openStepper( option: number ) {
    console.log(option,"option");
    //Esto lo uso en caso de que no selecciona editar no se mande la opcion de editar
    if(option != 1) this.TableEdit=[];

    this.initEstructuration().then(res => {
      this.setDataVarsDefault(res);
      this.estructures = res.map( e => {
      //    console.log(e)
      //  console.log(this.selectedElement);
        const { Campo } = this.getPk(e);
        e.Value = [(`${Campo}_${this.selectedElement!.element[Campo]}`)];
       // console.log(e.Value);
        //console.log("Mapeando3", `${Campo}_${this.selectedElement!.element[Campo]}`, this.selectedElement!.element);
        return e;
      });

      const selectField: any = {
        1: () => this.modal = true,
        2: () => this.showSucursales = true,

      }

      if(option === 3){
        this.detalle = true;
      }else if(option===4){
        console.log("empresa seleccionada: "+ this.selectedElement?.element["idEmpConv"]);
        this.CurrentidEmpConv= {
          idEmpConv:this.selectedElement?.element["idEmpConv"],
          name: this.selectedElement?.element["nombre"],
          rif: this.selectedElement?.element["rif"],
          codigoEmpresa: this.selectedElement?.element["idQR"] ? this.selectedElement?.element["idQR"] : this.selectedElement?.element["CodigoEEmpresa"]
        }
        this.showQR = true;
      }

      console.log(this.detalle)
      selectField[option] ? selectField[option]() : null;
      // console.log('this.estructures', this.estructures)
    });
}


  getPk(value: any) {
    const pk = value?.Estructura?.filter((element: any) => element.PK === 1);
    return pk[0];
  }

  trigger(ev: any){
    // console.log(ev)
    let values;

    if(ev.evento === "cerrar-detalle") {
      this.detalle = false;
    }
    else {
      if(ev.evento !== 'cerrar'){

        values = {
          ...ev.data[0],
          admin: this.admin
        }
        this._SesionstorageService.SetSesionStorage('login', JSON.stringify(values))
        //sessionStorage.setItem('login', JSON.stringify(values))
        this.helper.$userListener.emit(values)
      }


      this.modal = false;
      this.router.navigate(['/dashboard/home'])
    }
  }
   setDataVarsDefault(dataStructure: any[]){
    if(dataStructure.length>0){
       dataStructure.forEach(async structure=>{
      switch(structure.Table){
        case "cb_LandingBanner":
          if(this.filterBy){
            const dataField = this.filterBy.find(filt=>filt.name==="type");
            console.log(dataField);
            this.inputsValueDefault.push({tableName: "cb_LandingBanner",field: dataField?.name!, value: dataField?.value })

          }

          break;
          case "cb_Featured":
            if(this.filterBy){
              const dataField = this.filterBy.find(filt=>filt.name==="type");
              console.log(dataField);
              console.log(structure);
              this.inputsValueDefault.push({tableName: "cb_Featured",field: dataField?.name!, value: dataField?.value })
  
            }
          break
          default :
          console.log("No existen valores por default para agregar");
          break;
        }
    });

    }
    
  }
 getMessageFeatured(): string{
  switch(this.TableName){
    case "cb_Promo":
    return "la promoción Seleccionada"
    break;
    case "cb_EmpresaConvenio":
    return "el aliado seleccionado"
    break;
    default: 
    return "el elemento seleccionado"
    break;
  }
 }
}
