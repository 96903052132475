import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import * as ApexCharts from 'apexcharts';
import { ColorService } from 'src/app/services/color.service';

type ExtractArray<array> = array extends Array<infer type> ? type : never;

export interface IChartDataLine {
  id: string,
  value: number
}

export type IChartData = {
  color?: string,
  tag: string,
  data: IChartDataLine[]
}

export type ICharType =
| 'line'
| 'area'
| 'bar'
| 'pie'
| 'donut'
// | 'radialBar'
// | 'scatter'
// | 'bubble'
// | 'heatmap'
// | 'candlestick'
// | 'boxPlot'
// | 'radar'
// | 'polarArea'
// | 'rangeBar'
// | 'rangeArea'
// | 'treemap'
;

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent  implements OnInit, OnChanges {
  @ViewChild("chart_element", {static: true}) chart_element?: ElementRef;

  public colorDefault: string = "rgba(60,60,255)";

  // @Input() width: string = "100%";
  // @Input() height: string = "100%";
  @Input() type: ICharType = "bar";
  @Input() data: IChartData[];
  @Input() loading: boolean = false;
  @Input() title?: string;
  @Input() color?: string;
  @Input() labels?: string[];
  @Input() rangeDate: number = 0;
  @Input() formatterText?: (value: number) => string;

  @Output() select: EventEmitter<ExtractArray<ExtractArray<IChartData[]>["data"]>> = new EventEmitter();

  private initialized: boolean = false;
  private chart: ApexCharts | undefined;
  public noneValue: boolean = false;
  public allValuesData: any[] = [];
  constructor( public colorService: ColorService ) {
    this.data = [];
  }

  
  ngOnInit() {
    // window.addEventListener("resize", this.handlerSize);
    // this.orderDates();
    console.log("INICIO");
    
    setTimeout(() => {
      this.InitApexChart();
      this.initialized = true;
    }, 1000)
  }

  
  ngOnDestroy() {
    // window.removeEventListener("resize", this.handlerSize);
  }

  // ngAfterViewInit() {
  //   // this.InitChart();
  // }

  ngOnChanges(changes: SimpleChanges): void {
  
    // this.InitChart();
     const value = changes["rangeDate"]?.currentValue;

    console.log(value);
    //  if(value && changes["rangeDate"].currentValue != changes["rangeDate"].previousValue){
    //   this.orderDates();
    //   console.log("CAMBIO");
     
    // }
    // this.orderDates();
    console.log(this.data);
    
    if(this.initialized) {
      this.InitApexChart();
    }
    // this.orderDates();
  }

  InitApexChart() {
    if(this.chart_element?.nativeElement) {
      if(this.chart) {
        this.chart.destroy();
        delete this.chart;
      }
      // Inicializar los datos para mostrar
      const labels: string[] = [];
      const series: {
        name: string,
        color: string,
        data: number[],
        // backgroundColor: string[],
        // borderColor: string[],
        // borderWidth: number,
        // tension: number
      }[] = [];
      let noneValue: boolean = true;
      // for(let dataItem of this.data) {
      for(let dataItem of this.data) {
        const data: number[] = [];
        // const backgroundColor: string[] = [];
        let color: string = "red";
//for(let item of this.allValuesData) { 
        for(let item of dataItem.data) {
          noneValue = false;
          data.push(item.value);
          color = (dataItem.color || this.color || this.colorDefault);
        }

        series.push({
          name: dataItem.tag,
          data: data,
          color: color
          // backgroundColor: backgroundColor,
          // borderColor: backgroundColor,
          // borderWidth: 1,
          // tension: 0.2,
        })
      }

      this.noneValue = noneValue;
      var options: ApexCharts.ApexOptions;
//      var labelsCategory: string[] | undefined = this.labels || (this.allValuesData?.map(item => item.id) || undefined);
      var labelsCategory: string[] | undefined = this.labels || (this.data[0]?.data.map(item => item.id) || undefined);
      var isDateLabels = false;
      console.log(labelsCategory);
      if(labelsCategory) {
        try {
          for(let label of labelsCategory) {
            var timestamp = Date.parse(label);

            if (isNaN(timestamp)) {
              throw(new Error("No son fechas válidas"))
            }
          }
          isDateLabels = true; // SON FECHAS VALIDAS!!!
        }
        catch(err) {}
      }



      switch(this.type) {
        case "pie":
        case "donut":
          options = {
            series: series.length === 1 ? series[0].data : series.map(s => s.data.reduce((t,v) => (t+v), 0)),
            chart: {
            // width: 380,
            height: 300,
            type: this.type,
          },
          colors: series.length === 1 ? series[0].data.map(() => this.colorService.GetDynamicColor()) : series.map(s => s.color),
          labels: series.length === 1 ? labelsCategory : series.map(s => s.name),
          // responsive: [{
          //   breakpoint: 480,
          //   options: {
          //     chart: {
          //       width: 200
          //     },
          //     legend: {
          //       position: 'bottom'
          //     }
          //   }
          // }]
          };
          // console.log("SERIES",series.length === 1 ? series[0].data : series.map(s => s.data.reduce((t,v) => (t+v), 0)) )
          // console.log("LABEL", series.length === 1 ? labelsCategory : series.map(s => s.name) )
          break;

        case "area":
        default:
          options = {
            series: series,
            chart: {
              height: 200,
              background: "",
              toolbar: {
                show: true
              },
              type: this.type,
              zoom: {
                enabled: true
              },
            },
            grid: {
              show: true,      // you can either change hear to disable all grids

            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              type: isDateLabels ? "datetime" : undefined,
              categories: labelsCategory,
            },
            yaxis: {
              labels: {
                show: true,
                formatter: this.formatterText
              },
              floating: false,
              tickAmount: 1
            },
            tooltip: {
              x: {
                format: "dd/MM/yy HH:mm"
              },
              y: {
                formatter: this.formatterText
              }
            },
            ...(this.type === "area" ? { // agregar suavisado de color
              fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.7,
                  opacityTo: 0.4,
                  stops: [0, 230, 255]
                }
              },
            } : {
              fill: {
                type: ""
              }
            })

          };
          break;
      }


      if(this.chart) {
        // this.chart.updateOptions(options);
      }
      else {
        this.chart = new ApexCharts(this.chart_element.nativeElement, options)
      }

      this.chart.render()
    }
  }

  HandlerClickChart = (ev: ChartEvent) => {
    if(this.chart) {
    }
  }
  public orderDates(){
    const typeRange = Number(this.rangeDate!);
    console.log(typeRange);
    
    // if(typeRange){
      switch(typeRange){
        case 0:
        this.get30Days(this.data,30);
        break;
        case 1:
          this.get30Days(this.data,90);
          break;
          case 2:
            this.get30Days(this.data,200);
            break;
            case 3:
              this.get30Days(this.data,500);
              break;
            default:
              console.log("CAMBIOS DEFAULT");
              
              this.get30Days(this.data,30);
            break;
      // }
    }
  }
  public get30Days(data: IChartData[],numberDays: number){
    let dataConcat: IChartData[] = [];
    data.forEach(chartData=>{
      const refData = chartData;
      const dataRange: any[] = chartData.data;
      const newData =  dataRange.filter(value=>{
        const daysNumber =  86400000*numberDays;
        const dateNow = new Date().getTime()-daysNumber;
        if(new Date(value.id).getTime() >= dateNow) return value;
      })
      refData.data = newData;
      dataConcat.push(refData);
    })
    
    console.log(dataConcat);
    this.allValuesData = dataConcat;
  }
}