import { Component, OnInit,  EventEmitter, Input, Output } from '@angular/core';
import { IEstructura2 } from 'src/app/models/Estructure';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-string',
  templateUrl: './string.component.html',
  styleUrls: ['./string.component.scss']
})
export class StringComponent implements OnInit {

  @Input() data: TipoInput = {} as any;
  @Input() value: any = "";
  @Input() formData: IFormData = new IFormData();
  @Input() otrosdatos: any;
  @Output() ValueSalida: EventEmitter<any> = new EventEmitter();
  // @Output() outdata = new EventEmitter<{name: string, value: string, isInv


  ngOnInit(): void {
    // valor inicial
    //this.Exclusion()
    
    const value: string = this.formData.vars[this.data.Campo] || "";
    this.formData.vars[this.data.Campo] = value.trim();
  }

  HandlerOnKey(event: any) {
    console.log(event);
    delete this.formData.error[this.data.Campo];
  }

  // onKey(event: KeyboardEvent){
  //   //this.Exclusion(event);
  // }

  HanderOnChange(event: any) {
    delete this.formData.error[this.data.Campo];
    const value: string = event.target.value;
    this.formData.vars[this.data.Campo] = value.trim();
  }

  GetDataSelect(){

  }

  // Exclusion(event?:KeyboardEvent){
  //   console.log("Exclusion");
  //   if(this.data.TableName == 'cb_QR' && this.data.Campo == 'urlRedirection'){
  //     console.log('pase')
  //     this.formData.vars['urlRedirection'] = event ? event.key: '';
  //     const regex = /^(https?:\/\/)(www\.)?([a-zA-Z0-9@:%._\+~#?&//=]*)/i;
  //     if(regex.test(this.formData.vars['urlRedirection'])){
  //       console.log("Pase")
  //       console.log(this.data)
  //       this.ValueSalida.emit('Pase')
  //     }else{
  //       console.log(this.otrosdatos)
  //       if(this.otrosdatos){
  //         let index = this.otrosdatos[0]['Estructura'].findIndex((data:any)=>data.Campo == 'redirect');
  //         console.log(this.otrosdatos);
  //         this.otrosdatos[0]['Estructura'][index].ShowInsert=0;
  //         this.ValueSalida.emit('No Pase')
  //       }
  //     }

  //   }
  // }
}
