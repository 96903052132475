<div class="card bd-primary" style="max-height: calc(100vh - 100px); height: 100%; max-width: 100vw;">
  <div class="card-header bg-primary tx-white">{{ attributes.title }}</div>
  <div class="card-body pd-sm-30" style="max-height: 600px; height: 100%; overflow: auto;">
    <div class="d-inline-block w-100">
      <div *ngIf="ArrayDataAll.length && ArrayDataAll.length > 0" (click)="toOpenMenu()" class="filter-btn">
        FILTRAR<i class="icon ion-eye"></i>
        <!-- ion-navicon-round -->
      </div>
      <div *ngIf="showMenu" class="menu-dropdown" aria-labelledby="dropdownBasic1">
        <button [class.selected-filter]="head.visible" ngbDropdownItem *ngFor="let head of columnsFilter; let i = index" (click)="toggleColumn(i)">

          {{ head.title }}
        </button>
      </div>
    </div>

    <p class="mg-b-20 mg-sm-b-30">{{ attributes.description }}</p>

    <div class="table-wrapper">
      <div [class]="tableClasses || ''" class="scrollable-element"
        style="display: block; width: 100%; padding-bottom: 20px;">
        <table #datatable class="table display responsive nowrap " style="width: 100%;" cdkDropListGroup>
          <!-- <div class="not_dragable" *ngIf="!dragable"> -->
          <thead *ngIf="!first">
            <tr>
              <th [attr.data-orderable]="!isNotOrderableColumn(head.TipoDato)" *ngFor="let head of FieldAndHead?.head"  [width]="WithCalculeSize">{{ head.HeadListName }}</th>
              <th [attr.data-orderable]="!isNotOrderableColumn('Opciones')"  *ngIf="optionsDropdown.length !== 0">Opciones</th>
            </tr>
          </thead>
          <tbody *ngIf="!first && !dragable">
            <tr *ngFor="let item of ArrayDataAll; let i = index">
              <td class="table-item-td" *ngFor="let key of validFields;"
                [ngClass]="{'tbl-img': isFieldImage(key), '': !isFieldImage(key)}">
                <ng-container *ngIf="!isFieldImage(key) && !isFieldDate(key) && !isFieldStatus(key)">{{ item[key] ??
                  'S/I' }}</ng-container>
                <ng-container *ngIf="isFieldDate(key)">{{formatDate(item[key])}}</ng-container>
                <ng-container *ngIf="isFieldStatus(key)">{{item[key] ? 'ACTIVO' : 'INACTIVO'}}</ng-container>
                <img 
                loading="lazy"                *ngIf="isFieldImage(key)"
                (error)="handleImageError($event)"
                  src="{{ item[key] ? item[key] : '../../assets/images/users_sjlanv.png' }}"
                  alt="{{item[key]}}">

              </td>
              <td style="text-align: center; width: 0%;" *ngIf="optionsDropdown.length !== 0">

                <div class="dropdown">
                  <i class="icon ion-more" data-toggle="dropdown" style="cursor: pointer;"
                    (click)="setElement(ArrayDataAllOriginal[i] || item)"></i>
                  <app-table-actions [ElementSelect]="element" [options]="optionsDropdown"
                    (emitParam)="getOptionAndData( $event )"></app-table-actions>
                </div>
              </td>

            </tr>
            <tr *ngIf="loading" style="text-align: center; padding: 30px 0px;">
              <td [colSpan]="(FieldAndHead?.head?.length || 0) + 1">
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="dragable" id="dropContainer"  cdkDropList [cdkDropListData]="ArrayDataAll" (cdkDropListDropped)="drop($event)">
            <tr *ngFor="let item of ArrayDataAll; let i = index" id="dropChild" cdkDrag class="item-table-tr" [ngClass]="{'item-table-tr-bg-dark': i % 2 === 0}" #dropChild>
              <td class="table-item-td" *ngFor="let key of validFields;"  [attr.data-order]="isFieldNumeric(item[key]) ? item[key] :  0" 
                [ngClass]="{'tbl-img': isFieldImage(key), '': !isFieldImage(key)}">
                <ng-container *ngIf="!isFieldImage(key) && !isFieldDate(key) && !isFieldStatus(key)">{{ item[key] ??
                  'S/I' }}</ng-container>
                <ng-container *ngIf="isFieldDate(key)">{{formatDate(item[key])}}</ng-container>
                <ng-container *ngIf="isFieldStatus(key)">{{item[key] ? 'ACTIVO' : 'INACTIVO'}}</ng-container>
                <img *ngIf="isFieldImage(key)"
                (error)="handleImageError($event)"
                  src="{{ item[key] ? item[key] : '../../assets/images/users_sjlanv.png' }}"
                  alt="{{item[key]}}">
              </td>
              <td style="text-align: center; width: 0%;" *ngIf="optionsDropdown.length !== 0">

                <div class="dropdown">
                  <i class="icon ion-more" data-toggle="dropdown" style="cursor: pointer;"
                    (click)="setElement(ArrayDataAll[i] || item)"></i>
                  <app-table-actions [ElementSelect]="element" [options]="optionsDropdown"
                    (emitParam)="getOptionAndData( $event )"></app-table-actions>
                </div>
              </td>

            </tr>
            <tr *ngIf="loading" style="text-align: center; padding: 30px 0px;">
              <td [colSpan]="(FieldAndHead?.head?.length || 0) + 1">
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          </tbody>

          <!-- <thead *ngIf="first">
            <tr>
              <th *ngFor="let head of control" [width]="WithCalculeSize">{{ head }}</th>
              <th *ngIf="optionsDropdown.length !== 0">Opciones</th>
            </tr>
          </thead> -->
          <!-- [ngClass]="{'filtered-row': shouldShowRow(item)}" -->
          <!-- <tbody *ngIf="first">
            <tr *ngFor="let item of ArrayDataAll; let i = index">
              <td class="table-item-td" *ngFor="let key of control2;"
                [ngClass]="{'tbl-img': isFieldImage(key), '': !isFieldImage(key)}">
                <ng-container *ngIf="!isFieldImage(key) && !isFieldDate(key) && !isFieldStatus(key)">{{ item[key] ??
                  'S/I' }}</ng-container>
                <ng-container *ngIf="isFieldDate(key)">{{formatDate(item[key])}}</ng-container>
                <ng-container *ngIf="isFieldStatus(key)">{{item[key] ? 'ACTIVO' : 'INACTIVO'}}</ng-container>
                <img *ngIf="isFieldImage(key)"
                
                (error)="handleImageError($event)"
                  src="{{ item[key] ? item[key] : '../../assets/images/users_sjlanv.png' }}"
                  alt="{{item[key]}}">
              </td>
              <td style="text-align: center; width: 0%;" *ngIf="optionsDropdown.length !== 0">

                <div class="dropdown">
                  <i class="icon ion-more" data-toggle="dropdown" style="cursor: pointer;"
                    (click)="setElement(ArrayDataAllOriginal[i] || item)"></i>
                  <app-table-actions [ElementSelect]="element" [options]="optionsDropdown"
                    (emitParam)="getOptionAndData( $event )"></app-table-actions>
                </div>
              </td>

            </tr>
            <tr *ngIf="loading" style="text-align: center; padding: 30px 0px;">
              <td [colSpan]="(FieldAndHead?.head?.length || 0) + 1">
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          </tbody> -->
          <!-- </div> -->
          <!--
          <thead *ngIf="!first">
            <tr>
              <th *ngFor="let head of FieldAndHead?.head" [width]="WithCalculeSize">{{ head }}</th>
              <th *ngIf="optionsDropdown.length !== 0">Opciones</th>
            </tr>
          </thead> -->


        </table>
      </div>
    </div><!-- table-wrapper -->
  </div><!-- card-body -->
</div>
