<div  class="modal-background-black" >
    <div class="modal-dialog" role="document">
    <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Filtrar</h5>
        <button  (click)="onClose()"  type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form  class="form-modal" (submit)="onSubmit($event)" >
    <div class="modal-body">
        <div class="form-group">
            
                <div *ngFor="let formControlItem of columnsInput"  class="form-group">
                    <label *ngIf=" formControlItem.HeadListName !='' && formControlItem.TipoDato !='img' && formControlItem.ShowInsert"  class="form-label"
                    for="basic-icon-default-fullname">{{formControlItem.HeadListName + (formControlItem.Requerido ? '*' : '')}}</label>

                    <span [ngSwitch]="formControlItem.TipoDato"> 
                    <p *ngSwitchCase="'Diccionario'">
                        <app-field-dinamic [allowEmptyValue]="true" [formData]="formDatas" [FieldDynamic]="formControlItem.ValorDiccionario" (ValueSalida)="EventoDinamic($event, formControlItem)" [value]="FormDataActive.vars[formControlItem.Campo] || ''"
                        [disabledItem]="formControlItem.Reading === 1 ? true  : false"
                        ></app-field-dinamic>
                    </p>
    
                    <p *ngSwitchCase="'Numeric'">
                        <app-numeric [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-numeric>
                    </p>
                    <p *ngSwitchCase="'Map'">
                        <app-mapa [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-mapa>
                    </p>
    
                    <p *ngSwitchCase="'String'">
                        <app-string [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''" (ValueSalida)="EventoDinamic($event, formControlItem)"  ></app-string>
                    </p>
    
                    <p *ngSwitchCase="'Date'">
                        <span class="d-flex w-100">
                            <span class="w-50 d-flex flex-column ">
                                <label for="">Desde: </label>
                                <app-date [data]="formControlItem" [formData]="FormDataActive" [value]=" getRangeInputValue(FormDataActive.vars[formControlItem.Campo],'min') || ''"  (outdata)="emitDate($event,formControlItem.Campo,'min')"></app-date>
                            </span>
                            <span class="w-50 d-flex flex-column ">
                                <label for="">Hasta: </label>
                                <app-date   [data]="formControlItem" [formData]="FormDataActive" [value]="getRangeInputValue(FormDataActive.vars[formControlItem.Campo],'max') || ''" (outdata)="emitDate($event,formControlItem.Campo,'max')"></app-date>
                            </span>
                        </span>
                      
                    </p>
    
                    <p *ngSwitchCase="'Name'">
                        <app-name [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-name>
                    </p>
    
                    <p *ngSwitchCase="'Password'">
                        <app-password [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-password>
                    </p>
    
                    <p *ngSwitchCase="'Movil'">
                        <app-movil [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-movil>
                    </p>
    
                    <p *ngSwitchCase="'Email'">
                        <app-email [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-email>
                    </p>
    
                    <p *ngSwitchCase="'Area'">
                        <app-area [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-area>
                    </p>
    
                    <p *ngSwitchCase="'Avatar'" class="AvatarStyle" style="text-align: center;">
                        <app-avatar [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''" ></app-avatar>
                    </p>
    
                    <p *ngSwitchCase="'eAvatar'" class="AvatarStyle">
                        <app-ext-avatar [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-ext-avatar>
                    </p>
    
                    <p *ngSwitchCase="'Switch'">
                        <app-switch [data]="formControlItem" [formData]="FormDataActive"   [value]="FormDataActive.vars[formControlItem.Campo] || ''" (ValueSalida)="EventoDinamic($event, formControlItem)"></app-switch>
                    </p>
    
                    <p *ngSwitchCase="'Color'">
                        <app-color [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-color>
                    </p>
    
                    <p *ngSwitchCase="'DependencySelection'">
                        <app-dependency-selection></app-dependency-selection>
                    </p>
    
                    <p *ngSwitchCase="'Money'">
                        <app-money [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-money>
                    </p>
                    </span>
                    <div style="color: red" *ngIf="FormDataActive.error[formControlItem.Campo]">{{FormDataActive.error[formControlItem.Campo]}}</div>
                </div>
 
       
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onClose()" >Cancelar</button>
        <button type="submit" class="btn btn-primary" data-dismiss="modal" >Aceptar</button>
    </div>
    </form>
  
    </div>
    </div>
</div>  