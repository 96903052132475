import { Component, EventEmitter, Input, Output } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import { DictionaryObject } from '../../interfaces/Dictionary';
import { HelperAPIPetService } from 'src/app/services/helper-apipet.service';
import { IFormData } from 'src/app/models/tipo.input';
import { InputTableService } from 'src/app/services/input-table.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-field-dinamic',
  templateUrl: './field-dinamic.component.html',
  styleUrls: ['./field-dinamic.component.scss']
})
export class FieldDinamicComponent {
    @Input() disabledItem : boolean | number = 0; 
    @Input() FieldDynamic: string | null = '';   // para llamar este componente debes valiar antes de que lo que pasas contiene id_d_
    @Input() value: any;
    @Input() formData: IFormData | IFormData[] = new IFormData();
    @Input() idToSelect: string = '';
    
    @Input() allowEmptyValue: boolean = false;
    @Output() ValueSalida = new EventEmitter<string | string[]>();

    public diccionario: DictionaryObject = { } as any;
    public TipoComponente: string = "0" // Default No muestra nada deberia mostrar un cargando
    public DataSendComponent:any[] = [];
    public TitleStr: string = "";
    public TableName: string = "";
    
    constructor(private _helperApi: dbFullService, private dbFullService: dbFullService, private _SesionstorageService: SesionstorageService, private inputService: InputTableService) {

    }

    get sessionStorageData() {
      return this._SesionstorageService.GetSesionStorage('login') || null;
    }

    ngOnInit(): void {
      // console.log("EXT SELECT ***********************************")
    //   console.log("FieldDynamic", this.FieldDynamic);
    //   console.log("value", this.value);
    //   console.log("formData", this.formData);
    //   console.log("idToSelect", this.idToSelect);
      
      //this.FieldDynamic = "id_d_tec3".split("_")[2]  // Tomo nada mas tec1 Para Test
      if( !this.FieldDynamic ) this.FieldDynamic = 'id_d_tec1';
      if ( this.FieldDynamic &&  this.FieldDynamic.indexOf("_") > 0) this.FieldDynamic = this.FieldDynamic.split("_")[2]  // Para produccion
      // const FullDBObj = new dbFullService()
      this._helperApi.GetAllDataCampo("cb_Dictionary", "idUnico", this.FieldDynamic).then((DatosDic: any) => {
        try {
          const DataDic: DictionaryObject = DatosDic[0];
          this.diccionario = DataDic;
          if(DataDic.TableNameOrigen && DataDic.idCampoOrigen) {
            let promiseGetData;

            // if(DataDic.idCampoSearch) {
              let valor: unknown;
              if(this.formData instanceof Array) {
                this.formData.find(formData => (
                  valor = formData.vars[DataDic.idCampoSearchOrigen || DataDic.idCampoOrigen]
                ))
              }
              else {
                valor = this.formData.vars[DataDic.idCampoSearchOrigen || DataDic.idCampoOrigen];
              }
         
              promiseGetData = this.dbFullService.getAnyQuery(environment.bd, `SELECT * FROM ${DataDic.TableNameOrigen} WHERE ${this.FieldDynamic == "tec25" ? `statusAprobacion='APROBADA'` : 'Status=1'} AND ${DataDic.idCampoSearchOrigen || DataDic.idCampoOrigen}='${valor}'`);
              // }
              // else 
              // promiseGetData = this._helperApi.GetAllDataCampo(DataDic.TableNameOrigen, "Status", 1);
              
              promiseGetData
              .then((data: any[]) => {
                // console.log("QUERY GET DEFAULT VALUES:", `SELECT * FROM ${DataDic.TableNameOrigen} WHERE Status=1 AND ${DataDic.idCampoSearchOrigen || DataDic.idCampoOrigen}='${valor}'`, data.map(ItemData => ItemData[DataDic.idCampoOrigen]))
                this.ValueSalida.emit(data.map(ItemData => ItemData[DataDic.idCampoOrigen]));
              })
              .catch((err) => {
                console.error(err);
              })
          }
          else {
            try {
              const arr = JSON.parse(this.value);
              this.ValueSalida.emit(arr instanceof Array ? arr : this.value.toString()); // <<<=== en caso que séa un Array
            }
            catch(err) { }
          }

          // if(DataDic.StoreProcedure) {
          //   let vars: {[key: string]: unknown} = { };

          //   if(this.formData instanceof Array) {
          //     const formData = this.formData.find(formItem => formItem.vars[DataDic.idCampoOrigen]);
          //     if(formData) {
          //       vars = {...vars, ...formData.vars};
          //     }
          //   }
          //   else {
          //     vars = this.formData.vars;
          //   }         

          //   console.log("DICCIONARIO:", DataDic);
          //   console.log("PRIMARY KEY OF LIST:", DataDic.idCampoOrigen);
          //   console.log("CAMPO DESTINO:", DataDic.idCampoDestino);
          //   console.log("VALUE   KEY OF LIST:", this.formData);
            
          //   const value = vars[DataDic.idCampoOrigen];
            
          //   if(value){
          //     console.log("Tabla origen:", DataDic.TableNameOrigen);

              
          //     let promiseAnyQuery;
          //     if(DataDic.idCampoSearch) {
          //       console.log("QUERY", `SELECT origen.* FROM ${DataDic.TableNameOrigen} as origen, ${DataDic.TableNameDestino} as destino WHERE origen.Status=1 AND destino.${DataDic.idCampoSearch}='${vars[DataDic.idCampoSearch]}'`);
          //       promiseAnyQuery = this.dbFullService.getAnyQuery("thomas_clubfibex", `SELECT origen.* FROM ${DataDic.TableNameOrigen} as origen, ${DataDic.TableNameDestino} as destino WHERE origen.Status=1 AND destino.${DataDic.idCampoSearch}='${vars[DataDic.idCampoSearch]}'`);
          //     }
          //     else {
          //       promiseAnyQuery = this.dbFullService.getAnyQuery("thomas_clubfibex", `SELECT * FROM ${DataDic.TableNameOrigen} WHERE Status=1`);
          //     }
              
              
          //     promiseAnyQuery.then((res) => {
          //         if(res instanceof Array) {
          //           console.log("DATA CARGADAS POR LA TABLA", res.length === 1 ? res[0][DataDic.idCampoDestino] : res.map(item => item[DataDic.idCampoDestino]))
          //           this.ValueSalida.emit(res.length === 1 ? res[0][DataDic.idCampoDestino] : res.map(item => item[DataDic.idCampoDestino]));
          //         }
          //       })
          //       .catch((err) => {
          //         console.error(err);
          //       })
          //   }
          // }

          this.TableName = DataDic.TableNameDestino;
          this.TitleStr = DataDic.TitleShow
          // Voy a ir a Buscar los datos que me indique el diccionario

          let promiseGetData;
         
          if(DataDic.idCampoSearch) {
            let valor: unknown;
            if(this.formData instanceof Array) {
              this.formData.find(formData => (
                valor = formData.vars[DataDic.idCampoSearch!] ? formData.vars[DataDic.idCampoSearch!] : formData.vars["idPromo"]
              ))
            }
            else {
              valor = this.formData.vars[DataDic.idCampoSearch];
            }
            // console.log("VALOR: ", valor);
            // console.log("QUery: ", `SELECT * FROM ${DataDic.TableNameDestino} WHERE Status=1 AND ${DataDic.idCampoSearch}='${valor}'`)
            promiseGetData = this.dbFullService.getAnyQuery(environment.bd, `SELECT * FROM ${DataDic.TableNameDestino}  ${DataDic.TableNameOrigen} WHERE ${this.FieldDynamic == "tec25" ? `statusAprobacion='APROBADA'` : 'Status=1'} AND ${DataDic.idCampoSearch}='${valor}'`);

          }
          else {
            if(this.sessionStorageData.Data.Type === 0){
                // console.log('TABLA DESTINO>>>', DataDic.TableNameDestino)
                // console.log(this.sessionStorageData.Data.idEmpConv)
              if(DataDic.TableNameDestino === 'cb_EmpresaConvenio' && this.sessionStorageData.Data.idEmpConv){
                promiseGetData = this._helperApi.GetAllDataCampo(DataDic.TableNameDestino, "idEmpConv", this.sessionStorageData.Data.idEmpConv);
                // console.log("PASO POR AQUI 1")
              }else {
                this.FieldDynamic !== "tec25" ?
                promiseGetData = this._helperApi.GetAllDataCampo(DataDic.TableNameDestino, "Status", 1)
                : 
                promiseGetData = this.dbFullService.getAnyQuery(environment.bd,`SELECT * FROM ${environment.bd}.${DataDic.TableNameDestino} vw WHERE vw.statusAprobacion COLLATE utf8mb4_unicode_ci  = "APROBADA" COLLATE utf8mb4_unicode_ci `);
                ; // console.log("PASO POR AQUI 2")
              }
            } else {
              
              this.FieldDynamic !== "tec25" ?
              promiseGetData = this._helperApi.GetAllDataCampo(DataDic.TableNameDestino, "Status", 1)
              : 
              promiseGetData = this._helperApi.GetAllDataCampo(DataDic.TableNameDestino, "statusAprobacion", "APROBADA")
              ;
            }
            
          }
          
          promiseGetData
            .then((DataShow: any) => {
            //   console.log(DataShow)
            //   console.log('TABLA DESTINO')
            //   console.log(this.diccionario.TableNameDestino)
              this._helperApi.GetAllDataCampo("cb_Estructura", "TableName", this.diccionario.TableNameDestino)
                .then((res) => {
                    // console.log(res)
                  const pk = res.find(item => item.PK);
                  
                  // console.log("Diccionario:", DataDic)
                //   console.log("Datos del usuario:", this.sessionStorageData);
                  if( this.sessionStorageData.idEmpConv  && DataDic.idCampoDestino === "idEmpConv" && !this.sessionStorageData.admin) {
                    DataShow = DataShow.filter((enterprise: any) => enterprise.idEmpConv === this.sessionStorageData.idEmpConv );
                  }
                  this.TipoComponente = DataDic.Tipo;
                  
                //   console.log(DataShow)
                  let TemDataSendComponent:any[] = []
                  // Agregar un valor vacío si allowEmptyValue es true
                  if (this.allowEmptyValue) {
                    TemDataSendComponent.push({
                      datoShow: "Seleccione una opción", // Texto para el valor vacío
                      value: "", // Valor vacío
                      icon: "",
                      pk: {
                        Campo: "",
                        Valor: ""
                      },
                      element: {}
                    });
                  }
                  DataShow = DataShow.filter((data: any) => !data.hide);
                  DataShow.forEach((DataComponente:any) => {
                    if(DataComponente[DataDic.idCampoShow] != null){
                    //   console.log(DataComponente)
                      const Dato = {
                        datoShow:   DataComponente[DataDic.idCampoShow],
                        // status: DataComponente[DataDic.idCampoStatus || ""],
                        value: typeof DataComponente[DataDic.idCampoDestino] === "string" ? DataComponente[DataDic.idCampoDestino] : DataComponente[DataDic.idCampoDestino].toString(),
                        icon: DataComponente[DataDic.idCampoIcon || "icon"],
                        pk: {
                          Campo: pk?.Campo || "",
                          Valor: DataComponente[pk?.Campo || ""]
                        },
                        element: DataComponente
                        // EmpresaUser: DataComponente.cb_EmpresaUsers
                      }
                    //   console.log(DataShow)
                      TemDataSendComponent.push(Dato)
                    //   console.log(Dato)
                    //   console.log(">>>>>>>>>>>>>>>>>>>>", DataComponente.hide)
                    }
                  });
                //   console.log(DataShow)
                //   console.log("@@@ CAMPO:", DataDic.idCampoDestino)
                //   console.log("@@@ PK:", pk?.Campo);
                  // console.log(TemDataSendComponent);
                  
                  
                  
                  
                  
                  this.DataSendComponent = TemDataSendComponent;
                  console.log(this.DataSendComponent,"DataSendComponent");
                  
                if(!this.value && TemDataSendComponent[0]) {
                  this.ValueSalida.emit(TemDataSendComponent[0].value); // Valor inicial seleccionado
                }
                })
                .catch(err => {
          
                  
                  console.error(err);
                })
            })
            .catch(err => {
              console.log("PRUEBA");
              console.error(err)
            })

        } catch (error) {
          console.error(error)
        }
      })
    }

    

    GetDataSelect(Value: string | string[]) {
      this.ValueSalida.emit(Value);
    }

    /** Agregar un nuevo elemento */
    AddItemData(vars: IFormData["vars"]) {
      const option = {
        value: vars[this.diccionario.idCampoDestino || ""],
        datoShow:  vars[this.diccionario.idCampoShow],
      }

      if(this.DataSendComponent.every(item => item.value !== option.value)) {
        this.DataSendComponent.push(option);
        this.inputService.RegisterInput(this.TableName, this.diccionario.idCampoInsertion, vars);

        if(this.DataSendComponent.length == 1) {
          this.ValueSalida.emit(this.DataSendComponent[0].value);
        }
      }
    }

    /** Eliminar un elemento */
    RemoveItemDato(index: number) {
      if(index < this.DataSendComponent.length) {
        const option = this.DataSendComponent.splice(index, 1)[0];
        
        if(option.pk) {
          this._helperApi.UpdateData(this.diccionario.TableNameDestino, option.pk.Campo, {[option.pk.Campo]: option.pk.Valor, Status: 0})
            .then(() => {
            //   console.log("Eliminado con éxito");
            })
            .catch(() => {
            //   console.log("Destruido con éxito");
            })
        }
      }
    }

    UpdateItemData({index, data}: {index: number, data: {[key: string]: any}}) {
      if(index < this.DataSendComponent.length) {
        const option = this.DataSendComponent[index];
        
        for(let key in data) {
          option.element[key] = data[key];
        }
        
        if(option.pk) {
        //   console.log("MORTAL COMBAT::::::::", this.value);
          
          option.element[option.pk.Campo] = option.pk.Valor;
          // this.DataSendComponent = this.DataSendComponent.map((item: any) => ({...item}));
          option.datoShow = data[this.diccionario.idCampoShow] || option.element[this.diccionario.idCampoShow];
          // status: DataComponente[DataDic.idCampoStatus || ""],
          option.value = data[this.diccionario.idCampoDestino] || option.element[this.diccionario.idCampoDestino];
          
        //   console.log("DICCIONARION:::", this.diccionario);
        //   console.log("data:::::", option)
        //   console.log("PK:::::", option.pk)
          this._helperApi.UpdateData(this.diccionario.TableNameDestino, option.pk.Campo, {...data, [option.pk.Campo]: option.pk.Valor})
            .then(() => {
            //   console.log("Actualizado con éxito");
            })
            .catch(() => {
            //   console.log("Destruido con éxito");
            })
        }
      }
    }

    // ActualizarDatos(vars: IFormData["vars"]) {
    //   const option = {
    //     value: vars[this.diccionario.idCampoDestino || ""],
    //     datoShow:  vars[this.diccionario.idCampoShow],
    //   }

    //   console.log("DIC:", this.diccionario);
    //   console.log("vars:", vars);
    //   console.log("OPTION:", option);
    //   if(this.DataSendComponent.every(item => item.value !== option.value)) {
    //     this.DataSendComponent.push(option);
    //     // this.inputService.RegisterInput(this.TableName, this.diccionario.idCampoOrigen, vars);

    //     if(this.DataSendComponent.length == 1) {
    //       this.ValueSalida.emit(this.DataSendComponent[0].value);
    //     }
    //   }
    // }


    //https://backend.thomas-talk.me/api/v1/find-any-info/thomas_clubfibex/cb_Dictionary/idUnico/tec1

}
