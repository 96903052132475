import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import * as XLSX from 'xlsx';
import $, { param } from 'jquery';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, Observable, Subject, Subscription, switchMap } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
import { DataTableService } from 'src/app/services/datatable.service';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';
import { ApiClubService } from 'src/app/services/api-club.service';
import Swal from 'sweetalert2';

interface IReport {
	status: boolean;
	data: IDataReport[]
}

interface IDataReport{
	Empresa:string;
	Ciudad:string;
	idAbonado:string;
	nombrPromocion:string;
	Canjeado:string;
	FechaGenerado:string;
	HoraGenerado:string;
	FechaCanjeado: string,
	HoraCanjeado: string,
	nombreAbonado:string;
}

@Component({
  selector: 'app-report-coupons',
  templateUrl: './reportCoupons.component.html',
  styleUrls: ['./reportCoupons.component.scss'],
  encapsulation: ViewEncapsulation.None // Desactiva la encapsulación
})
export class ReportCouponsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("datatable3", { static: true}) datatable3!: ElementRef;
	showMenu: boolean = false;
	showOptions: boolean = false;
	loading: boolean = true;
    currentTable: any[] = []
	private inicializedTable: boolean = false;
	public rows: any[] = [];
	public showFilter: boolean = false;
	public isfilteredTable: boolean = false;
	public errorFechaMax: boolean = false;
	public canShowInput: TipoInput[] = [];
	public columns: any[] = [];
	public estructuraInputs: TipoInput[] = [];
	public camposFilter: string[] = ["FechaGenerado","idEmpConv"];
	public columnsFilter: any[] = [];
	public datatablesParameters: any;

	constructor(private dbFull: dbFullService, private fb: FormBuilder, private helper: HelperService, private datatableService: DataTableService, private _apiHelpr: HelperService, private apiClub: ApiClubService){
		
	}
	public dataForm: IFormData[] = [{defaultValues: {},error: {},vars: {}}];
	public data: any[] = [];
	public selectEmterprise: any[] = [] 
	selectProm: any[] = [];
	public dataAgrouped: any[]=[];
   

	public areExportingData: boolean = false;




	public obsEnt!: Subscription;
	public table!: any;
	public dataFilter: any = {};
	public timerFilter!: NodeJS.Timeout; 
	public TipoDato: { [key: string]: { descripcion: string, render: (data: any) => string } } = {
		Area: {
		  descripcion: 'Área de texto',
		  render: (data: any) => data ? data : 'N/A'
		},
		AutoIncrement: {
		  descripcion: 'Autoincremento',
		  render: (data: any) => Number.isInteger(data) ? data : 'N/A'
		},
		Avatar: {
		  descripcion: 'Imagen de avatar',
		  render: (data: any) => data ? `<img src="${data}" alt="Avatar" style="width:50px; height:50px;"/>` : 'N/A'
		},
		Boll: {
		  descripcion: 'Booleano',
		  render: (data: any) => data ? 'Sí' : 'No'
		},
		Date: {
		  descripcion: 'Fecha',
		  render: (data: any) => data ? new Date(data).toLocaleDateString('es-VE') : 'N/A'
		},
		TimeHour: {
			descripcion: 'Hora',
			//  render: (data: any) => data 
			render: (data: any) => { return data ?? "N/A"; }
				
		  },
		DateHour: {
	    	descripcion: 'Hora',
			//  render: (data: any) => data 
			render: (data: any) => {
				
				const date = new Date(data);
				console.log(data);
				const hours = date.getUTCHours().toString().padStart(2, '0');
				const minutes = date.getUTCMinutes().toString().padStart(2, '0');
				const seconds = date.getUTCSeconds().toString().padStart(2, '0');
				if(hours === 'NaN' || minutes === 'NaN' || seconds === 'NaN') return data ?? "N/A";
				const timeString = `${hours}:${minutes}:${seconds}`;
				return timeString;
			}
				
		  },
		Diccionario: {
		  descripcion: 'Diccionario',
		  render: (data: any) => data ? JSON.stringify(data) : 'N/A'
		},
		eAvatar: {
		  descripcion: 'Avatar extendido',
		  render: (data: any) => data ? `<img src="${data}" alt="Avatar" style="width:50px; height:50px;"/>` : 'N/A'
		},
		Email: {
		  descripcion: 'Correo electrónico',
		  render: (data: any) => data ? `<a href="mailto:${data}">${data}</a>` : 'N/A'
		},
		Map: {
		  descripcion: 'Mapa',
		  render: (data: any) => data ? data : 'N/A'
		},
		Money: {
		  descripcion: 'Dinero',
		  render: (data: any) => data ? `$${parseFloat(data).toFixed(2)}` : 'N/A'
		},
		Movil: {
		  descripcion: 'Número de móvil',
		  render: (data: any) => data ? data : 'N/A'
		},
		Name: {
		  descripcion: 'Nombre',
		  render: (data: any) => data ? data : 'N/A'
		},
		Number: {
		  descripcion: 'Número',
		  render: (data: any) => !isNaN(data) ? data : 'N/A'
		},
		Numeric: {
		  descripcion: 'Numérico',
		  render: (data: any) => !isNaN(data) ? data : 'N/A'
		},
		Password: {
		  descripcion: 'Contraseña',
		  render: (data: any) => data ? '******' : 'N/A'
		},
		String: {
		  descripcion: 'Cadena de texto',
		  render: (data: any) => data ? data : 'N/A'
		},
		Switch: {
		  descripcion: 'Interruptor',
		  render: (data: any) => (typeof data === 'number' && data === 1) || data === true ? 'ACTIVO' : 'INACTIVO'
		}
	  
	};
	  
	// Ejemplo de uso en DataTables

	ngOnInit(): void {

	}
	ngAfterViewInit(): void {
		this.initData();
	}
    toOpenMenu() {
        setTimeout(() => {
                document.body.addEventListener("click", this.toCloseMenu);
        }, 200)
        this.showMenu = true;
    }

    toCloseMenu = () => {
        document.body.removeEventListener("click", this.toCloseMenu)
        if(!this.showOptions){
            this.showMenu = false;
        }
        this.showOptions = false;
    }
	//DESCARGAR DATOS DEL DATATABLES
	async getDataExcel() {
		const batchSize = 10000;
		let allData: any[] = [];
		let start = 0;
		let parameters = {...this.datatablesParameters};
		parameters.length = batchSize;
		while (true) {
			parameters.start = start;
			const response = await this.apiClub.fetchCouponReport(environment.URLApiLocal, batchSize, start, parameters.search.value, parameters.filterData);
			const responseData = response.data;
			allData = allData.concat(responseData.map((data: any) => {
				const row: any = {};
				const columns = this.estructuraInputs.filter((input: TipoInput) => input.ShowList== 1) ;
				columns.forEach((input: TipoInput) => {
					row[input.Campo] = data[input.Campo] ? this.TipoDato[input.TipoDato].render(data[input.Campo]) : '';
					// if (input.TipoDato === 'Date') {
					// 	row[input.Campo] = data[input.Campo] ? new Date(data[input.Campo]).toLocaleString() : '';
					// } else if (input.TipoDato === 'Number') {
					// 	row[input.Campo] = data[input.Campo] ? data[input.Campo].toString() : '';
					// } else {
					// 	row[input.Campo] = data[input.Campo] ? this.datatableService.render(input, data[input.Campo]) : '';
					// }
				});
				return row;
			}));

			if (responseData.length < batchSize) {
				break;
			}

			start += batchSize;
		}
		return allData;
	}
	onClickButtonExport(): void {
			Swal.fire({
				title: "¿Deseas descargar el documento?",
				showDenyButton: true,
				confirmButtonText: "Aceptar",
				denyButtonText: `Cancelar`
			  }).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) 
				 this.downloadExcel();
			  });
		}
	async  downloadExcel() {
		try {
			  this.areExportingData = true;
			  const allData = await this.getDataExcel();
			  console.log(allData,"allData");
		  
			  // Obtener los títulos de las columnas
			  const columnTitles: any = this.columns.reduce((acc: any, column: any) => {
				acc[column.data] = column.title;
				return acc;
			  }, {});
			  console.log("columnTitles",columnTitles);
			  
			  // Reemplazar claves con títulos
			  const excelData = allData.map(item => {
				const newItem: any = {};
				for (const key in item) {
				  if (item.hasOwnProperty(key)) {
					newItem[columnTitles[key]] = item[key];
				  }
				}
				return newItem;
			  });
			  console.log(excelData);
			  
			  const worksheet = XLSX.utils.json_to_sheet(excelData);
			  const workbook = XLSX.utils.book_new();
			  this.areExportingData = false;
			  XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
			  XLSX.writeFile(workbook, 'reporte_cupones.xlsx');
		} catch (error) {
					
			this.areExportingData = false;
		  Swal.fire({
			
			icon: "error",
			title: "Error",
			text: "¡Ha ocurrido un error en la generación de su reporte por favor intente nuevamente!",
		  });
		  console.error('Error al descargar los datos:', error);
		}
	}
	async initData() {
		//Obetnemos el header de la tabla; 
		await this.getHeaderData();
		
		//Buscamos los campos que se van a giltrar
		const columuns = this.canShowInput;
		const filterCampos = this.estructuraInputs.filter(input => this.camposFilter.includes(input.Campo));
		this.columnsFilter = filterCampos;
		console.log(this.columnsFilter);

		console.log(this.canShowInput);
		//Definimos las columnas y su renderizado en la tabla
		this.columns = columuns.map(columnHeader => { 
			return {data: columnHeader.Campo, title: columnHeader.HeadListName, render: this.TipoDato[columnHeader.TipoDato].render}
		});
		console.log(this.columns);
		
		if (!this.inicializedTable) {
			this.inicializedTable = true;                
			this.table = ($(this.datatable3.nativeElement) as any).DataTable({
				language: {
					searchPlaceholder: 'Buscar...',
					sSearch: '',
					info: 'Mostrando _START_ - _END_ de _TOTAL_ registros',
					lengthMenu: 'Mostrar _MENU_',
					paginate: {
						previous: 'Anterior',
						next: 'Siguiente'
					}
				},
				processing: true, 
				serverSide: true,
				searchDelay: 1000,
				'ajax': (dataTablesParameters: any, callback: any) => { 
					if (this.dataFilter) {
						dataTablesParameters.filterData = this.dataFilter;
					}
					this.datatablesParameters = dataTablesParameters;
					
					// Usar throttle para limitar la frecuencia de las llamadas
					console.log( ($ as any).fn.dataTable.util);
					
					// const throttledRequest =setTimeout(() => {
						this.datatableService.getCouponReportCached(dataTablesParameters, this.estructuraInputs).then((resp: any) => { 
							console.log(dataTablesParameters);
							this.rows = resp.data;
							callback({ recordsTotal: resp.totalRecords, recordsFiltered: resp.filteredRecords, data: resp.data }); 
						});
					// }, 1000); // Límite de 1 segundo
					// console.log(throttledRequest);
					
					// throttledRequest();
				},
				columns: this.columns
			});
		}
		
		const value = this.table;
		console.log(value);
	}
	showModalFilter(){
		
		this.showFilter=true;
	
	}
	hideModalFilter(){
		this.showFilter=false; 		
	}

	groupBy(array: any[], key: any) {
			return array.reduce((result, currentValue) => {
			if(typeof(currentValue)=="string"){
				(result[currentValue[key].trim()] = result[currentValue[key].trim()] || []).push(currentValue);
			}
			else (result[currentValue[key].trim()] = result[currentValue[key].trim()] || []).push(currentValue);
		  return result;
		}, {});
	}	

	stopFilter(){
		
		// ($ as any).fn.dataTableExt.afnFiltering.length = 0;
		this.isfilteredTable = false;
		this.dataForm = [];
		// this.formG.reset();
		// this.cambiarData();
		this.dataFilter = {};
		this.table.draw();	
	}

	ngOnDestroy(): void {
		($ as any).fn.dataTableExt.afnFiltering.length = 0;
		// this.obsEnt.unsubscribe();
	}

	async getHeaderData() {
		try {
		  const Estructura: TipoInput[] = await this.dbFull.GetAllDataCampo('cb_Estructura', 'TableName', "Reporte_Club_FIbex");
	  
		  if (!Estructura || Estructura.length === 0) {
			throw new Error("No se encontró la estructura");
		  }
	  
		  // Ordenar la estructura
		  this.estructuraInputs = Estructura.sort((a, b) => a.OrdenList - b.OrdenList);
	  
		  // Filtrar entradas que se pueden mostrar
		  this.canShowInput = this.estructuraInputs.filter(input => input.ShowList === 1);
	  
		  // Puedes retornar la estructura ordenada y filtrada si es necesario
		  return this.estructuraInputs;
	  
		} catch (error) {
		  console.error('Error al obtener los datos de la estructura:', error);
		  throw error; // O manejar el error de una manera adecuada
		}
	  }
	  async getDataFilter($event: any){

		this.hideModalFilter(); 
		console.log($event,"event");
		this.dataFilter= $event;
		if(Object.values(this.dataFilter).length>0){
			this.isfilteredTable=true;
			this.dataForm[0].vars = this.dataFilter;
		}
		console.log(this.dataForm);
		
		this.table.draw();
	  }
 }
