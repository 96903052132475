<input 
    type="tel" 
    class="form-control" 
    [placeholder]="data.PlaceHolder" 
    [name]="data.Campo" 
    [required]="data.Requerido + (!!data.Requerido ? '*' : '')" 
    (keypress)="allowOnlyNumbersAndHyphen($event)" 
    (paste)="onPaste($event)" 
    (input)="formatPhoneNumber($event)" 
    (change)="HanderOnChange($event)" 
    (blur)="HanderOnChange($event)" 
    [maxLength]="data.MaxLen" 
    [hidden]="!data.ShowInsert" 
    [value]="value" 
    [disabled]="data.Reading === 1 ? true : false"
/>


<!-- <input
  type="text"
  class="form-control"
  [placeholder]="param.Campos[i]"
  [name]="param.Campos[i]"
  [(ngModel)]="param.Value[i]"
  [class.error]="newUserForm.submitted && param.Campos[i].invalid"
  (keypress)="keypressControPhones($event,param.Campos[i],newUserForm.submitted)"
  #[param.Campos[i]]="ngModel"
  [required]="param.Required[i]"
/> -->