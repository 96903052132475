import { Component } from '@angular/core';
import { IChartData } from 'src/app/shared/components/chart/chart.component';
import { dbFullDataBase, dbFullTable } from 'src/assets/lib/dbfullv2';
import { Icb_CantAliadosActivos, Icb_CantCupones, Icb_CantCuponesDisponibles, Icb_CantCuponesMonto, Icb_CantCuponesUsados, Icb_CantEmpresasAliados, Icb_CantPromocionesActivas, Icb_CantPromoEmpresasAliados, Icb_PromocionesInactivas } from 'src/app/models/tables';
import { ColorService } from 'src/app/services/color.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';
import { environment } from '../../../environments/environment';
import { PushNotificationService } from 'src/app/services/pushNotification.service';
import { dbFullService } from 'src/app/services/dbFull.service';
import { AuthService } from 'src/app/services/auth.service';

interface ChartItem {
  title: string,
  loading: boolean,
  formatter?: (value: number) => string,
  data: IChartData[]
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  // Datos de los charts generados
  public charts: ChartItem[] = [];
  public cards: {
    title: string,
    icon: string,
    cant: number | string
  }[] = [];
  // Tablas
  public cb_CantCupones: dbFullTable<Icb_CantCupones>;
  public cb_CantCuponesDisponibles: dbFullTable<Icb_CantCuponesDisponibles>;
  public cb_CantCuponesMonto: dbFullTable<Icb_CantCuponesMonto>;
  public cb_CantCuponesUsados: dbFullTable<Icb_CantCuponesUsados>;
  public cb_CantEmpresasAliados: dbFullTable<Icb_CantEmpresasAliados>;
  public cb_CantPromoEmpresasAliados: dbFullTable<Icb_CantPromoEmpresasAliados>;
  public cb_CantAliadosActivos: dbFullTable<Icb_CantAliadosActivos>;
  public cb_CantPromocionesActivas: dbFullTable<Icb_CantPromocionesActivas>


  public currentChart?: ChartItem;
  private masterPermission: boolean = false; 
  public defaultValue: any = 0;

  constructor(private auth: AuthService,public colorService: ColorService, private _SesionstorageService:SesionstorageService, private _notfication: PushNotificationService, private dbfull: dbFullService) {
    const db = new dbFullDataBase(environment.bd);
    
    this.cb_CantCupones             = db.CreateChild("cb_CantCupones");
    this.cb_CantCuponesDisponibles  = db.CreateChild("cb_CantCuponesDisponibles");
    this.cb_CantCuponesMonto        = db.CreateChild("cb_CantCuponesMonto");
    this.cb_CantCuponesUsados       = db.CreateChild("cb_CantCuponesUsados");
    this.cb_CantEmpresasAliados     = db.CreateChild("cb_CantEmpresasAliados");
    this.cb_CantPromoEmpresasAliados = db.CreateChild("cb_CantPromoEmpresasAliados");
    this.cb_CantAliadosActivos = db.CreateChild("cb_CantAliadosActivos")
    this.cb_CantPromocionesActivas = db.CreateChild("cb_CantPromocionesActivas");
    // this.currentChart = this.charts[0];
  }

  ngOnInit() {
    // const ROLE = this._SesionstorageService.GetSesionStorage('login').Data.idRol
    const ROLETYPE = this._SesionstorageService.GetSesionStorage('login').Data.Type
    //const ROLE = JSON.parse(sessionStorage.getItem('login') || '{}').idRol
    // console.log("ROLETYPE >>>> ", ROLETYPE)
    if(ROLETYPE === 0) this.masterPermission = true;

    this.InitData();
  }

  async InitData() {
    const { idEmpConv, idUser } = this._SesionstorageService.GetSesionStorage('login').Data;
    const cardsReference: HomeComponent["cards"] = this.cards = [];
    this.charts = []; // Reiniciar datos

    // 1. Cargar datos relacionados a los cupones
    await this.loadCuponesData(idEmpConv, cardsReference);
    
    // 2. Cargar datos relacionados a las promociones
    await this.loadPromocionesData(idEmpConv, cardsReference);

    // 3. Cargar datos relacionados a los aliados (solo si el usuario tiene permisos de master)
    if (this.masterPermission) {
        await this.loadAliadosData(cardsReference);
    }

    // 4. Cargar datos relacionados a las tarjetas regalo
    await this.loadTarjetasRegaloData(cardsReference);
}

async loadCuponesData(idEmpConv: string, cardsReference: any[]) {
    // Datos de la cantidad de cupones
    const CantCuponesData = await this.loadChartData(
        this.cb_CantCupones,
        `SELECT ccc.Fecha, SUM(Cantidad) as Cantidad FROM cb_CantCupones ccc ${!this.masterPermission ? `WHERE ccc.idEmpConv='${idEmpConv}'` : ''} GROUP BY ccc.Fecha`,
        "Cupones Generados",
        "../assets/icons/icon-coupon.png"
    );
    if (CantCuponesData) {
        this.charts.push(CantCuponesData.chart);
        cardsReference.push(CantCuponesData.card);
        this.switchButton(CantCuponesData.chart.title);
    }

    // Datos de los cupones disponibles
    const CantCuponesDisponiblesData = await this.loadChartData(
        this.cb_CantCuponesDisponibles,
        `SELECT cccd.Fecha, SUM(cccd.Cantidad) as Cantidad FROM cb_CantCuponesDisponibles cccd ${!this.masterPermission ? `WHERE cccd.idEmpConv='${idEmpConv}'` : ''} GROUP BY cccd.Fecha`,
        "Cupones Disponibles",
        "../assets/icons/icon-coupon.png"
    );
    if (CantCuponesDisponiblesData) {
        this.charts.push(CantCuponesDisponiblesData.chart);
        cardsReference.push(CantCuponesDisponiblesData.card);
    }

    // Datos de los cupones usados
    const CantCuponesUsadosData = await this.loadChartData(
        this.cb_CantCuponesUsados,
        `SELECT cccu.Fecha, SUM(cccu.Cantidad) as Cantidad FROM cb_CantCuponesUsados cccu ${!this.masterPermission ? `WHERE cccu.idEmpConv='${idEmpConv}'` : ''} GROUP BY cccu.Fecha`,
        "Cupones Canjeados",
        "../assets/icons/icon-coupon.png"
    );
    if (CantCuponesUsadosData) {
        this.charts.push(CantCuponesUsadosData.chart);
        cardsReference.push(CantCuponesUsadosData.card);
    }

    // Datos de los montos de los cupones
    const CantCuponesMontoData: any = await this.loadChartData(
        this.cb_CantCuponesMonto,
        `SELECT cccm.Fecha, SUM(cccm.CantidadDisponibles) as CantidadDisponibles, SUM(cccm.CantidadUsados) as CantidadUsados FROM cb_CantCuponesMonto cccm ${!this.masterPermission ? `WHERE cccm.idEmpConv='${idEmpConv}'` : ''} GROUP BY cccm.Fecha`,
        "Montos Cupones",
        "../assets/icons/icons8-cash-48.png",
        true
    );
    if (CantCuponesMontoData) {
        this.charts.push(CantCuponesMontoData.chart);
        cardsReference.push(...CantCuponesMontoData.cards);
    }
}

async loadPromocionesData(idEmpConv: string, cardsReference: any[]) {
    // Datos de las promociones de las empresas aliadas
    const CantPromoEmpresasAliadosData = await this.loadChartData(
        this.cb_CantPromoEmpresasAliados,
        `SELECT ccpea.Fecha, SUM(ccpea.Cantidad) as Cantidad FROM cb_CantPromoEmpresasAliados ccpea ${!this.masterPermission ? `WHERE ccpea.idEmpConv='${idEmpConv}'` : ''} GROUP BY ccpea.Fecha`,
        "Promociones de las empresas aliadas",
        "../assets/icons/promociones2.png"
    );
    if (CantPromoEmpresasAliadosData) {
        this.charts.push(CantPromoEmpresasAliadosData.chart);
    }

    // Datos de las promociones activas
    const CantPromocionesActivasData = await this.loadChartData(
        this.cb_CantPromocionesActivas,
        `SELECT  Fecha, SUM(ccaa.Cantidad) AS Cantidad FROM cb_CantPromoEmpresasAliados ccaa GROUP BY Fecha`,
        "Cantidad promos empresas aliadas",
        "../assets/icons/promociones2.png"
    );
    if (CantPromocionesActivasData && this.masterPermission) {
        cardsReference.push(CantPromocionesActivasData.card);
    }
}

async loadAliadosData(cardsReference: any[]) {
    // Datos de la cantidad de aliados
    const CantEmpresasAliadosData = await this.loadChartData(
        this.cb_CantEmpresasAliados,
        `SELECT ccea.Fecha, SUM(ccea.Cantidad) as Cantidad FROM cb_CantEmpresasAliados ccea GROUP BY ccea.Fecha`,
        "Empresas aliadas",
        "../assets/icons/company.png"
    );
    if (CantEmpresasAliadosData && this.masterPermission) {
        this.charts.push(CantEmpresasAliadosData.chart);
        cardsReference.push(CantEmpresasAliadosData.card);
    }

    // Datos de las empresas activas
    const CantAliadosActivosData = await this.loadChartData(
        this.cb_CantAliadosActivos,
        `SELECT DATE(ccaa.createdAt) as Fecha, ccaa.Cantidad FROM cb_CantAliadosActivos ccaa GROUP BY Fecha`,
        "Empresas activas",
        "../assets/icons/company.png"
    );
    if (CantAliadosActivosData && this.masterPermission) {
        cardsReference.push(CantAliadosActivosData.card);
    }
}

async loadTarjetasRegaloData(cardsReference: any[]) {
    const hasGiftCardPermission = await this.validateGiftcardComponents();
    if (hasGiftCardPermission) {
        const unusedGiftCards = await this.dbfull.getCountGiftCardsUnUsed();
        cardsReference.push({
            title: "Tarjetas Regalo Sin Canjear",
            icon: "../../../assets/icons/icons8-gift-card-52.png",
            cant: unusedGiftCards as number
        });

        const usedGiftCards = await this.dbfull.getCountGiftCardsUsed();
        cardsReference.push({
            title: "Tarjetas Regalo Canjeadas",
            icon: "../../../assets/icons/icons8-gift-card-52.png",
            cant: usedGiftCards as number
        });
    }
}

async loadChartData(
    table: dbFullTable<any>,
    query: string,
    title: string,
    icon: string,
    isMonto: boolean = false
): Promise<{ chart: ChartItem; card?: any; cards?: any[] } | null> {
    try {
        const data = await table.GET_ANY_QUERY<any>({ query });
        if (data.length > 0) {
            const chartData: IChartData = {
                tag: title,
                color: this.colorService.GetDynamicColor(),
                data: data.map((item: any) => ({
                    id: item.Fecha,
                    value: item.Cantidad || item.CantidadUsados || item.CantidadDisponibles
                }))
            };

            const chart: ChartItem = {
                title,
                loading: false,
                data: [chartData]
            };

            if (isMonto) {
                const cards = [
                    {
                        title: "Monto Cupones Generados",
                        icon,
                        cant: this.getDigitalMoney(data[data.length - 1].CantidadDisponibles)
                    },
                    {
                        title: "Monto Cupones Canjeados",
                        icon,
                        cant: this.getDigitalMoney(data[data.length - 1].CantidadUsados)
                    }
                ];
                return { chart, cards };
            } else {
                const card = {
                    title,
                    icon,
                    cant: data[data.length - 1].Cantidad
                };
                return { chart, card };
            }
        }
    } catch (err) {
        console.error(err);
    }
    return null;
}



  getDigitalMoney(money: number): string {
    // restar 3 significa quitarle el sufijo al texto ".00", un texto de ejemplo: "150.00" <<-- se quita .00
    const textFixed = Number(money).toFixed(2);
    const textSliced = textFixed.toString().slice(0, textFixed.toString().length-3);
    const fragText: string[] = [];


    for(let x = textSliced.length; x > 0; x-=3) {
      fragText.unshift(textSliced.slice(x-3 >= 0 ? x-3 : 0, x));
    }

    return fragText.join(".") + "," + textFixed.toString().slice(textFixed.toString().length-2, textFixed.toString().length) + " $";
  }

  formatterToInt(value: number): string {
    return Math.round(Number(value)).toString();
  }

  getDataLengthGiftCardsUsed(){
    
  }
  getDataLengthGiftCardsWithoutUse(){
    
  }














  // dataInputCards: any[] = [];
  // empresaDataChart: any;
  // cuponesDataChart: any;
  // productosDataChart: any;

  // /*
  //   ! Configuracion estatica enviada a cada chart para renderizar texto y determinar el tipo de chart
  // */
  // configEmpresaChart: any[] = [{
  //     id: 'empresa-chart',
  //     title: 'Aliados activos por mes',
  //     typeChart: 'line',
  //     label: 'Registros por mes'
  // }];
  // idCuponesChart: any[] = [
  //   {
  //     id: 'cupones-chart',
  //     title: 'Cupones generados',
  //     typeChart: 'bar',
  //     label: 'Registros por mes'
  //   },
  //   {
  //     title: 'Cupones Usados',
  //     label: 'Registros por mes'
  //   },
  // ];
  // idProductosChart: any[] = [{
  //     id: 'productos-chart',
  //     title: 'Total de productos',
  //     typeChart: 'bar',
  //     label: 'Registros por mes'
  // }];


  // constructor( private dbFull: dbFullService ) { }

  // ngOnInit(): void {
  //   this.getDataChartsCards();
  // }

  // getDataChartsCards() {
  //   this.getDataEmpresas();
  //   this.getDataCupones();
  //   this.getDataProductos();
  // }


  // getDataEmpresas() {
  //   this.dbFull.getCountDataQuery('cb_EmpresaConvenio')
  //   .then((data: any) => {
  //     const dataCards = {
  //       title: 'Aliados activos',
  //       icon: '../assets/icons/aliado2.png',
  //       cant: 0
  //     };

  //     const filterDataCard =this.filterDataToCard( data, dataCards );
  //     // * Sumar para obtener total de empresas activas en bbdd
  //     // this.dataInputCards.push( filterDataCard );

  //     // * Organizar data para grafica
  //     this.empresaDataChart = this.handleMonth(data);
  //   })
  //   .catch(err => console.log(err));
  // }

  // getDataCupones() {
  //   /*
  //     * Data Estatica para generar ejemplos
  //   */
  //   const dataCards = {
  //     title: 'Cupones activos',
  //     icon: '../assets/icons/promociones2.png',
  //     cant: 0
  //   };

  //   const objTest = {
  //     cuponesGenerados: [
  //       { mes: 4, cantidad: 30, },
  //       { mes: 5, cantidad: 20, },
  //       { mes: 6, cantidad: 80, },
  //       { mes: 7, cantidad: 100, },
  //       { mes: 8, cantidad: 20, }
  //     ],

  //     cuponesUsados: [
  //       { mes: 4, cantidad: 20, },
  //       { mes: 5, cantidad: 10, },
  //       { mes: 6, cantidad: 80, },
  //       { mes: 7, cantidad: 50, },
  //       { mes: 8, cantidad: 15, }
  //     ]
  //   }

  //   const filterData = this.filterDataToCard(objTest.cuponesGenerados, dataCards)
  //   this.dataInputCards.push(filterData);

  //   console.log(this.dataInputCards)

  //   const data1 = this.handleMonth(objTest.cuponesGenerados);
  //   const data2 = this.handleMonth(objTest.cuponesUsados);


  //   // ! Se coloco este timeout con el fin de simular tiempos de la petición
  //   setTimeout(() => {
  //     this.cuponesDataChart = {
  //       cuponesGenerados: data1,
  //       cuponesUsados: data2
  //     }
  //   },500);
  // }

  // getDataProductos() {
  //   this.dbFull.getCountDataQuery('cb_EmpresaProductos').then((res: any) => {
  //     this.productosDataChart = this.handleMonth( res )
  //   });
  // }


  // /*
  //   ! Filtrar data para las cards y sumar
  // */
  // filterDataToCard( data: any[], dataCards: any ) {
  //   for( let mount of data ) {
  //     dataCards.cant += mount.cantidad;
  //   };

  //   return dataCards;
  // }

  // /*
  //   ! Filtrado y cambio de los meses
  // */
  // handleMonth(data: any[]) {
  //   const dataChart = data.map((month: any) => {
  //     month.mes = MONTHS[ month.mes - 1 ];
  //     return month;
  //   });

  //   return dataChart;
  // }
  public switchButton(title: string){
    
    const chart: ChartItem[] = this.charts.filter(char=>char.title.includes(title));
    console.log(chart);
    this.currentChart = chart[0];
  }
  public async validateGiftcardComponents(){
    try {
      const { Data } = this._SesionstorageService.GetSesionStorage('login')
      const actions = await this.auth.retrieveActions(Data.idRol)
      const options = actions.filter( (e: any) => e.Componente === 'ReportGiftcardComponent')
      console.log(options,"options");		
      if(options && options.length > 0) return true;
      else return   false;
    } catch (error) {
      console.log(error);
      return false;
    }
     
    }
}
