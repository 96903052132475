import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
//import { CloudynariService } from 'src/app/services/cloudDinary.service';
import { CloudynariServicev2 } from 'src/app/services/cloudDinaryv2.service';
import { error } from 'jquery';
import { reject } from 'lodash';

@Component({
  selector: 'app-avatar-generic',
  templateUrl: './avatar-generic.component.html',
  styleUrls: ['./avatar-generic.component.scss']
})
export class AvatarGenericComponent  implements OnInit ,OnDestroy, AfterViewInit{
    // @Input() Avatar: any;   // para llamar este componente debes valiar antes de que lo que pasas contiene id_d_
    @Input() name: string | undefined;
    @Input() UrlIMg: string="";
    @Input() imgDisabled: number | boolean = 0;
    @Input() downloadImg: number | boolean = 0;    
    @Output() ValueSalida = new EventEmitter<string>();
    @Output() onDelete = new EventEmitter<void>(); 
    @Input() tableName: string = "";

    @Input() resolutionImage!: {tableName: string, typeResolution?: string, width: number, height: number, widthMob?: number, heightMob?: number};
    // notificar que se ha eliminado
    // @Input() CanEdit: boolean | new EventEmitter<boolean>;
    public ImgDefecto: string = "../../../../assets/img/add-photo.jpg"
    public showEditimg: boolean = false;
    public validFormats = ['jpg', 'jpeg', 'png','webp'];
    public extn: any ="";
    public indexof: any =0;
    public validResolutionSize: boolean = true;
    public ValidExtension: boolean = true;
    public ValidSize: boolean = true;
    public currentResolution!: {widht: number, height: number};

    public limitMB: number = 2;
    public uploadingImg = false;
    public imageUrl: any="";


    constructor(private _CloudynariServicev2:CloudynariServicev2, private _dbFullService:dbFullService) {}


    ngOnInit(): void {
      // if(!this.UrlIMg) {
      //   this.ValueSalida.emit(this.ImgDefecto);
      // }

      // this.ValueSalida.subscribe((resp:boolean)=>{
      //   //Aqui debería mandarme solo booleano
      //   console.log("ValueSalidaescuchando");
      //   this.CanEdit = resp;
      // })
    }
    
    ngOnDestroy(): void {
      
      // this.ValueSalida.unsubscribe();
    }

    ngAfterViewInit() {
      $('.custom-dropdown').on('show.bs.dropdown', function () {
        var that = $(this);
        setTimeout(function () {
          that.find('.dropdown-menu').addClass('active');
        }, 100);
      });
      $('.custom-dropdown').on('hide.bs.dropdown', function () {
        $(this).find('.dropdown-menu').removeClass('active');
      });
      
    }

    RemoveAvatar(){
      console.log('elimino');
      this.UrlIMg=this.ImgDefecto;
      this.onDelete.emit();
    }


    GetDataSelect(Value: string) {
      this.ValueSalida.emit(Value);
    }

    test(event:any){
      console.log(event);
    }

    onMouseEnter() {
      console.log("mouse enter");
    }

    onMouseOut(name: string) {
      console.log("mouse out", name);
    }

   uploadImagePayment2($event: any) {
      this.showEditimg = true;
      let reader = new FileReader();
      
      reader.readAsDataURL($event.target.files[0]);
     
      
      
      reader.onload =  async() => {
        
        let imageBase64: any = reader.result;
        if(this.resolutionImage){
       await this.getImageResolution(imageBase64).then((response: any)=>{
          console.log(response);
          this.validResolutionSize= this.verifyFileSizeResolution(response.width,response.height)
          console.log(this.validResolutionSize);
   
         });
        }
        

        const fileList: FileList = $event.target.files;
        //Extraigo la extension del arhivo que subio
        this.extn = fileList[0].name.split(".").pop();
        //Valido si es aceptado la extenxion
        this.indexof = this.validFormats.indexOf(this.extn.toLowerCase());
        console.log("ACTUAL ESTADO DEL VALIDADOR DE RESOLUCION: "+this.validResolutionSize);
        console.log(this.indexof)

        if (this.indexof != -1) {
          this.ValidExtension = true;
          if (fileList.length > 0) {
            const file = fileList[0];
            if (!this.verifyFileSize(file.size)) {
              this.ValidSize=false;
              this.uploadingImg = false;
              return;
            }
            if(!this.validResolutionSize){
              console.log("SIZE INCORRECTO: ");
              this.uploadingImg = false;
              return;
            }

            var dt = new Date();
            let year = dt.getFullYear();
            let month = (dt.getMonth() + 1).toString().padStart(2, "0");
            let day = dt.getDate().toString().padStart(2, "0");
            let Hour = dt.getHours().toString();
            let Minute = dt.getMinutes().toString();
            let Second = dt.getSeconds().toString();
            let NameCloud = 'Avatar' + '-' + day + '-' + month + '-' + year + '-' + Hour + Minute + Second

            console.log("UPLOADING...")
            //Paso el file en base64 y el nombre que se le asignara
            this._CloudynariServicev2.upload_images(imageBase64, NameCloud)
            .then((response: any) => {
              if (response.hasOwnProperty('error')) {
                console.error("Error al tratar de subir la imagen")
                return;
              }
              console.log(response)
              this.uploadingImg = false;
              imageBase64 = '';
              this.imageUrl = response;
              this.UrlIMg= this.UrlIMg ?? this.imageUrl;
              console.log("UPLOADED...", this.imageUrl)
                this.ValueSalida.emit(this.imageUrl);
              })
              .catch((error: any) => {
                console.log("NO UPLOADED...")
                console.error(error);
              })
          }
        } else {
          this.ValidExtension = false;
          this.uploadingImg = false;
        }
      }
    }

    verifyFileSize(fileSize: number): boolean {
      fileSize =  fileSize / Math.pow(1024 , 2);
      if( fileSize <= this.limitMB ) {
       
        this.ValidSize=true;
        return true;
      }

      return false;
    }
   verifyFileSizeResolution(width: number, height: number){
        //Evaluar esto en otra funcion
        let widthImg; 
        let heightImg;
        let widthImgMob: number;
        let heightImgMob: number
        switch(this.name){
          case "imgDesktop":
          widthImg = this.resolutionImage.width;
          heightImg = this.resolutionImage.height;
          this.currentResolution = {widht: widthImg , height: heightImg}
          if(widthImg && heightImg && widthImg===width && heightImg===height) return true
          break;

          case "ImgDesktop":
          widthImg = this.resolutionImage.width;
          heightImg = this.resolutionImage.height;
          this.currentResolution = {widht: widthImg , height: heightImg}
          if(widthImg && heightImg && widthImg===width && heightImg===height) return true
          break;

          case "imgMobile":
          widthImgMob = this.resolutionImage.widthMob!;
          heightImgMob = this.resolutionImage.heightMob!;
          this.currentResolution = {widht: widthImgMob , height: heightImgMob}
          if(widthImgMob && heightImgMob && widthImgMob===width && heightImgMob===height) return true

          break;
          case  "ImgPhone": 
          widthImgMob  = this.resolutionImage.widthMob!;
          heightImgMob = this.resolutionImage.heightMob!;
          this.currentResolution = {widht: widthImgMob , height: heightImgMob}
          if(widthImgMob && heightImgMob && widthImgMob===width && heightImgMob===height) return true
          break;

          default: 
          let wImg = this.resolutionImage.width;
          let hImg = this.resolutionImage.height;
          this.currentResolution = {widht: wImg , height: hImg}
          if(wImg && hImg && wImg===width && hImg===height) return true
          break;
    }
    return false;
  }
 
  async getImageResolution(src: any) {
    return new Promise((resolve, reject) => {
        try {
            const image = new Image();
            image.src = src;
            console.log(image)
            image.onload = () => {
              console.log("Cargado")
                const width = image.width;
                const height = image.height;
                console.log("width "+width," height "+height);
                resolve({"width": width, "height": height});
            }
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
  }
  download() {
    // Supongamos que 'imageURL' es la URL de la imagen que quieres descargar
    
    let imageURL = this.UrlIMg;
    let dateNow  = new Date();
    this.downloadImage(imageURL,`img_${dateNow.getTime()}`);
  }
  downloadImage(imageUrl: string, imageName: string) {
    fetch(imageUrl).then(res => res.blob()).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', imageName);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    });
  }

    //https://backend.thomas-talk.me/api/v1/find-any-info/thomas_clubfibex/cb_Dictionary/idUnico/tec1

}
