import { Component } from '@angular/core';

@Component({
  selector: 'app-mainpanel',
  template: `
    <div class="sh-mainpanel" style='overflow: hidden; height: 100vh;'>
      <div style='overflow: auto; height: -webkit-fill-available; position: relative; height: 100%;'>
        <ng-content></ng-content>
      </div>
    </div>
  `
})
export class MainpanelComponent {}
