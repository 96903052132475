<ng-container *ngIf="getParamActive()">

    <div class="slideout-content modal-content pt-0">
        <!-- Modal to add new user starts-->
        <!-- <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
            <div class="modal-dialog">

            </div>
        </div> -->
        <form id="form" #form class="form-modal" (submit)="(submitv2($event))">
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="toggleSidebar('new-user-sidebar')"> -->
            <!-- × -->
            <!-- </button> -->
            <div class="modal-header mb-1">
            <!-- APP TITLE -->
            <h5 class="modal-title" id="exampleModalLabel" style="position: sticky; top: 0;">{{getParamActive().TitleHead}}</h5>
            <!-- END APP TITLE -->
            <button *ngIf="!noClose" type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="sendMessage('cerrar')">
            <i class="icon ion-close"></i>
            <!-- × -->

            </button>
            </div>

            <div *ngIf="ConseguiData" class="modal-body flex-grow-1" style="overflow: auto; max-height: 300px;">

              <div *ngFor="let formControlItem of getParamActive().Order" class="w-100">
                <div *ngIf="formControlItem.ShowInsert" [hidden]="formControlItem.hidden" class="form-group">
                    <span [ngSwitch]="formControlItem.TipoDato">
                        <label *ngIf=" formControlItem.HeadListName !='' && formControlItem.TipoDato !='img' && formControlItem.ShowInsert"  class="form-label"
                        for="basic-icon-default-fullname">{{formControlItem.HeadListName + (formControlItem.Requerido ? '*' : '')}}</label>

                        <p *ngSwitchCase="'Diccionario'">
                            <app-field-dinamic [formData]="formDatas" [FieldDynamic]="formControlItem.ValorDiccionario" (ValueSalida)="EventoDinamic($event, formControlItem)" [idToSelect]="idToSend" [value]="FormDataActive.vars[formControlItem.Campo] || ''"
                            [disabledItem]="formControlItem.Reading === 1 ? true  : false"
                            ></app-field-dinamic>
                        </p>

                        <p *ngSwitchCase="'Numeric'">
                            <app-numeric [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-numeric>
                        </p>
                        <p *ngSwitchCase="'Map'">
                            <!-- <app-map [defaultValor]="PrepareValueMap(FormDataActive.vars[formControlItem.Campo])" (coords)="FormDataActive.vars[formControlItem.Campo] = $event[0] + '_' + $event[1]"></app-map> -->
                            <app-mapa [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-mapa>
                        </p>

                        <p *ngSwitchCase="'String'">
                            <app-string [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''" (ValueSalida)="EventoDinamic($event, formControlItem)" [otrosdatos]="params" ></app-string>
                        </p>

                        <p *ngSwitchCase="'Date'">
                            <app-date [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-date>
                        </p>

                        <p *ngSwitchCase="'Name'">
                            <app-name [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-name>
                        </p>

                        <p *ngSwitchCase="'Password'">
                            <app-password [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-password>
                        </p>

                        <p *ngSwitchCase="'Movil'">
                            <app-movil [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-movil>
                        </p>
                            <!-- [class.error]="newUserForm.submitted && formControlItem.Campo.invalid" -->

                        <p *ngSwitchCase="'Email'">
                            <app-email [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-email>
                        </p>

                        <p *ngSwitchCase="'Area'">
                            <app-area [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-area>
                        </p>

                        <!-- <img *ngSwitchCase="'img'" src="assets/img/anonimo.png" width="60" /> -->

                        <p *ngSwitchCase="'Avatar'" class="AvatarStyle" style="text-align: center;">
                            <app-avatar [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''" [resolutionImage]="currentResolutionImage"></app-avatar>
                        </p>

                        <p *ngSwitchCase="'eAvatar'" class="AvatarStyle">
                            <app-ext-avatar [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-ext-avatar>
                        </p>

                        <p *ngSwitchCase="'Switch'">
                            <app-switch [data]="formControlItem" [formData]="FormDataActive"   [value]="FormDataActive.vars[formControlItem.Campo] || ''" (ValueSalida)="EventoDinamic($event, formControlItem)"></app-switch>
                        </p>

                        <p *ngSwitchCase="'Color'">
                            <app-color [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-color>
                        </p>

                        <p *ngSwitchCase="'DependencySelection'">
                            <app-dependency-selection></app-dependency-selection>
                        </p>

                        <p *ngSwitchCase="'Money'">
                            <app-money [data]="formControlItem" [formData]="FormDataActive" [value]="FormDataActive.vars[formControlItem.Campo] || ''"></app-money>
                        </p>

                    </span>

                <div style="color: red" *ngIf="FormDataActive.error[formControlItem.Campo]">{{FormDataActive.error[formControlItem.Campo]}}</div>


              </div>
              </div>



            </div>

            <div style="display: flex;" class="p-3 row">
                <div class="col-sm-12 col-md-4 col-lg-4 mb-2" *ngIf="step === params.length -1 && params.length === 1">
                    <button type="reset" class="btn btn-block" style="background-color: #0195ec !important; color: white !important;"
                    rippleEffect
                    (click)="reset()">
                        <i class="fa fa-refresh"></i>
                        Resetear
                    </button>
                </div>


                <div class="col-sm-12 col-md-4 col-lg-4 mb-2" *ngIf="step && step <= params.length -1 && params.length !== 1">
                    <button type="reset" class="btn btn-block" style="background-color: #0195ec !important; color: white !important;"
                    rippleEffect
                    (click)="back()">
                    <!-- <i class="fa fa-refresh"></i> -->
                    Atrás
                </button>
                </div>

                <div class="col-sm-12 col-md-8 col-lg-8 mb-2" *ngIf="step === params.length -1">
                    <button type="submit" class="btn btn-block" style="background-color: #0195ec !important; color: white !important;"
                    rippleEffect>
                        <i class="fa fa-floppy-o" aria-hidden="true"></i>
                        Guardar
                    </button>
                </div>

                <div [ngClass]="step == 0 ? 'col-sm-12 col-md-12 col-lg-12 mb-2' : 'col-sm-12 col-md-8 col-lg-8 mb-2' " *ngIf="step < params.length -1">
                    <button type="submit" class="btn btn-block" style="background-color: #0195ec !important; color: white !important;"
                    rippleEffect>
                        <!-- <i class="fa fa-floppy-o" aria-hidden="true"></i> -->
                        Siguiente
                    </button>
                </div>
            </div>

            <div *ngIf="!ConseguiData">
            <h2>Estimado cliente no se pudo cargar la data, comuniquese con soporte</h2>
            </div>
        </form>
            <!-- Modal to add new user Ends-->
    </div>


</ng-container>

<!-- style="margin: auto; margin-top:25%; max-width: 500px; background-color: aliceblue; display: flex; flex-direction: column; border-radius: 20px;" -->

<div *ngIf="isSidebarOpen" class="slideout-content add-new-user div-centrar modal-content pt-0" style="min-height: auto;">

        <div class="modal-header mb-1">
            <!-- APP TITLE -->
            <h5 class="modal-title" id="exampleModalLabel" style="position: sticky; top: 0;">Detalles de Rechazo</h5>
            <!-- END APP TITLE -->
            <button *ngIf="!noClose" type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()">
            <i class="icon ion-close"></i>
            <!-- × -->

            </button>
        </div>
<!--
        <div class="modal-header">
            <h2 style="margin: 0;">Detalles de Rechazo</h2>
            <button *ngIf="!noClose" type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="closeModal()">
                <i class="icon ion-close"></i>
            </button>
          </div> -->

        <div class="modal-body" style="min-height: 300px; max-height: 100vw;">
            <div class="left-align">
                <p style="font-weight: 600;">Nombre de la Promoción Rechazada:</p>
                <p class="center-text">{{ dataSelected.element.Nombre }}</p>
              </div>

              <div class="left-align">
                <p style="font-weight: 600;">Descripción:</p>
                <p class="center-text">{{ dataSelected.element.Descripcion }}</p>
              </div>

              <div class="left-align">
                <p style="font-weight: 600;">Detalle:</p>
                <p class="center-text">{{ dataSelected.element.Descripcion }}</p>
              </div>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
            <button type="button" class="btn btn-block" style="background-color: #0195ec !important; color: white !important;"
            rippleEffect (click)="closeModal()">
                <i class="fa fa-floppy-o" aria-hidden="true"></i>
                Aceptar
            </button>
        </div>

</div>
