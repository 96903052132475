import { Component, EventEmitter, Input, OnDestroy, Output, HostListener, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { IEstructura, IEstructura2, IEstructuraCampo } from 'src/app/models/Estructure';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';
import { dbFullService } from 'src/app/services/dbFull.service';
import { HelperService } from 'src/app/services/helper.service';
import { InputTableService } from 'src/app/services/input-table.service';
import { LogsService } from 'src/app/services/logs.service';
import { environment } from '../../../../environments/environment';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { isArray } from 'lodash';
import { ApiClubService } from 'src/app/services/api-club.service';
import { Icb_EmpresaUser } from 'src/app/models/tables';
import { Icb_QR } from '../../../models/tables';
import { error } from 'jquery';
import { Title } from 'chart.js';
import { FormValidationService } from 'src/app/services/form-stepper/form-validation.service';



const exclusions = [
  'cb_SucursalDir',
  'cb_PromoProductos',
  'cb_Filtro',
  'cb_ProductosFotos',
  'cb_ProductosSucursal'
]

const exclusionsAnyQuerys = [
  'cb_ProductosSucursal',
  'cb_PromoSucursal'
]

let Estructura = {
  TitleHead: "Franquicia",
  Table: "cb_Franquicia",
  Inputs: [],
  Value:[]//"idUserEmp_3jKgVz"
}

@Component({
  selector: 'app-new-user-sidebar-stepper',
  templateUrl: './new-user-sidebar-stepper.component.html',
  styleUrls: ['./new-user-sidebar-stepper.component.scss']
})
export class NewUserSidebarStepperComponent implements OnDestroy {
  @Input() isSidebarOpen: boolean = false;
  @Input() element:any;
  @Input() params: IEstructura2[] = [];
  @Input() excelColumnsNames: string[] = [];
  @Input() optionToExecute: string = '';
  //AGREGADO UN INPUT PARA EL ID DE LAS ACCIONES
  @Input() idAction!: string;
  @Input() dataSelected: any;
  @Input() idEmpConvCreate: string = '';
  @Input() relations: any[] = [];
  @Input() noClose: boolean = false;
  @Input() TableEdit: string[] = [];
  @Input() onSubmit: ((vars: {[key: string]: unknown}) => void) | undefined; // Este método permite evitar enviar cualquier dato a base de datos, en cambio de disparar esta funcion (sólo si se recibe)
  // ESTE INPUT SIRVE PARA AGREGAR DATA DEFAULT DESDE EL MAINCRUD
  @Input() dataDefault!: {tableName: string, field:string, value: string}[];
  @Output() SalidaClickMenu: EventEmitter<any> = new EventEmitter();
  /** Permite verificar si se puede mostrar algun campo */
  @Input() canShowInput: {name: string, check(vars: {[key: string]: any}): boolean}[] | null = null; // name = Nombre del campo || check = método que se llamará para verificar si se puede mostrar el campo asociado
  @Input() beforeSubmit?: (vars: {[key: string]: unknown}, tableName: string) => Promise<{[key: string]: unknown}>;

  public contador: number = 0;
  public marginSize: number = 0;
  public step: number = 0;
  public fullname:any;
  public username:any;
  public email:any;
  private regex = /_/
  public DataCampos:any ="";
  public DataKeys:any[] =[];
  // public CampoValor = new Map();
  public ConseguiData = true;
  public userID: string = "";
  public formDatas: IFormData[] = [];
  public idToSend: string = '';
  private ResponseTables: any [] = [];
  public areNextingOnForm: boolean = true;
  private vinculateInputs = [
    { propiedadOrigen: 'CantCupones', propiedadDestino: 'CuponesDiponible' },
    // Aquí puedes agregar más objetos para vincular otras propiedades
  ];

  private inputsToHide = ['CuponesDiponible'];
  //BANDERA BOOLEANA PARA VERIFICAR SI UN DATO YA HA SIDO REGISTRADO EN
  //LA AUDITORIA
  private isRegisteredLog: boolean = false;
  private vars: {[key: string]: unknown} = { };
  //TEMPORAL: RESOLUCION DE LOS BANNERS PRINCIPALES
  public currentResolutionImage!: {tableName: string,  typeResolution?: string, width: number, height: number, widthMob?: number, heightMob?: number};
   public resolutionImges: {tableName: string,typeResolution?: string, width: number, height: number, widthMob?: number, heightMob?: number}[] = [
   {tableName:"cb_LandingBanner",typeResolution: "primary",width:1485,height:460,widthMob:820,heightMob:780},
   {tableName:"cb_LandingBanner",typeResolution: "secondary",width:640,height:480,widthMob:539,heightMob:269},
   {tableName:"cb_BannerPublic",width:827,height:180,widthMob:370,heightMob:180},
   {tableName:"cb_AdvertisingBanner",width:968,height:200 ,widthMob:370,heightMob:180}


  ]
  public dataLogin!: Icb_EmpresaUser;

  @HostListener('window:resize', ['$event'])
  @ViewChild('form') formElement?: ElementRef;
  onResize(event: Event): void {
    const windowWidth = window.innerWidth;
    this.marginSize = 0.1 * windowWidth;
    this.calculateMargin();
  }

  constructor(
    private _dbFullService : dbFullService,
    private gsnackbar: GenericSnackbarService,
    private logs: LogsService,
    private helper: HelperService,
    private router: Router,
    private inputService: InputTableService,
    private _SesionstorageService: SesionstorageService,
    private auth: AuthService,
    private apiclub: ApiClubService,
    private cdr: ChangeDetectorRef,
    private formValidator: FormValidationService
    ) {
  }
  triggerSubmit() {
    if(this.formElement){
      const formElement = this.formElement.nativeElement;

      // Crear un nuevo evento de tipo 'submit'
      const event = new Event('submit', {
        bubbles: true, // Permite que el evento burbujeé
        cancelable: true // Permite cancelar el evento si es necesario
      });
  
      // Disparar el evento en el formulario
      formElement.dispatchEvent(event);
      console.log(formElement);
      
    }
  }
  ngOnInit() {

    let ParamsTemporal:any[] = []
    // console.log('this.params', this.params)
    //Esto sirve para configurar en cada componente que se llame a este si desea que se vea una tabla en especifico para editar
    //this.getParamActive().Table
    if(this.TableEdit.length>0){
      this.TableEdit.forEach((elementEdit:string,index:number) => {
        // console.log(elementEdit);
        let indexEdit = this.params.findIndex((element:any) => element.Table === elementEdit )
        if(indexEdit != -1){
          ParamsTemporal.push(this.params[indexEdit])
        }

        if(this.TableEdit.length - 1 === index) {this.params = ParamsTemporal; this.relations = []}
      });
    }
    // console.log("asi quedo el params")
    // console.log(this.params);
    this.setParams();
    // this.calculateMargin();
    // console.log(this.getParamActive());

    // console.log(this.dataSelected)
    //COmenzamos aqui
    const currentTable = this.getParamActive().Table;
    console.log("TABLA ACTUAL: "+currentTable);
    switch(currentTable){
      case "cb_LandingBanner":
        let dataBanner: any  = this.dataDefault.find(data=>data.tableName===currentTable);
        if(dataBanner){
          const data = this.resolutionImges.find(data=>data.typeResolution===dataBanner.field)!;
          console.log(data);
          this.currentResolutionImage = this.resolutionImges.find(data=>data?.typeResolution===dataBanner.value)!;
        }
      break;
      case "cb_AdvertisingBanner":
        let dataBanner2  = this.resolutionImges.find(data=>data?.tableName===currentTable)!;
        // console.log(dataBanner2);
       if(dataBanner2)  this.currentResolutionImage = dataBanner2;
      break;
      case "cb_BannerPublic":
      let  dataBanner3  = this.resolutionImges.find(data=>data?.tableName===currentTable)!;
        if(dataBanner3)  this.currentResolutionImage = dataBanner3;
      break;
    }
    // if(this.dataDefault){
    //   const dataLandingBanner = this.dataDefault.find(data=>data.tableName==="cb_LandingBanner");
    //   if(dataLandingBanner){
    //     const data = this.resolutionImges.find(data=>data.typeResolution===dataLandingBanner.field)!;
    //     console.log(data);
    //     this.currentResolutionImage = this.resolutionImges.find(data=>data?.typeResolution===dataLandingBanner.value)!;
    //   }
    // }
  }

  ngOnDestroy(): void {
    this.FormDataActive.vars = {};
  }

  lastValue: unknown;
  lastResult: any;

  PrepareValueMap(value: unknown): any {
    // console.log(this.dataSelected)
    if(this.lastValue === value) return this.lastResult || [undefined, undefined];
    else {
      this.lastValue = value;

      if(typeof value === "string" && /_/.test(value || "")) {
        const [lng, lat] = value!.split("_");

        return this.lastResult = [Number(lng), Number(lat)];
      }
    }
    return this.lastResult = [undefined, undefined];

  }

  // param: IEstructura = {
  //   TitleHead: '',
  //   Table:'',
  //   Inputs:[],
  //   Value:[],
  //   Order:[],
  //   Type:''
  // };

  get FormDataActive() {
    if(!this.formDatas[this.step]) {
      this.formDatas[this.step] = new IFormData();
    }
    return this.formDatas[this.step];
  }

  calculateMargin(){
    const windowWidth = window.innerWidth;
    this.marginSize = 0.1 * windowWidth;
    // console.log(this.marginSize);
    const windowHeight = window.innerHeight;
    // console.log(windowWidth, windowHeight);
  }

  getParamActive() {
    // if(this.params[this.step] && this.params[this.step].Order?.find(t => t.TipoDato === "Map")) {
    //   console.log("MAPA:", this.params[this.step].Order?.find(t => t.TipoDato === "Map"));
    // }

    return this.params[this.step];
  }

  sendMessage(event:any,data: any = '') {
    if( data !== '' && data.hasOwnProperty('Status') ) data.Status = 1;
    let Estructura ={
      evento:event,
      data: [data]
    }

    this.SalidaClickMenu.emit(Estructura)

  }
  // 5sTambYCkd
  // qMjTa7EG7t
  async setParams() {
    const param: IEstructura2 = this.getParamActive();
    // console.log(param.Table)

    try {
      const resp = await this._dbFullService.GetAllDataCampo("cb_Estructura", "TableName", param.Table);
      if(param.Table ==="cb_Featured"){
        const filtro = this.dataDefault?.find(valu=>valu.field=="type")
        // console.log(this.dataDefault);

        if(filtro){
          const valorEstruct:  TipoInput = resp.find(value=>value.Campo==="id")!;
          // console.log(filtro);

          switch(filtro?.value){

            case '0':
              valorEstruct.TipoDato = "Diccionario";
              valorEstruct.ValorDiccionario = "tec24";
              break;
            case '1':
              valorEstruct.TipoDato = "Diccionario";
              valorEstruct.ValorDiccionario = "tec4";
              break;
            default:
              break;
          }
          // this.isIDNANOID = false;
          // this.cdr.detectChanges();
        }

        // console.log(resp);


      }
    //   console.log(resp)

      param.Inputs = (resp as any);
      // console.log(param.Inputs)
      // console.log("Estructura Campos: ", resp)
      //Si tiene valores es un edit
      param.Value = param.Value?.filter(v => v !== undefined && v !== null);
    //   console.log(param.Value)
      param.Type = param.Value && param.Value.length > 0 ? "Update" : "Create";
      await this.VerificadorTipeRequest();
      // console.log(param.Value);

      this.hideFields(param);
      // console.log('getParamActive() después de setParams():', this.getParamActive());
    } catch (error) {
      console.error(error);
    }
  }

  private hideFields(param: IEstructura2) {
    param?.Order?.forEach(controlItem => {
      if (this.inputsToHide.includes(controlItem.Campo)) {
        controlItem.hidden = true;
        controlItem.Requerido = 0;
      }
    });
    param.visibleOrder = param?.Order?.filter(controlItem => !controlItem.hidden);
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  async VerificadorTipeRequest() {
    const param = this.getParamActive();
    // console.log("YEOOOOOOOOOOOOOOOOOOOOOOOO:", param)
    if (param.Type === "Create") {
      param.Order = param.Inputs
        .filter(e => {
          const check = this.canShowInput?.find(i => i.name === e.Campo)?.check;
          return !check || check(this.FormDataActive.vars);

        })
        .filter((i: TipoInput) => i.ShowInsert && !i.DownloadImg).sort((a: any, b: any) => (a.OrdenInsert - b.OrdenInsert));
    } else {
      // Busco por el campo llave
      let Position = param.Value[0].search(this.regex)
      let Campo = param.Value[0].slice(0,Position);
      let Valor = param.Value[0].slice(Position+1);


      try {
        // console.log(param)
        // console.log(param.Table)
        // console.log(Campo)
        // console.log(Valor)
        const resp = await this._dbFullService.GetAllDataCampo(param.Table, Campo, Valor);

        // console.log(resp)
        if (resp && resp.length > 0) {
          this.FormDataActive.vars = resp[0];
          // console.log("this.FormDataActive.vars")
          // console.log(this.FormDataActive.vars)
          this.idToSend = this.dataSelected.element[Campo];
          const formData = this.FormDataActive;
          /*
            * Step 1: Busco los keys de lo que me trajo la BD
            * Step 2: Los Ordeno excepto los que no voy a mostrar
            * Step 3: Agarro lo que no se muestran y los meto de ultimo en el arreglo
          */
          Object.entries(resp[0]).forEach(([keyOriginal, valueKey]) => formData.vars[keyOriginal] = valueKey);
          param.Order = param.Inputs.filter((i: any) => i.ShowInsert).sort((a: any, b: any) => (a.OrdenInsert - b.OrdenInsert));
          console.log(param.Order);

          param.Order.push(...param.Inputs.filter((i: any) => !i.ShowInsert));


        } else {
          param.Type = "Create"
          this.ConseguiData = true;
          this.VerificadorTipeRequest();
        }
      } catch (error) {
        this.ConseguiData = false;
        console.error(error);
      }
    }

    // console.log('param VerificadorTipeRequest', param);
    // console.log('FormDataActive', this.FormDataActive);

    // Validar campos del formulario
    // const targetForm = document.getElementById('form');
    // await this.validateInputs(this.getParamActive(), this.FormDataActive, targetForm);

    // Devolver promesa resuelta
    return Promise.resolve();
  }

  /**
   * Submit
   *
   * @param form
   */

  async submitv2(event: any) {
    // console.log("LA ACCION: "+this.idAction);
    event.stopPropagation();
    event.preventDefault();
    // Valido por el formulario
    // console.log("VALIDACIÖN::::::", event.target.checkValidity());
    const formData = this.FormDataActive;

    //this.Exclusions(this.params[0], {...this.formDatas[0].vars});
    const param = this.getParamActive();

    if(param.Table==="cb_PromoProductos"){
      formData.vars["FechaLimite"]=environment.daysUseCupon;
    }
    else if(param.Table==="cb_LandingBanner"){
        const valor =  formData.vars["orderBanner"];
        if(this.dataDefault && isArray(this.dataDefault) && this.dataDefault.length>0){
          const dataField = this.dataDefault.find(filt=>filt.field==="type");
          formData.vars[dataField?.field!] = dataField?.value;
        }
        console.log(formData.vars);
        if(valor==='' || valor===0){
            const filters = [{name: 'type',value:  formData.vars["type"]}]
            const resp: any = await this._dbFullService.getDataTableCount('cb_LandingBanner',filters)
            formData.vars["orderBanner"] = resp+1;
        }
          console.log(valor);
    }
    else if(param.Table==="cb_QR" ){

      if(formData.vars["idQR"] && !formData.vars["imgQR"]){
      let DataQR: Icb_QR = {
          idEmpConv : formData.vars["idEmpConv"],
          urlRedirection: formData.vars["urlRedirection"],
          Status:  formData.vars["Status"],
          redirect: formData.vars["redirect"],
          idQR: formData.vars["idQR"]
        }
      try {
        const response = await this.apiclub.generateQR_Redirection(DataQR);
         formData.vars["imgQR"] = response;
      } catch (error) {
        formData.vars["imgQR"] = " ";
        console.log(error);
      }
      }
      if(!formData.vars["partnerAlly"]) formData.vars["idEmpConv"]="";
    }
    else if(param.Table==="cb_Featured"){
      const valor =  formData.vars["orden"];
      if(this.dataDefault && isArray(this.dataDefault) && this.dataDefault.length>0){
        const dataField = this.dataDefault.find(filt=>filt.field==="type");
        // console.log();
        // console.log();

        formData.vars[dataField?.field!] = dataField?.value;
      }
      // console.log(formData.vars);
      if(valor==='' || valor===0){
          const filters = [{name: 'type',value:  formData.vars["type"]}]
          const resp: any = await this._dbFullService.getDataTableCount(param.Table,filters)
          formData.vars["orden"] = resp+1;
      }
        // console.log(valor);
  }

    const targetForm = event.target;

    // console.log("Formulario actual:", formData); // Ver el estado actual del formulario
    // console.log("Parámetros activos:", param); // Ver los parámetros activos
    console.log("Target del evento:", targetForm); // Ver el target del evento
    console.log(typeof targetForm);
    
    // console.log("Step", this.step);
    
    if(param.Type=="Create"){
      if (this.FormDataActive.vars) {
        this.vinculateInputs.forEach((vinculacion: any) => {
          const propiedadOrigen = vinculacion.propiedadOrigen;
          const propiedadDestino = vinculacion.propiedadDestino;
          if (this.FormDataActive.vars[propiedadOrigen]) {
            this.FormDataActive.vars[propiedadDestino] = this.FormDataActive.vars[propiedadOrigen];

          }
        });
      }
    }

    
    // verify data
    // let hasError: any = this.validateInputs(param, formData, targetForm);
    // console.log("Errores de validación:", hasError); // Ver si hay errores de validación

    //VALIDAR LOS DATOS DE ENTRADA
    let hasError: any = await this.formValidator.validateInputs(param, formData, targetForm);
    // FIN VALIDACION DE LOS DATOS DE ENTRADA

    // const resp = await this.ExclusionFormSubmitv2(param,formData.vars)
    // //Esto es solo para validar si el codigo fijo ya existe para otra empresa
    // // console.log(resp);

    // if(resp.hasOwnProperty('status') && resp.status && resp.table=="cb_Promo" ){
    //   formData.error['CodigoFijo']="Este código ya se encuentra registrado"
    //   hasError = true;
    // }
    // else if(resp.hasOwnProperty('error')  && resp.table=="cb_Featured"){
    //   console.log(resp.campo);
    //   formData.error[resp.campo]=resp.message;
    //   hasError = true;
    // }
    // else if( !resp.status && resp.hasOwnProperty('table') && resp.table=="cb_QR" ){
    //   console.log("URL");
    //   formData.error['idEmpConv']="Este aliado ya tiene un QR asignado";
    //   hasError = true;
    // // }
    // console.log(hasError,"hasError");
    // console.log(formData.error);
    if(event.target.checkValidity()) {
      if (!hasError ) {
      //   const resp = await this.Exclusions(param,formData.vars);


        param.Order?.forEach((input: any, index: number) => {
          if (!(input.Campo in formData.vars)) {
            let defaultValue;

            // Se usa un valor por defecto en caso de que no tenga asignado ningun valor
            // NOTA: Es muy probable que nunca pase esto pero no está demás asegurar .___.
            switch (input.TipoDato) {
              case "Switch":
                defaultValue = false;
                break;

              case "Avatar":
                defaultValue = "assets/img/add-photo.jpg";
                break;
            }

            formData.vars[input.Campo] = targetForm[input.Campo]?.value || defaultValue;
            let valueSetting = targetForm[input.Campo]?.value;

            if (valueSetting !== undefined && valueSetting !== null && valueSetting !== '') formData.vars[input.Campo] = valueSetting;

            // console.log("Valor asignado a " + input.Campo + ":", formData.vars[input.Campo]); // Ver el valor asignado al campo
          }
        });


        if (this.step === this.params.length - 1) {
          this.vars = { };
          for(let formItem of this.formDatas) {
            for(let key in formItem.vars) {
              if(formItem.vars[key] instanceof Object) {
                // Transformar Arrays y objectos en algo que la base de datos pueda manejar
                formItem.vars[key] = JSON.stringify(formItem.vars[key]);
              }

              if(formItem.vars[key] !== undefined && (formItem.vars[key] !== null || this.vars[key] === undefined)) {
                this.vars[key] = formItem.vars[key];
              }
            }
          }
          // console.log("Variables:", this.vars);

          if(this.onSubmit) {
            this.onSubmit(this.vars);
          }
          else {
            Swal.fire({
              icon: "info",
              title: "Enviando",
              text: "Espere mientras enviamos los datos"
            })
            Swal.showLoading();
            this.ProcesoDataSteperv3();
          }
        }
        else {
          this.next();
        }
        targetForm.reset();
        // console.log("Formulario reseteado:", formData); // Ver el estado del formulario después de resetear
      }
    }
    else {
      // Invalido por el formulario (Notificar al usuario cual son los errores)
      event.target.reportValidity();
    }
  }

  closeModal(){
    // this.isSidebarOpen = false;
    let Estructura ={
      evento:'cerrar-detalle',
      data: ['']
    }
    this.SalidaClickMenu.emit(Estructura)

  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  async ProcesoDataSteperv3(){
    // this.SalidaClickMenu.emit();
    let message = ''
    if( this.params && this.params.length > 0){
      // console.log(this.params);

      const formData = this.formDatas[0];
      if(!this.params[0].Estructura) {
        this.params[0].Estructura = await this._dbFullService.GetAllDataCampo("cb_Estructura", "TableName", this.params[0].Table);
      }

      const pk = this.params[0].Estructura.find(est => est.PK);
      // console.log(pk);

     // this.Exclusions(this.params[0],this.formDatas[0])

      //Exclusiones para tablas que tiene Store Procedures
      const pass = exclusionsAnyQuerys.includes(this.params[0].Table);
        if(!pass){
          try {
            if(pk) {
              if(this.params[0].Type==="Create") {
                //   console.log("NONE MAN")

                  //Evitar codigo cableado
                  if( this.idEmpConvCreate && this.idEmpConvCreate.length > 0 ) {
                    formData.vars['idEmpConv'] = this.idEmpConvCreate;
                  }

                  message = 'Se ha creado un nuevo registro'
                  //Inserto Registro en BD
                  //Descomentar
                  //Preguntar si es una tabla de origen o destino
                  let TipoTable = this.TipoTable(this.params[0].Table);
                  switch (TipoTable) {
                    case "Origen":
                    //   console.log("Origen");
                      this.TablaOrigen(formData);
                    break;
                    case "Destino":
                    //   console.log("Destino");
                      this.TablaDestino(formData)
                    break;

                    default:
                    //   console.log("default");
                      this.Default(formData);
                    break;
                  }


                } else {


                  if(this.beforeSubmit) {
                    formData.vars = await this.beforeSubmit(formData.vars, this.params[0].Table);
                  }

                try {
                  await this.UpdateData(this.params[0], {...formData.vars });//...primaryKey
                } catch (error) {
                  console.error(error);
                  throw error;
                }
           

                  let resp: any;
                  // Actualizar datos
                  // const result: any = await this.UpdateData(this.params[0], {...formData.vars });//...primaryKey

                  const pk = this.params[0].Estructura.find(item => item.PK);


                  if(pk) {
                    try {
                      resp = (await this._dbFullService.GetAllDataCampo(this.params[0].Table, pk.Campo, formData.vars[pk.Campo]))[0]; // Obtener datos actualizados
                      //AL SER LA RESPUESTA POSITIVA SE APLICA LA AUDITORIA
                      message = `Se ha actualizado un registro`
                      if(!this.isRegisteredLog){
                        await this.logs.createLogs(this.idAction,message)
                        .then(resp=>{
                        //   console.log(resp);
                          //AHORA YA EL LOG HA SIDO REGISTRADO
                          this.isRegisteredLog=true;
                        })
                        ;

                      }
                    }
                    catch(err) {
                      console.error(err);
                    }
                  }

                  if(!resp) {
                    resp = {...formData.vars }; // En caso que no se haya podido algo, simplemente vamos aqui
                  }

                  // Esto ayudará para el proceso del StoreProcedure
                  this.ResponseTables.push({
                    Table: this.params[0].Table,
                    ...resp
                  })

                  this.sendMessage("update",resp);
                  this.params.splice(0,1);
                  this.formDatas.splice(0,1);
                  this.ProcesoDataSteperv3();
                  //primaryKey = {[pk.Campo]: resp[pk.Campo]};
                }
              }
              else throw new Error("No existe ningun campo 'primaryKey' en la tabla: " + this.params[0].Table);
            }
            catch(error) {
              console.error(error);
              this.onHandleErrorsPetitions(error);
              this.sendMessage("error", error);
            }

            //this.createLogs(message)
            // if(this.params.length === 1) {
            //   this.gsnackbar.runSnackBarNoCallback("Su registro ha sido completado de manera exitosa.", 1, true, 5000)
            // }
        }else{
          //Tiene un any queries
          this.ProcessStoreProcedures(pk);
        }
    }else{
      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "Correcto",
          text: "Se han enviado los datos exitosamente",
          footer: "Presione el boton para continuar",
          confirmButtonText: "Aceptar"
        })
      }, 400)
      message = 'Se ha creado un nuevo registro';
      //BOTON DE CREACION SUCCESFULY
      if(!this.isRegisteredLog){
        await this.logs.createLogs(this.idAction,message)
        .then((resp: any)=>{
            // console.log("RESPUESTA CREACION: ",resp)
        })
        ;
      }
      // const insertItem = this.insertToEnd.shift();
      // if(insertItem) {
      //   // this.InsertData(insertItem.params, {...insertItem.vars});
      //   // this._dbFullService.CreateData
      //   console.log("VARIABLES:", insertItem);
      // }
      // else {
        // console.log("END STEPER 3")
        //Si no hay nada termino


        let inputItem: {
          table: string,
          campoOrigen: string,
          vars: {[key: string]: any}
        } | undefined;
        while(inputItem = this.inputService.GetInput()) {
          try {
            // console.log("PROMISE1")
            const estructura: {
              idEstructura: number,
              TableName: string,
              PK: number,
              Campo: string,
              TipoDato: string,
              HeadListName: string,
              PlaceHolder: string,
              ShowList: number,
              ShowInsert: number,
              MaxLen: number,
              Requerido: number,
              ExpresionReg: string,
              OrdenList: number,
              OrdenInsert: number,
              RelacionOtrasTablas: string,
              ValorDiccionario: string
            }[] = await this._dbFullService.GetAllDataCampo("cb_Estructura", "TableName", inputItem.table);

            const PK = estructura.find(item => item.PK);
            // console.log("PROMISE2")

            if(PK) {
              const value = this.ResponseTables.find(res => res[String(inputItem?.campoOrigen)])?.[inputItem.campoOrigen];
            //   console.log("INSERTANDO:::", inputItem.table, PK.Campo, {...inputItem.vars, [inputItem.campoOrigen]: value});
              const resolved: any = await this._dbFullService.CreateData(inputItem.table, PK.TipoDato == "AutoIncrement" ? '' : PK.Campo , {...inputItem.vars, [inputItem.campoOrigen]: value});
              //AGREGAMOS EL REPORTE


            //   console.log("PROMISE3")
              this.ResponseTables.push({
                Table: inputItem.table,
                ...resolved
              });
            }
            else throw new Error("No existe ningun campo primary key en la tabla: " + inputItem.table);
          }
          catch(err) {
            console.error((err as any).response.data);
          }
        }

        this.ResponseTables = [];
        return;
      // }
    }

  }


  Exclusions(DataTable:any,Data:any){
    // Generar codigo QR para aliado
    if(!DataTable.hasOwnProperty("Table")) return;
    if(DataTable.Table == 'cb_EmpresaConvenio'){
      // console.log(Data);
      // console.log(DataTable)
      if(Data.hasOwnProperty("idEmpConv") && Data.idEmpConv!=""){

        let data: Icb_QR = {
          idEmpConv: Data.idEmpConv,
          redirect: false,
          Status: true,
          urlRedirection: "",
        }
        this._dbFullService.CreateData("cb_QR","idQR",data).then((resp:any)=>{
        // console.log("RESPUESTA: EMPRESA")
        // console.log(resp);
        this.apiclub.generateQR_Redirection(resp).then(respRedirection=>{
          // console.log("RESPUESTA: QRRRR")
          // console.log(respRedirection);
          const dataQR = resp;
          dataQR.imgQR = respRedirection;
          this._dbFullService.UpdateData("cb_QR","idQR",resp).then(resp3=>console.log(resp3));
        }
        );
        }).catch(error=>console.error(error))
        
        const { username, clave } = this.helper.crearUsername(Data.nombre, Data.rif);
        console.log(username); // Salida: "juan_perez_gomez"
        console.log(clave);    // Salida: "v_12345678_9"// console.log(username);
        const newUser: Icb_EmpresaUser ={
          Nombre: Data.nombre.trim(),
          Avatar: Data.ImgLogo.trim(),
          Clave: clave,
          Email: Data.emailContactoPrincipal.trim(),
          idRol: "uiVGBPD5Jcw2cJV8",
          Login: username,
          Movil: Data.contactoPrincipal,
          Status: 1,
          idEmpConv: Data.idEmpConv
        }
        this.createUserEmpresa(newUser).then(resp=>{
          // console.log(resp);
        }).catch(error=>console.error(error));
      }
      return;
    }

    if(DataTable.Table == 'cb_Promo'){
      if(Data.hasOwnProperty("CodigoFijo") && Data.CodigoFijo!=""){
        Data.Action="Create"
        this.apiclub.ValidateCodiF(Data)
        .then((res) => {
          // console.log(res);
         //resolve(res);
        })
        .catch((error) => {
          console.log(error);
         // reject(error);
        });
      }
    }
    else if(DataTable.Table==="cb_QR"){
      let DataQR: Icb_QR = Data;
      try {
         this.apiclub.generateQR_Redirection(DataQR).then(response=>{
          DataQR["imgQR"] = response;
          this._dbFullService.UpdateData(DataTable.Table,"idQR",DataQR)
         });

      } catch (error) {
        DataQR.imgQR = "";
        this._dbFullService.UpdateData(DataTable.Table,"idQR",DataQR)
        console.error(error);
      }

    }
  }


  ExclusionsForm(Value:any,Tittle:string){

    switch (Tittle) {
      case "TipoCanjeo":
        if(Value === 1 || Value==="1"){

          const Formulario = this.getParamActive().Order;
          if(Array.isArray(Formulario) && Array.isArray(this.params[0].Estructura) ) {

              const index = this.params[0].Estructura.findIndex((DataEstruct) => DataEstruct.Campo=="CodigoFijo")
              console.log(index,"index");
              console.log( this.params[0].Estructura);
              
              if(index !=-1){
                this.params[0].Estructura[index].ShowInsert=1
                this.params[0].Estructura[index].Requerido=1
                Formulario.splice(3, 0,(this.params[0].Estructura[index] as any ))

              }

          }

        }else{

          const Formulario = this.getParamActive().Order;
          if(Array.isArray(Formulario)) {
            const index = Formulario.findIndex((DataEstruct) => DataEstruct.Campo=="CodigoFijo")
            console.log(index,"index");
            console.log( Formulario);
            console.log(Formulario[index]);
            
            if(index!=-1){
              this.formDatas[0].vars["CodigoFijo"]=""
              this.formDatas[0].vars["Requerido"]=0
              Formulario.splice(index, 1);
            }

         }
        }
        break;

      // case "partnerAlly":
      //   if(Value === 1 || Value==="1"  || Value==true){
      //     const Formulario = this.getParamActive().Order;
      //     if(Array.isArray(Formulario) && Array.isArray(this.params[0].Estructura) ) {

      //         const index = this.params[0].Estructura.findIndex((DataEstruct) => DataEstruct.Campo=="idEmpConv")
      //         if(index !=-1){
      //           this.params[0].Estructura[index].ShowInsert=1
      //           this.params[0].Estructura[index].Requerido=1
      //           Formulario.splice(3, 0,(this.params[0].Estructura[index] as any ))

      //         }

      //     }

      //   }else{
      //     const Formulario = this.getParamActive().Order;
      //     if(Array.isArray(Formulario)) {
      //       const index = Formulario.findIndex((DataEstruct) => DataEstruct.Campo=="idEmpConv")
      //       if(index!=-1){
      //         this.formDatas[0].vars["Requerido"]=0
      //         Formulario.splice(3, 1);
      //       }

      //    }
      // break;
        // }
  }
  }
// ++
//  --
  ExclusionSwitch(Value:any,dataStruct:any){
    // console.log(dataStruct);
    //VERIFICO SI EXISTE VALORES EN LA PROPIEDAD
    if(dataStruct.TipoDato == "Switch" && dataStruct.fielldHide){
      //CONVIERTO EN ARRAY EL VALOR DE LOS CAMPOS A OCULTAR
      const arrayFields: any[] = JSON.parse(dataStruct.fielldHide);
      // console.log(arrayFields);
      // console.log(Value);
      //SI EL VALOR ESTA EN 1 O VERDADERO
      arrayFields.forEach(field=>{

        const Formulario = this.params[0].Estructura;
        const FormActiveIStructs = this.getParamActive().Order;
        // console.log(FormActiveIStructs);
        const valueDeconcatenated = field.split("--");
        const index = Formulario!.findIndex((DataEstruct) => DataEstruct.Campo==valueDeconcatenated[0]);
        const indexParam =FormActiveIStructs?.findIndex(struct=>struct.Campo == valueDeconcatenated[0]);
        // console.log(Boolean(Number(valueDeconcatenated[1])) == Value);
        const valueCast = typeof(Value) == "boolean" ? Value : Boolean(Value)
        if(Boolean(Number(valueDeconcatenated[1])) == valueCast){
          this.params[0].Estructura![index].ShowInsert=1
          this.params[0].Estructura![index].Requerido=1
         if(indexParam==-1) FormActiveIStructs!.push(this.params[0].Estructura![index] as any );
         else FormActiveIStructs![indexParam!].ShowInsert = 1;
          FormActiveIStructs!.sort((a,b)=>a.OrdenInsert-b.OrdenInsert);
        }
        else{

        if(indexParam!=-1){
        // console.log(indexParam);
        switch(this.params[0].Estructura![index!].TipoDato){
                case "String":
                 this.formDatas[0].vars[valueDeconcatenated[0]]=""
                break;
                case "Numero":
                 this.formDatas[0].vars[valueDeconcatenated[0]]=0;
                 break;

              }
            if(indexParam){
            this.params[0].Estructura![index].ShowInsert = 0;
            this.params[0].Estructura![index].Requerido = 0;
            }
              // this.formDatas[0].vars.["Requerido"]=0
              FormActiveIStructs!.splice(indexParam!, 1);
              console.log(FormActiveIStructs);

            }
      }
          })
      // if(Value === 1 || Value==="1" || Value){

      //   const Formulario = this.params[0].Estructura;
      //   const FormActiveIStructs = this.getParamActive().Order;
      //   arrayFields.forEach(field=>{
      //     const valueDeconcatenated = field.split("--");
      //     console.log(Boolean(Number(valueDeconcatenated[1])));
      //     const index = Formulario!.findIndex((DataEstruct) => DataEstruct.Campo==valueDeconcatenated[0]);
      //     console.log(Boolean(Number(valueDeconcatenated[1])) == Value);
      //     if(index!=-1 && Formulario![index].ShowInsert === 0 && Boolean(Number(valueDeconcatenated[1])) == Value){
      //       console.log(index);
      //       this.params[0].Estructura![index].ShowInsert=1
      //       this.params[0].Estructura![index].Requerido=1
      //       FormActiveIStructs!.splice(0, 0,(this.params[0].Estructura![index] as any ))
      //       FormActiveIStructs!.sort((a,b)=>a.OrdenInsert-b.OrdenInsert);
      //     }

      //   })



      // }
      //    // CASO CONTRARIO SE OCULTAN LOS VALORES
      // else{

      //   const Formulario = this.getParamActive().Order;
      //   arrayFields.forEach(field=>{

      //     if(Array.isArray(Formulario)) {
      //       const valueDeconcatenated = field.split("--");
      //       const indexParam = this.params[0].Estructura?.findIndex(struct=>struct.Campo == field);
      //       console.log(Boolean(valueDeconcatenated[1]));
      //       const index = Formulario.findIndex((DataEstruct) => DataEstruct.Campo==valueDeconcatenated[0])
      //       if(index!=-1  && Boolean(Number(valueDeconcatenated[1])) != Value){
      //         switch( this.formDatas[0].vars[field].TipoDato){
      //           case "String":
      //            this.formDatas[0].vars[field]=""
      //           break;
      //           case "Numero":
      //            this.formDatas[0].vars[field]=0;
      //            break;

      //         }
      //       if(indexParam){
      //       this.params[0].Estructura![indexParam].ShowInsert = 0;
      //       this.params[0].Estructura![indexParam].Requerido = 0;
      //       }
      //         // this.formDatas[0].vars.["Requerido"]=0
      //         Formulario.splice(index, 1);
      //       }

      //    }
      //   })

      // }
    }
  }
  ExclusionFormSubmitv2(DataTable:any,Data:any): Promise<any>{
    return new Promise((resolve,reject)=>{
      if(DataTable.Table == 'cb_Promo'){
        if(Data.hasOwnProperty("CodigoFijo") && Data.CodigoFijo!=""){
          console.log(Data);
          Data.Action="Get"
          this.apiclub.ValidateCodiF(Data)
          .then((res:any) => {
            res.table = "cb_Promo";
            console.log("res");
           return resolve(res);
          })
          .catch((error) => {
            console.log(error);
            return reject(error);
          });
        }else{
          resolve(false)
        }

      }
      else if(DataTable.Table == 'cb_Featured'){
        if(Data.hasOwnProperty("id") ){
          this.apiclub.validateOrden(Data)
          .then((res: any)=>{
            console.log(res);
            resolve(res)})
          .catch(error=>{console.log(error); reject(false)})
        }else{
          resolve(false)
        }
      }

      //     else if(DataTable.Table == 'cb_QR'){
      //   if(Data.hasOwnProperty("idEmpConv")){
      //     this.apiclub.ValidateQREnterprise(Data.idEmpConv, DataTable.Type == "Update" ? Data.idQR : "" )
      //     .then((res: any)=>{
      //       resolve(res)})
      //     .catch(error=>console.log(error))
      //   }else{
      //     resolve(false)
      //   }
      // }
      else{
        resolve(false)
      }

    })
  }

  private trysProcedureStoreRelations: number = 0;

  async ProcessStoreProcedures(pk:any){

        const relations = await this.hasRelations(this.params[0], 'tabladestino')
        if( relations != undefined ){
          const {  tablaorigen, campoorigen } = relations;

          let indexTable = this.ResponseTables.findIndex((T:any)=> T.Table === tablaorigen)
          if(indexTable != -1){
            // console.log("HERE DESTROYED")
            this.CreateStoreProcedures(pk,this.ResponseTables[indexTable],campoorigen);
            this.trysProcedureStoreRelations = 0;
          }else{
            if(this.trysProcedureStoreRelations < 3) {
            //   console.log("PARAMS:", this.params);
              if(this.params.length) {
                //Si no tengo el pk que necesito para insertar me muevo para la última posicion
                let temp = this.params[0];
                let temp2 = this.formDatas[0];
                this.params.push(temp);
                this.formDatas.push(temp2);
                this.params.splice(0,1);
                this.formDatas.splice(0,1);

                setTimeout(() => {
                  this.trysProcedureStoreRelations++;
                  this.ProcesoDataSteperv3();
                }, 1000);
              }
              else {
                // console.warn("No existen mas tablas");
                this.trysProcedureStoreRelations = 0;
              }
            }
            else {
              // console.warn("Tabla sin relación");
              this.trysProcedureStoreRelations = 0;
            }
          }
        }
  }

  async CreateStoreProcedures(pk:any,Data:any,CampoOrigen:string){
    // Preparar todos los StoreProcedure de los diccionarios
    // console.log('pase por acaaaaa9')
    // console.log("HERE PROCEDURE")
    await Promise.all(this.params[0].Inputs.map(async (input:any) => {
    //   console.log("HERE PROCEDURE2")
      if(input.TipoDato === "Diccionario") {

        const dataDiccionario = await this._dbFullService.GetAllDataCampo("cb_Dictionary", "idUnico", input.ValorDiccionario);
        if(dataDiccionario.length && dataDiccionario[0].StoreProcedure) {
            // console.log("this.vars:", CampoOrigen, pk.Campo);
            // console.log(this.vars);
            // console.log(Data);
            // console.log(Data[CampoOrigen]);
          // Guardar
          this.GuardarStoresProcidius(dataDiccionario[0].StoreProcedure, Data[CampoOrigen], String(this.vars[pk.Campo]));
       }
      }
    }))
    this.params.splice(0,1);
    this.formDatas.splice(0,1);
    this.ProcesoDataSteperv3();
  }

  next(){

    if (this.step < this.params.length -1) {
      this.areNextingOnForm = true;
    //   console.log("aaa",this.params.values)
      this.step++;
      this.setParams()
      // if( this.dataSelected.type === 'EDITAR' ) this.getDataToEdit(this.dataSelected.element);
    }
  }

  back() {

    if(this.step > 0) {
      this.areNextingOnForm = false;
      this.step--;
      this.setParams();
    }
  }

  reset() {

    this.step = 0;
    this.formDatas = [];
  }

  async TablaOrigen(formData:any){
      try {
        const resp: any = await this.InsertData(this.params[0], {...formData.vars});
        //Verifico si la tabla tiene realciones con otra tabla
        const relations = await this.hasRelations(this.params[0], 'tablaorigen');
        if( relations != undefined ){
          let key = '';
          const { tabladestino, tablaorigen, campodestino, campoorigen } = relations;
  
          //Guardo las respuesta por si la necesito al terminar borro
          this.ResponseTables.push({
            Table:tablaorigen,
            ...resp
          })
  
          //Exclusiones para tablas que no deben llevar id generados por BD
          const pass = exclusions.includes(tabladestino);
  
          //Valido si tiene o no key por  defecto para la bd
            if(!pass){
              let indexTable = this.params.findIndex((T:any)=> T.Table === tabladestino)
              let idKey:any = this.params[indexTable].Inputs.filter((Campos:any)=>Campos.PK ==1);
              key = idKey[0]['Campo'];
            }
  
          //Le envio la tabla donde voy a insertar, los valores que me respondio la tablaorigen y la primera key en caso de tener
          try {
            const resp2: any = await this.InsertDataWithRelations(tabladestino, {...resp, key}, campoorigen, campodestino);
            this.params.splice(0,1);
            this.formDatas.splice(0,1);
            this.ResponseTables.push({
              Table: tabladestino,
              ...resp2
            });

            this.sendMessage("create", resp2);
            this.ProcesoDataSteperv3();
          } catch (error: any) {
            this.sendMessage("error", error);
            console.error(error);
            throw error;
          }
          return;
        }
        //primaryKey = {[pk.Campo]: resp[pk.Campo]};
        //Toca eliminar este registro y me vuelvo a llamar
        this.params.splice(0,1);
        this.formDatas.splice(0,1);
        this.ProcesoDataSteperv3();   
      } catch (error: any) {
       this.onHandleErrorsPetitions(error);
      }

  }

  async TablaDestino(formData:any){

    const relations = await this.hasRelations(this.params[0], 'tabladestino')
      if( relations != undefined ){
        let key = '';
        const { tabladestino, tablaorigen, campodestino, campoorigen } = relations;
        //Busco la respuesta de la tabla origen y la inserto donde lo necesito
        let indexTable = this.ResponseTables.findIndex((T:any)=> T.Table === tablaorigen)
        if(indexTable != -1){
          let DataTablaOrigen = this.ResponseTables[indexTable];

          //Exclusiones para tablas que no deben llevar id generados por BD
          const pass = exclusions.includes(tabladestino);
          //Valido si tiene o no key por  defecto para la bd
            if(!pass){
              let indexTable = this.params.findIndex((T:any)=> T.Table === tabladestino)
              let idKey:any = this.params[indexTable].Inputs.filter((Campos:any)=>Campos.PK ==1);
              key = idKey[0]['Campo'];
            }

          //Le envio la tabla donde voy a insertar, los valores que me respondio la tablaorigen y la primera key en caso de tener
          try {
            await this.InsertDataWithRelations(tabladestino, {...DataTablaOrigen,key},campoorigen,campodestino)
              .then((resp2:any)=>{
                // console.log("Respuesta InsertDataWithRelations");
                // console.log(resp2);
                  this.params.splice(0,1);
                  this.formDatas.splice(0,1);
                  this.ResponseTables.push({
                    Table:tabladestino,
                    ...resp2
                  });
                  this.sendMessage("create",resp2);
                  this.ProcesoDataSteperv3();
              })
              .catch((error:any)=>{
                console.error(error);
                throw error;
              })
          } catch (error: any) {
           this.onHandleErrorsPetitions(error);
          }

          return;
        }else{
            // console.log("Pase por else tabla destino")
          //Si no tengo respuesta de mi tabla origen me toca moverme a la última posición para que la tabla origen inserte el pk que necesito
          //Se debe mejorar esta lógica
          let temp = this.params[0];
          let temp2 = this.formDatas[0];
          this.params.push(temp);
          this.formDatas.push(temp2);
          this.params.splice(0,1);
          this.formDatas.splice(0,1);
          this.ProcesoDataSteperv3();
        }
      }
      else {
        this.params.splice(0,1);
        this.formDatas.splice(0,1);
        this.ProcesoDataSteperv3();
      }
  }

  async Default(formData:any){

    try {
      if(this.beforeSubmit) {
        formData.vars = await this.beforeSubmit(formData.vars, this.params[0].Table);
      }
  
      console.warn("ANTES DE LA RESPUESTA DEFAULT");
      const resp: any = await this.InsertData(this.params[0], {...formData.vars});
      this.ResponseTables.push({
        Table:this.params[0].Table,
        ...resp
      });
  
      this.sendMessage("create",resp);
      this.params.splice(0,1);
      this.formDatas.splice(0,1);
      this.ProcesoDataSteperv3();
    } catch (error: any) {
      console.error(error);
      this.onHandleErrorsPetitions(error);
    }
    
  }

  EventoDinamic(event:any,itemForm:any){
    console.log("DATA evento");
    // if(this.getParamActive().Inputs.length == 1 && event === "" || (isArray(event) && event.length == 0) )  {
    //   console.log(this.step);
    //  this.triggerSubmit();
    // }
    const campo = itemForm.Campo;
    this.ExclusionsForm(event,campo);
    this.ExclusionSwitch(event,itemForm);
    //METODO PARA MANEJAR LA EXCLUSIVIDAD
    delete this.FormDataActive.error[campo];
    this.FormDataActive.vars[campo] = event;

  }

  InsertData(param: any, value:any){
    return new Promise((resolve,reject)=>{
      // console.log(param);
      // console.log(value);

       let idKey:any = param.Inputs.filter((Campos:any)=>Campos.PK ==1) //Con esto se busca la llave primaria para que el backend la genere automáticamente
      if(idKey && idKey !=null){
        console.log(idKey[0]['Campo']);
        this._dbFullService.CreateData(param.Table, idKey[0]['TipoDato'] == "AutoIncrement" ? '' :idKey[0]['Campo'],value)
        .then((resp:any)=>{
          // console.log("Pase2")
          // console.log(param);
          // console.log(resp);
          this.Exclusions(param, resp);
            resolve(resp);


        })
        .catch((error:any)=>{
          // console.log("ERRRORR");

          console.error(error);
          reject(error);
        //   console.log("DIR ERROR: ", new Error().stack)
        })
      }
    });
  }

  InsertDataWithRelations(tableDestiny: any, value:any,pkorigen:string,pkdestino:string){
    // console.log(tableDestiny)
    // console.log(value)

    return new Promise(async (resolve,reject)=>{
      try {
        let indexTable = this.params.findIndex((T:any)=> T.Table === tableDestiny)
      if(indexTable != -1){
        //Esto se hace ya que slice no borra la posicion 0,0 por lo tanto debe ser 0,1 esa es la unica excepcion NO TOCAR
        let indexdelete = indexTable === 0 ? 1 : indexTable
        let Data =this.formDatas[indexTable].vars
        Data[pkdestino] = value[pkorigen];

          if(tableDestiny && tableDestiny != null){
            if(this.beforeSubmit) {
              Data = await this.beforeSubmit(Data, tableDestiny);
            }

            this._dbFullService.CreateData(tableDestiny, value.key, Data)
            .then((resp:any)=>{
              this.Exclusions(this.params[indexTable], resp);
              this.params.splice(indexTable,indexdelete);
              this.formDatas.splice(indexTable,indexdelete)
              // console.log("Pase1")
              resolve(resp);


            })
            .catch((error:any)=>{
              reject(error);
              console.error(error);
            })
          }

      }
      } catch (error) {
        console.error(error)
      }

    });


  }

  TipoTable(table:string){
    const found = this.relations.find((T:any) => table === T.tablaorigen);
    if(found && found != undefined) {return "Origen"
    }else{
      const found2 = this.relations.find((T:any) => table === T.tabladestino);
      if(found2 && found2 != undefined) return "Destino";
    }
     return "Sinrelacion"
  }

   async UpdateData(param: any, value:any){
    const formData = this.FormDataActive;
    if(param.Table === 'cb_PromoProductos'){
      const consulta = await this._dbFullService.GetAllDataCampo('cb_PromoProductos', 'idPromo', value.idPromo)

    //   console.log(consulta[0].CantCupones)
      this.contador = consulta[0].CantCupones
    }
    //AQUI ES DONDE SE ACTUALIZAN LOS DATOS
    //AQUI DEBERIA AGREGAR EL ID DE LA ACCION E IMPRIMIRLO
    return new Promise((resolve,reject)=>{
      let idKey:TipoInput | undefined = param.Inputs.find((Campos:any)=> Campos.PK == 1) //Con esto se busca la llave primaria para que el backend la genere automáticamente
      // if(idKey && idKey !=null){

        if(idKey) {
          if(param.Table === 'cb_PromoProductos'){
            const cadena =+ value.CantCupones;
            if(this.contador !== cadena){
              if(cadena > this.contador){
                const result = (cadena - this.contador) + value.CuponesDiponible;
                value.CuponesDiponible = result;
                // console.log(result)
              } else if (cadena < this.contador && cadena < value.CuponesDiponible) {
                value.CuponesDiponible = cadena;
              } else if (cadena < this.contador && cadena > value.CuponesDiponible ) {
                value.CuponesDiponible = 0;
              }
            }
          }
          this._dbFullService.UpdateData(param.Table,idKey.Campo,value)
          .then((resp:any)=>{
            formData.vars['rowsUpdate']=resp.rowsUpdate
            resolve(formData.vars);
          })
          .catch((error:any)=>{
            reject(error);
            console.error(error);
          })
        }
      // }
    });
  }

  //! Para validar los inputs del formulario dinámico
  validateInputs(param:any, formData: any, targetForm: any){
    let hasError = false;

    param.Order?.forEach((input: TipoInput) => {
      let value = targetForm[input.Campo]?.value || Boolean(targetForm[input.Campo]?.checked) || formData.vars[input.Campo];
      const invalidRegExp = Boolean(input.ExpresionReg && RegExp(input.ExpresionReg).test(value instanceof Object ? JSON.stringify(value) : String(value)) === false);
      const invalidMaxLength = Boolean(input.MaxLen !== null && value?.length > input.MaxLen);
      let invalidRequired = Boolean(input.Requerido && ((value === undefined || value === null || (typeof value === "string" && !value.length))));

      if(input.Requerido && input.TipoDato === "eAvatar") {
          try {
            if(!JSON.parse(value).length) {
              invalidRequired = true;
            }
            else {
              invalidRequired = false;
            }
          }
          catch(err) {
            invalidRequired = true;
          }
      }

      // fix data
      if(invalidMaxLength) value = value.slice(0, input.MaxLen);
      if(invalidRegExp) {
        formData.error[input.Campo] = `El valor no cumple con la estructura deseada`;
      } else if(invalidRequired) {
        formData.error[input.Campo] = `Campo requerido*`
      } else {
        delete formData.error[input.Campo];
        return; // escape
      }
      hasError = true;
    })
    return hasError;
  }

  hasRelations(estructure: IEstructura, tableType: string){
    // console.log(estructure);
    // console.log(tableType);
    const { Table } = estructure;

    const found = this.relations.find((T:any) => Table === T[tableType]);
    return found
  }

  GuardarStoresProcidius(nameProcedure: string, valuePrimaryKey: string, value: unknown) {
    const query =  `CALL ${nameProcedure}('${value}','${valuePrimaryKey}');`;
    // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$", query);
    this._dbFullService.getAnyQuery(environment.bd, query)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.error(err);
      })
  }
  public getValueCount(tableName: string){

  }
  public createUserEmpresa(user: Icb_EmpresaUser){
    return new Promise((resolve,reject)=>{
      this._dbFullService.CreateData("cb_EmpresaUser","idUserEmp",user).then(resp=>{
        // console.log(resp);
        resolve({status: true, message:"se ha creado correctamente el usuario" })
      }).catch(error=>{
        console.error(error);
        reject({status: false, message:"ha habido problemas la crear el usuario" })
      })
    })
  }
  
  /**
   * Maneja los errores de las peticiones
   * @param errorObject el objeto de error
   */
  onHandleErrorsPetitions(errorObject: any){
    let message = "Ha ocurrido un error al registrar los datos. Por favor intente nuevamente";
    // console.error(errorObject);
    // Check if the error is a network error
    if(errorObject.hasOwnProperty("code") && errorObject.code === "ERR_NETWORK") 
      message = "Ha ocurrido un error al conectar con el servidor. Por favor compruebe su conexion a internet e intente nuevamente";
    // Check if the error is a bad response
    if(errorObject.hasOwnProperty("code") && errorObject.code === "ERR_BAD_RESPONSE")  
      message = "Ha ocurrido un error en el servidor. Por favor contacte a soporte";
    // Send the error message to the parent component
    this.sendMessage("error", errorObject.message);
    // Show the error message to the user
    Swal.fire({
      icon: "error",
      title: "Error",
      text: message,
    })
  }
}
