import { IUser } from "src/app/core/interfaces/IUser.interface";
import { IDummyDataResponse } from "src/shared-interfaces/IDummyDataResponse.interface";

export function getMockUserPublicInfo(): Promise<IDummyDataResponse<IUser>> {
  let data: IUser = {
    id: 1,
    username: "user",
    email: "user@gmail.com",
    roles: [],
    modules: [
        {
          id: 2,
          name: 'Inicio',
          order: 1,
          route: 'home',
          imgIcon:'../assets/icons/home.png',
          titleIcon:'../../../../../assets/icons/home2.png',
          styleRules: { icon: 'fa fa-home' },
          childrenModules: []
        },
        {
          id: 3,
          name: 'Aliados',
          order: 2,
          imgIcon:'../assets/icons/company2.png',
          titleIcon:'../../../../../assets/icons/company.png',
          styleRules: { icon: 'fa fa-building' },
          childrenModules: [
            {
              id: 4,
              name: 'Consulta',
              order: 1,
              imgIcon:'../assets/icons/consulta.png',
              titleIcon:'../../../../../assets/icons/consulta2.png',
              styleRules: { icon: 'fa fa-search' },
              route: 'empresa/consulta',
              childrenModules: []
            },
            // {
            //   id: 5,
            //   name: 'Nueva',
            //   order: 2,
            //   styleRules: { icon: 'fa fa-pencil' },
            //   route: 'empresa/nueva',
            //   childrenModules: []
            // },
            {
              id: 6,
              name: 'Productos',
              order: 3,
              imgIcon:'../assets/icons/productos.png',
              titleIcon:'../../../../../assets/icons/productos2.png',
              styleRules: { icon: 'fa fa-shopping-bag' },
              route: 'empresa/productos',
              contentRoute: '/modules/5/content-blocks',
              childrenModules: []
            },
            {
              id: 7,
              name: 'Usuarios',
              order: 4,
              imgIcon:'../assets/icons/usuarios.png',
              titleIcon:'../../../../../assets/icons/usuarios2.png',
              styleRules: { icon: 'fa fa-users' },
              route: 'empresa/usuarios',
              contentRoute: '/modules/5/content-blocks',
              childrenModules: []
            }
          ]
        },
        {
          id: 8,
        name: 'Promociones',
        order: 3,
        imgIcon:'../assets/icons/promociones.png',
        titleIcon:'../../../../../assets/icons/promociones2.png',
        styleRules: { icon: 'fa fa-tags' },
        childrenModules: [
          {
            id: 9,
            name: 'Consulta',
            order: 1,
            imgIcon:'../assets/icons/consulta.png',
            titleIcon:'../../../../../assets/icons/consulta2.png',
            styleRules: { icon: 'fa fa-search' },
            route: 'promociones/consulta',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 10,
            name: 'Revisiones',
            order: 2,
            imgIcon:'../assets/icons/revision.png',
            titleIcon:'../../../../../assets/icons/revision2.png',
            styleRules: { icon: 'fa fa-search' },
            route: 'promociones/revisiones',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 11,
            name: 'Observaciones',
            order: 3,
            imgIcon:'../assets/icons/observacion.png',
            titleIcon:'../../../../../assets/icons/observacion2.png',
            styleRules: { icon: 'fa fa-search' },
            route: 'promociones/estado-promociones',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 12,
            name: 'Destacados',
            order: 4,
            imgIcon: '../../../../../assets/icons/icons-star.png',
            titleIcon:'../../../../../assets/icons/icons-star.png',
            styleRules: { icon: '	fa fa-star' },
            route: 'promociones/featured',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
        ]
      },
      {
      id: 13,
      name: 'Configuración',
      order: 4,
      imgIcon:'../assets/icons/configuracion.png',
      titleIcon:'../../../../../assets/icons/configuracion2.png',
      styleRules: { icon: 'fa fa-cog' },
      childrenModules: [
          {
            id: 14,
            name: 'Categoria aliado',
            order: 1,
            imgIcon:'../assets/icons/categoria.png',
            titleIcon:'../../../../../assets/icons/categoria2.png',
            styleRules: { icon: '	fa fa-institution' },
            route: 'configuracion/cat_empresas',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          // {
          //   id: 13,
          //   name: 'Categoría Producto',
          //   order: 2,
          //   styleRules: { icon: 'fa fa-archive' },
          //   route: 'configuracion/cate_prod',
          //   contentRoute: '/modules/5/content-blocks',
          //   childrenModules: []
          // },
          {
            id: 15,
            name: 'Franquicias',
            order: 2,
            imgIcon:'../assets/icons/franquicias.png',
            titleIcon:'../../../../../assets/icons/franquicias2.png',
            styleRules: { icon: 'fa fa-industry' },
            route: 'configuracion/franquicias',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 16,
            name: 'Reportes',
            order: 3,
            imgIcon:'../assets/icons/iconreport2.png',
            titleIcon:'../../../../../assets/icons/iconreport.png',
            styleRules: { icon: 'fa fa-industry' },
            route: 'configuracion/reportes',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 17,
            name: 'Banners',
            order: 4,
            imgIcon:'../assets/icons/image.png',
            titleIcon:'../../../../../assets/icons/image2.png',
            styleRules: { icon: 'fa fa-industry' },
            route: 'configuracion/banners',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 18,
            name: 'QR',
            order: 5,
            imgIcon:'../assets/icons/qrSection.png',
            titleIcon:'../../../../../assets/icons/qrSection2.png',
            styleRules: { icon: 'fa fa-industry' },
            route: 'configuracion/QR',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          ]
      },
        {
          id: 19,
          name: 'Tags',
          order: 4,
          noDisplay: true,
          imgIcon:'../assets/icons/promociones.png',
          titleIcon:'../../../../../assets/icons/promociones2.png',
          styleRules: { icon: 'fa fa-cog' },
          childrenModules: [
            {
              id: 20,
              noDisplay: true,
                name: 'Promociones',
                order: 1,
                imgIcon:'../assets/icons/promociones.png',
                titleIcon:'../../../../../assets/icons/promociones2.png',
                styleRules: { icon: '	fa fa-star' },
                route: 'tags/promociones',
                contentRoute: '/modules/5/content-blocks',
                childrenModules: []
              },
              {
                id: 21,
                name: 'Cupones',
                order: 1,
                imgIcon:'../assets/icons/promociones.png',
                titleIcon:'../../../../../assets/icons/promociones2.png',
                styleRules: { icon: '	fa fa-ticket' },
                route: 'tags/cupones',
                contentRoute: '/modules/5/content-blocks',
                childrenModules: []
              },
              // {
              //   id: 19,
              //   name: 'Perfil',
              //   order: 1,
              //   styleRules: { icon: '	icon ion-ios-person' },
              //   route: 'tags/perfil',
              //   contentRoute: '/modules/5/content-blocks',
              //   childrenModules: []
              // },
            ]
        },
      //   {
      //     id: 20,
      //   name: 'Logs',
      //   order: 3,
      //   styleRules: { icon: 'fa fa-tags' },
      //   childrenModules: [
      //     {
      //       id: 21,
      //       name: 'Consulta',
      //       order: 1,
      //       styleRules: { icon: 'fa fa-search' },
      //       route: 'logs/consulta',
      //       contentRoute: '/modules/5/content-blocks',
      //       childrenModules: []
      //     }
      //   ]
      // },
        {
          id: 22,
          name: 'Validación QR',
          order: 4,
          imgIcon:'../assets/icons/qr.png',
          titleIcon:'../../../../../assets/icons/qr2.png',
          route: 'validation-qr',
          styleRules: { icon: 'icon ion-ios-person' },
          childrenModules: []
        },
        {
          id: 23,
          name: 'Perfil',
          order: 4,
          imgIcon:'../assets/icons/perfil.png',
          titleIcon:'../../../../../assets/icons/perfil2.png',
          route: 'perfil',
          styleRules: { icon: 'icon ion-ios-person' },
          childrenModules: []
        },

      ]
    }

  const res = { data }
  return new Promise((resolve, reject) => {
    resolve(res)
    // setTimeout(() => {
    // }, 100);
  });
}
