import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { ITab } from 'src/app/core/interfaces/ITabs.interface';
import { ReportCouponsComponent } from './reportCoupons/reportCoupons.component';
import { ReportAlliesComponent } from './reportAllies/reportAllies.component';
import { ReportGiftcardComponent } from './report-giftcard/report-giftcard.component';
import { AuthService } from 'src/app/services/auth.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit{
	components: ITab[] = [
		{component: ReportCouponsComponent,title: "Reporte Cupones"},
		{component: ReportAlliesComponent,title: "Reporte Aliados"},
	]
	public areLoadingComponents = true;
	constructor(private _SesionstorageService: SesionstorageService, private auth: AuthService, private helper: HelperService) {
		
	this.initComponents();	
	 }
	// 
		// {component: ReportGiftcardComponent,title: "Reporte Tarjeta Regalo"},
	ngOnInit(): void {
	}

	public async initComponents() {
		const { Data } = this._SesionstorageService.GetSesionStorage('login')
		const actions = await this.auth.retrieveActions(Data.idRol)
		const options = actions.filter( (e: any) => e.Componente === 'ReportGiftcardComponent')
		console.log(options);		
		if(options && options.length > 0) this.components.push({component: ReportGiftcardComponent,title: "Reporte Tarjeta Regalo"});
		console.log(this.components);
		this.areLoadingComponents = false
	}
}
