import { Injectable } from "@angular/core";
import { SesionstorageService } from "./sesionstorage.service";
import { dbFullDataBase, dbFullTable } from "src/assets/lib/dbfullv2";
import { Icb_ComponentesAcciones, Icb_LogSystem } from '../models/tables';
import { environment } from "src/environments/environment";
import axios from "axios";
import { HttpHeaders } from '@angular/common/http';
import { DecodedtokenService } from "./decodedtoken.service";
import { BehaviorSubject, interval,filter, takeWhile, tap, Subscription } from "rxjs";
import { DataTableService } from "./datatable.service";
import { PushNotificationService } from "./pushNotification.service";
import { Router } from "@angular/router";
import { CleanCacheService } from "./clean-cache.service";
import { ApiClubService } from "./api-club.service";

interface IUserMaster {
    Clave: string,
    Email: string,
    Login: string,
    Status: string,
    admin: boolean,
    idRol: string,
    idUser: string,
    createdAt: string,
    updatedAt: string
}

interface IUserEmpresa {
    Avatar: string,
    Clave: string,
    Email: string,
    Login: string,
    Movil: string,
    Nombre: string,
    Status: number,
    admin: boolean,
    idRol: string,
    idUserEmp: string,
    updatedAt: string,
    createdAt: string,
    idEmpConv: string,
}

@Injectable({
    providedIn: "root"
})
export class AuthService {

    public cb_ComponentesAcciones: dbFullTable<Icb_ComponentesAcciones>;
    
    public tokenExpirationTime: number =0;

    private tokenSubscription!: Subscription; 
    
    public tokenSubject = new BehaviorSubject<number>(0);

    public expiringTimeSoon = 300000; // 2 min
    constructor(
        public _sessionStorage: SesionstorageService,
        private _DecodedtokenService:DecodedtokenService,
        private _datatable: DataTableService,
        private _notifications: PushNotificationService,
        private router: Router,
        private cacheServ: CleanCacheService,
        private apiClub: ApiClubService,
    ) {
        
        const token: any = this._sessionStorage.GetSesionStorage("login");

        if(this.isUserAuthenticaded()) {
          this.tokenExpirationTime = token.exp*1000 ;
          this.startTokenTimer();
        }
      
        
        const db = new dbFullDataBase(environment.bd);
        this.cb_ComponentesAcciones = db.CreateChild("cb_ComponentesAcciones");
     }
    
    /**
     * Permite obtener información acerca del usuario logueado actualmente, ó null en coso que no se encuentre logueado
     * @returns Datos asociado al usuario logueado
     */
    public GetUser(): (IUserMaster | IUserEmpresa) | null {
        try {
            const user: any = this._sessionStorage.GetSesionStorage("login").Data;

            if(user) {
                return user;
            }
        }
        catch(err) {
            console.error(err);
        }
        return null;
    }

    /**
     * Validar si el usuario logueado es administrador
     * @returns valor booleano
     */
    public isMaster(): boolean {
        return Boolean(this.GetUserMaster());
    }

    /**
     * validar sie l usuario logueado es usuario empresa
     * @returns valor booleano
     */
    public isUserEmpresa(): boolean {
        return Boolean(this.GetUserEmpresa());
    }

    /**
     * Permite obtener los datos del usuario sólo cuando cumple con la estructura master
     * @returns Datos asociado del master usuario o nul en caso que no exista
     */
    public GetUserMaster(): IUserMaster | null {
        const user = this.GetUser();
        return (user && this.checkUserMaster(user)) ? user : null;
    }

    /**
     * Permite obtener los datos del usuario sólo cuando cumple con la estructura user empresa
     * @returns Datos asociado del usuario de la empresa ó null en caso que no exista
     */
    public GetUserEmpresa(): IUserEmpresa | null {
        const user = this.GetUser();
        return (user && this.checkUserEmpresa(user)) ? user : null;
    }

    /**
     * Permite validar si el dato del usuario cumple con la estructura master
     * @param user datos asociado del usuario
     * @returns valor booleano
     */
    public checkUserMaster(user: IUserMaster | IUserEmpresa): user is IUserMaster {
        return user.admin;
    }

    /**
     * Permite validar si el dato del usuario cumple con la estructura userEmpresa
     * @param user datos asociado del usuario
     * @returns valor booleano
     */
    public checkUserEmpresa(user: IUserMaster | IUserEmpresa): user is IUserEmpresa {
        return !user.admin;
    }

    retrieveActions(roleID: string): Promise<any>{

        return new Promise<void>((resolve, reject) => {
            
            this.cb_ComponentesAcciones.GET_ANY_QUERY<any>({ query: `SELECT * FROM ComponentesAcciones WHERE idRol = '${roleID}'` })
            .then((data: any) => resolve(data))
            .catch((err: any) => { reject(err); console.log(err); })
        })
        
    }
    
    public async getIP(): Promise<any>{
    try {
        let token = this._sessionStorage.GetSesionStorage("token");
        if(!token) return ;
        const headers = new HttpHeaders().set('tknlg', `Bearer ${token}`);
        let config ={
            headers: {
                'tknlg': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
           
            }                                               
    const ip = (await axios.get(`${environment.URLApiLocal}/whatismyip`,config)).data.ip;
    return ip;    
    } catch (error) {
        console.error(error);
    }   
      
    
    }
    getToken() { 
        return this._sessionStorage.GetSesionStorage('token'); 
    }
    getDecodedToken() { 
        const token = this.getToken(); 
        const decodedToken = this._DecodedtokenService.DecodedToken(token);
        return decodedToken;
    }
    isTokenExpiringSoon(): boolean {
        const currentTime = new Date().getTime();
        const {exp} = this.getToken(); 
        return exp*1000 - currentTime <= 300000; // 5 minutos
    }
    public setExpirationTime(time: number) {
      this.tokenExpirationTime = time;
    }
    async refreshToken() {
         const response: any = await this.apiClub.refreshToken();
          if(response.hasOwnProperty('tknlg')){
            this._sessionStorage.SetSesionStorage('token',JSON.stringify(response.tknlg))
            const tokenResponse = this._DecodedtokenService.DecodedToken(response.tknlg);;
            this._sessionStorage.SetSesionStorage('login',JSON.stringify(tokenResponse));
            console.log(tokenResponse,"tokenResponse");
            this.setExpirationTime(tokenResponse.exp*1000);
            return true;
          }
          return false;
    }
      startTokenTimer() {
        
        if(this.isUserAuthenticaded()){
            this.tokenExpirationTime = this.getDecodedToken().exp*1000;
        
        this.tokenSubscription =  interval(1000).pipe(
                // takeWhile(() => (this.tokenExpirationTime-3000000)-new Date().getTime() > 0),
                tap(() => {
                  const currentTime = new Date().getTime();
                  const timeLeft = (this.tokenExpirationTime) - currentTime;
                  if (timeLeft <= this.expiringTimeSoon  && timeLeft > 0) { // 5 minutos
                    this.tokenSubject.next(timeLeft);
                  }
                  else if( this.isUserAuthenticaded() && timeLeft <= 0) {
                    this.tokenSubject.next(timeLeft);
                    this.logout();

                  }
                  }) 
              ).subscribe();
        }
    
      }
      
      isUserAuthenticaded(): boolean {
        const token = this.getToken();
        return token ? true : false;
      }
      isTokenExpired(): boolean {
        return this.tokenExpirationTime <= new Date().getTime();
      }

      public logout(): void {
        this._datatable.onCleanData();
        const user = this._sessionStorage.GetSesionStorage("login");
        if (user !== null) {
            sessionStorage.clear();
            this._notifications.stopConnection();
            this.tokenExpirationTime = 0;
    
            // Cancela la suscripción si existe
            if (this.tokenSubscription) {
                this.tokenSubscription.unsubscribe();
            }
    
            this.router.navigate(['/signin']);
            this.cacheServ.clear();
        }
    }
      renewTokenSubscription() {
        this.startTokenTimer();
      }
}
