<div class="card bd-primary tableContainer">
    <div class="card-header bg-primary tx-white">Estadísticas</div>
    <div class="card-body pd-sm-30" style="max-height: 600px; height: 100%; overflow: auto;">
        
      
      <div class="d-inline-block">
        <div
        class="btn_filter" (click)="showModalFilter()">
            FILTRO <i class="icon ion-stats-bars"></i> 
            <!-- ion-navicon-round -->
        </div> 
        <button *ngIf="isfilteredTable" class="btn btn-danger btn_no_filter"
        
        (click)="stopFilter()"
        >No filtrar</button>
      </div>
   
      <p class="mg-b-20 mg-sm-b-30"> Registro de cupones y promociones generados y canjeados</p>

      <button  [disabled]="areExportingData || rows.length === 0" class="btn btn-success mx-1" (click)="onClickButtonExport()">{{areExportingData ? "Generando reporte..."  :"Descargar reporte"}}</button>
     <div *ngIf="showFilter">
        <app-filter-table [formDatas]="dataForm" [columnsInput]="columnsFilter" (outdata)="getDataFilter($event)" (SalidaClickMenu)="hideModalFilter()"></app-filter-table>
     </div>
    
      <div class="table-wrapper mt-3">
          <div class="scrollable-element subWrapper">
              <table  #datatable3 class="table display responsive nowrap tableWidth"  id="datatable3">
              </table>
              
          </div>
      </div>
      
     </div>
  </div>
  
