import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { filter, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-warning',
  template: `
<div *ngIf="isTokenExpiringSoon && areVisible && areShowedExpirationWarnings"  class="modal-background">
<div class="  modal-sm" style="background-color: white;">
    <div class=" modal-sm bd-0 tx-14">
      <div class="modal-header pd-x-20">
        <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">Atención</h6>
        <button (click)="onCloseModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pd-20">
        <p class="mg-b-5">Estimado usuario, su sesión está a punto de expirar. ¿Desea extender su sesión para continuar en la plataforma?.</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button (click)="onCloseModal()" type="button" class="btn btn-danger pd-x-20">Cancelar</button>
        <button (click)="onRefreshToken()" type="button" class="btn btn-primary pd-x-20" data-dismiss="modal">Si, extender</button>
      </div>
    </div>
  </div>
</div>
  `,
  styles: [`
    .warning {
      color: red;
      font-weight: bold;
     position: fixed;
      top: 100px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9999;
      background-color: #f8d7da;
      border: 1px solid #f5c2c7;
      padding: 10px;
      margin: 10px;
      height: 100px;
      max-width: 600px;
    }
    .modal-background {
      width: 100%; 
      height: 100%; 
      position: fixed; 
      top: 0; 
      left: 0; 
      background-color: rgba(0,0,0,0.4); 
      z-index: 1000; 
      display: flex; 
      justify-content: center; 
      align-items: center;
    }
  `]
})
export class WarningComponent implements OnInit, OnDestroy {
  isTokenExpiringSoon = false;
  private subscription!: Subscription;
  public areShowedExpirationWarnings: boolean = false;
  public areVisible: boolean = false;
  constructor(private authService: AuthService) {
    console.log("Warning component initialized"); // Añadir log para verificar la inicialización del component


  }
  onCloseModal() {
        this.areVisible = false;
  }
  ngOnInit(): void {
    this.subscribeToToken();
    }
// Método para suscribirse a las actualizaciones del token
private subscribeToToken(): void {
  this.subscription = this.authService.tokenSubject.subscribe(timeLeft => {

    if (timeLeft <= this.authService.expiringTimeSoon  && timeLeft > 0) { // 5 minutos
      if(!this.areShowedExpirationWarnings) this.areVisible = true;
      this.isTokenExpiringSoon = true;
      this.areShowedExpirationWarnings = true;
      
    } 
    else if(timeLeft <= 0) {
      this.isTokenExpiringSoon = false;
      this.areShowedExpirationWarnings = false;
      // this.authService.logout();
    }
  
  })
}
      // Método para renovar la suscripción cuando sea necesario
  renewSubscription(): void {
    this.authService.renewTokenSubscription();
    this.subscribeToToken();
  }
 async onRefreshToken(){
    this.onCloseModal();
    await this.authService.refreshToken();
    this.areShowedExpirationWarnings = false;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.areShowedExpirationWarnings = false;
    this.areVisible = false;
    this.isTokenExpiringSoon = false;
  }
}
