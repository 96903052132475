import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {
  @Input() data: TipoInput = {} as any;
  @Input() value: any = "";
  @Input() formData: IFormData = new IFormData();
  @Output() outdata = new EventEmitter<any>();
  public formatValue: string = ''
  constructor( private helper: HelperService ) {}

  /*
    @Param: el segundo parametro en formatDate es para determinar que dormato usar
      1- [day, month, year]
      2- [year, month, day]
  */ 
  ngOnInit(): void {
    this.formatValue = this.value ? this.helper.formatDate(this.value, 2) : "";
    // console.log("DATE FORMATED:", this.formatValue)
  }

  HandlerOnKey() {
    delete this.formData.error[this.data.Campo];
  }

  HanderOnChange(event: any) {
    // console.log(event.target.value);

    let value = event.target.value;
    const localdate: Date = this.formatDate(value);
    const today = new Date();
    if (localdate < today && this.data.ValorDiccionario && this.data.ValorDiccionario === 'today_date') {
      value = today.toISOString().split('T')[0]; // Set to today's date
    }
    event.target.value = value;
    
    // // console.log(localDate.toISOString());
    // this.formData.vars[this.data.Campo] = valueDate.toISOString();
    this.outdata.emit({name: this.data.Campo, value: localdate.toISOString()});
    
    delete this.formData.error[this.data.Campo];
    this.formData.vars[this.data.Campo] = localdate.toISOString();
  }

  formatDate(value: any) {
    const [fullyear, month, day] = value.split("-");
    const localDate = new Date();
    localDate.setFullYear(Number(fullyear));
    localDate.setMonth(Number(month)-1);
    localDate.setDate(Number(day));
    localDate.setHours(0);
    localDate.setMinutes(0);
    localDate.setSeconds(0);
    localDate.setMilliseconds(0);
    return localDate;
  }
}
