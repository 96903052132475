import {
  CdkDragDrop,
  CdkDragSortEvent,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { ICoreEstructure } from 'src/app/models/Estructure';
import { Attributes } from 'src/app/models/attributes';
import { TipoInput } from 'src/app/models/tipo.input';
import { dbFullService } from 'src/app/services/dbFull.service';
import { HelperService } from 'src/app/services/helper.service';
import { ObservableComponentsService } from 'src/app/services/observable-components.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';
import { environment } from 'src/environments/environment';


interface IColumnFilter{
  title: string;
  orderable: boolean;
  visible: boolean
}

@Component({
  selector: 'app-main-table',
  templateUrl: './main-table.component.html',
  styleUrls: ['./main-table.component.scss'],
})
export class MainTableComponent implements OnInit, AfterViewInit,OnDestroy {
  @ViewChild('datatable', { static: true }) datatable!: ElementRef;
  @Input() TableName: string = '';
  @Input() steps: any[] = [];
  @Input() dragable: boolean = false;
  @Input() dataForSucursales: any;
  @Input() attributes: Attributes = { title: '', description: '' };
  @Input() optionsDropdown: any;
  @Input() filterBy: { name: string; value: string | number }[] | null = null;
  @Output() dataRender: EventEmitter<any> = new EventEmitter();
  /** Permite verificar si se puede mostrar algun campo */
  @Input() canShowInput:
    | { name: string; check(vars: { [key: string]: any }): boolean }[]
    | null = null; // name = Nombre del campo || check = método que se llamará para verificar si se puede mostrar el campo asociado

  /**
   * Name of the function to load data
   */
  @Input() fnDataName: string = '';

  @Input() idColName: string = 'id';

  public showMenu: boolean = false;
  public FieldAndHead: any;
  public validFields: any = [];
  public WithCalculeSize: number = 0;
  public ArrayDataAll: any[] = [];
  public ArrayDataAllOriginal: any[] = [];
  public loading: boolean = true;
  public tableClasses: string[] = [];
  public loadingMessage: string = 'Cargando...';
  public DataFormOpenArray: ICoreEstructure[] = [];
  public element: any;
  public selectedFilters: string[] = [];
  public selectedFilters2: string[] = [];
  public showOptions: boolean = false;
  public control: string[] = [];
  public originalOptions: string[] = [];
  public optionPositions: { [key: string]: number } = {};
  public first: boolean = true;
  public control2: string[] = [];
  public columnsFilter: any[] = [];
  public estructuraInputs: TipoInput[] = [];
  enterpriseID: string = '0000';
  ROLETYPE: number = 0;
  private inicializedTable: boolean = false;
 public urlTree ?: UrlTree;
 public valueObservable: any;
 public table: any;
  constructor(
    // private _helperApi: HelperAPIPetService,
    private _helperApi: dbFullService,
    private _SesionstorageService: SesionstorageService,
    private helperServ: HelperService,
    private cdr: ChangeDetectorRef,
    private _dbFull: dbFullService,
    private Router: Router,
    private observable: ObservableComponentsService
  ) {}

  ngOnInit(): void {
    this.observable.notificationButton$.subscribe((resp: any)=>{
      console.log("EJECUTADO EL OBSEVABLE"); 
      this.valueObservable= resp;
      this.initRouter();
    })
    this.first = false;
   
    // this.enterpriseID = JSON.parse(sessionStorage.getItem("login") || '0000').idEmpConv;
    // this.enterpriseID = this._SesionstorageService.GetSesionStorage("login")?.idEmpConv;
    const { Data } = this._SesionstorageService.GetSesionStorage('login');
    // console.log(Data)
    this.ROLETYPE = Data.Type;
    this.enterpriseID = Data.idEmpConv;
    
    // console.log(this.enterpriseID)
  }

  consologuea(): void {
    const andi = this.helperServ.restructureOption('DETALLE', 'DETALLE');
    if (this.ArrayDataAll.length > 0) {
      if (
        this.ArrayDataAll[0].idAprobacion === 'Rechazado' ||
        this.ArrayDataAll[0].idAprobacion === 2
      ) {
        this.optionsDropdown = [andi];
      }
    }
  }

  searchByFilter(head: string) {
    console.log(this.ArrayDataAll);
    this.showOptions = true;
    console.log(this.optionsDropdown);
    this.first = true;
    const index = this.selectedFilters.indexOf(head);
    const ubicacion = this.FieldAndHead.head.indexOf(head);
    if (index === -1) {
      this.selectedFilters.splice(ubicacion, 0, head);
      this.selectedFilters2.splice(ubicacion, 0, this.validFields[ubicacion]);
    } else {
      this.selectedFilters.splice(index, 1);
      this.selectedFilters2.splice(index, 1);
    }
    this.control = this.selectedFilters;
    this.control2 = this.selectedFilters2;
    this.toOpenMenu();
    // this.consologuea()
  }

  isFieldImage(fieldName: string) {
    const field = this.estructuraInputs.find(
      (estructuraItem) => estructuraItem.Campo === fieldName
    );

    switch (field && field.TipoDato) {
      case 'Avatar':
        return true;

      default:
        return false;
    }
  }

  isFieldDate(fieldName: string) {
    const field = this.estructuraInputs.find(
      (estructuraItem) => estructuraItem.Campo === fieldName
    );

    switch (field && field.TipoDato) {
      case 'Date':
        return true;

      default:
        return false;
    }
  }

  isFieldStatus(fieldName: string) {
    const field = this.estructuraInputs.find(
      (estructuraItem) => estructuraItem.Campo === fieldName
    );
    switch (field && field.TipoDato) {
      case 'Switch':
        return true;

      default:
        return false;
    }
  }
  isFieldNumeric(fieldName: string) {
    const field = this.estructuraInputs.find(
      (estructuraItem) => estructuraItem.Campo === fieldName
    );

    switch (field && field.TipoDato) {
      case 'Numeric':
        return true;
      default:
        return false;
    }
  }
  formatDate(date: string) {
    return this.helperServ.formatDate(date, 1);
  }

  Cargar3() {
    return new Promise(async (resolve, reject) => {
      // const FullDBObj = new dbFullService();
      
      const DatosSend = { title: 'Control ' + this.TableName, data: [] };
      this.FieldAndHead = DatosSend;
      //Cargamos la estructura de la tabla
      this._helperApi
        .GetAllDataCampo('cb_Estructura', 'TableName', this.TableName)
        .then(async (Estructura: TipoInput[]) => {
          const ArgHead: any[] = [];
          try {
            //Filtramos los campos que se vn a ver en la tabla
            Estructura = Estructura.filter((e) => {
              const check = this.canShowInput?.find(
                (i) => i.name === e.Campo
              )?.check;
              return !check || check({});
            });
            //Ordenamos la estructura
            this.estructuraInputs = Estructura.sort(
              (a, b) => a.OrdenList - b.OrdenList
            );

          } catch (error) 
          {
            reject(error);
          }
          //AQUI AGREGGO LA CUESTION PARA EL FILTRO ANTES DE REALIZAR EL
          if(this.TableName ==="cb_Featured"){
            const filtro = this.filterBy?.find(valu=>valu.name=="type")
            if(filtro){
              const valorEstruct:  TipoInput = this.estructuraInputs.find(value=>value.Campo==="id")!;
              console.log(filtro);
              
              switch(filtro?.value){
              
                case '0':
                  valorEstruct.TipoDato = "Diccionario";
                  valorEstruct.ValorDiccionario = "tec21";
                  break;
                case '1':
                  valorEstruct.TipoDato = "Diccionario";
                  valorEstruct.ValorDiccionario = "tec22";
                  break;
                default: 
                  break;
              }
            }
            console.log(this.estructuraInputs);
            
           
          }
          try {
            Estructura.forEach(async (element: any, index: number) => {
              if (element.ShowList) {
                this.validFields.push(element.Campo);
                ArgHead.push(element);
              }
              this.selectedFilters2 = [...this.validFields];
            });
          } catch (error) {
            reject(error);
          }
          this.FieldAndHead.head = ArgHead;
          this.selectedFilters = [...this.FieldAndHead.head];
          this.WithCalculeSize = 100 / this.FieldAndHead.head.length;

          if (!this.fnDataName) {
            //! if superuser retrieve all; else retrieve only data with same idEmpConv than enterpriseID
            if (this.ROLETYPE === 0) {
              // console.log(this.TableName)
              this.ArrayDataAll = await this._helperApi.getAnyQuery(
                environment.bd,
                this.filterBy
                  ? `SELECT * FROM ${this.TableName} WHERE ${this.filterBy
                      .map(
                        (filter) =>
                          filter.name + ' = ' + "'" + filter.value + "'"
                      )
                      .join(' AND ')} AND Status = 1`
                  : ` SELECT * FROM ${this.TableName} WHERE Status = 1 `
              );
            } else {
              this.ArrayDataAll = await this._helperApi.getAnyQuery(
                environment.bd,
                this.filterBy
                  ? ` SELECT * FROM ${this.TableName} WHERE ${this.filterBy
                      .map(
                        (filter) =>
                          filter.name + ' = ' + "'" + filter.value + "'"
                      )
                      .join(' AND ')} AND Status = 1 AND idEmpConv = '${
                      this.enterpriseID
                    }'`
                  : ` SELECT * FROM ${this.TableName} WHERE Status = 1 AND idEmpConv = '${this.enterpriseID}'`
              );
            }
          } 
          else{
            const ArrayData = await (this._dbFull as any)[this.fnDataName](); //* If the fn data name is passed On Input
            const orderBy = this.orderDefaultTable(this.FieldAndHead.head);
            const columns = this.FieldAndHead.head as TipoInput[]; 
            if(orderBy){
              ArrayData.sort((a: any, b: any) =>
                a[columns[orderBy].Campo] > b[columns[orderBy].Campo] ? 1 : -1
              );
            }
            this.ArrayDataAll = ArrayData;
          }
          if (this.TableName === 'cb_LandingBanner') {
            this.ArrayDataAll.sort((a, b) => a.orderBanner - b.orderBanner);
          }
          this.orderData(this.TableName);

          this.consologuea();
          try {
            this.ArrayDataAllOriginal = this.ArrayDataAll.map((data: any) => ({
              ...data,
            }));
            let Diccionario: TipoInput[] = Estructura.filter(
              (value) => value.TipoDato === 'Diccionario'
            );

            if (Diccionario && Diccionario.length > 0) {
              Diccionario.forEach((EstructuraItem, index) => {
                this._helperApi
                  .GetAllDataCampo(
                    'cb_Dictionary',
                    'idUnico',
                    EstructuraItem.ValorDiccionario
                  )
                  .then((dataDiccionario: any[]) => {
                    const diccionario = dataDiccionario[0];

                    if (diccionario) {
                      this._helperApi
                        .getAnyQuery(
                          environment.bd,
                          'SELECT * FROM ' +
                            (diccionario.TableNameOrigen ||
                              diccionario.TableNameDestino)
                        )
                        .then((dataTable: any) => {
                          this.ArrayDataAll.forEach((data) => {
                            const listOfValues = dataTable.filter(
                              (item: any) =>
                                item[
                                  diccionario.idCampoOrigen ||
                                    diccionario.idCampoDestino
                                ] == data[EstructuraItem.Campo]
                            );

                            data[EstructuraItem.Campo] =
                              listOfValues
                                .map((itemValue: any) =>
                                  String(itemValue?.[diccionario.idCampoShow])
                                )
                                .join(', ') || data[EstructuraItem.Campo];
                          });
                          return resolve(true);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                if (index == Estructura.length - 1) return resolve(true);
              });
            } else return resolve(true);
          } catch (error) {
            reject(error);
          }
        });
    });
  }
  /*
   * @function loadDataField() metodo para obtener data y estrucutra por un campo generico
   */
  loadDataField(table: string, field: string, value: string) {
    const DatosSend = { title: 'Control ' + this.TableName, data: [] };
    this.FieldAndHead = DatosSend;

    this._helperApi
      .GetAllDataCampo('cb_Estructura', 'TableName', table)
      .then(async (Estructura: any) => {
        console.log(Estructura);
       
        this.estructuraInputs = Estructura;
        const ArgHead: any[] = [];
        Estructura.forEach(async (element: any) => {
          if (element.ShowList) {
            this.validFields.push(element.Campo);
            ArgHead.push(element);
          }
        });
        console.log('VALID FIELDS:', this.validFields);
        this.selectedFilters2 = [...this.validFields];
        this.FieldAndHead.head = ArgHead;
        this.selectedFilters = [...this.FieldAndHead.head];
        this.WithCalculeSize = 100 / this.FieldAndHead.length;

        if (!this.fnDataName)
          this.ArrayDataAll = await this._helperApi.getAnyQuery(
            environment.bd,
            'SELECT * FROM ' +
              table +
              ' WHERE ' +
              field +
              '=' +
              (typeof value === 'string' ? `'${value}'` : value)
          );
        else this.ArrayDataAll = await (this._dbFull as any)[this.fnDataName]();
        console.log('this.ArrayDataAll', this.ArrayDataAll);

        this.ArrayDataAllOriginal = [...this.ArrayDataAll];
        // await this._helperApi.GetAllDataCampo(table, field, value);
        if (this.ArrayDataAll && this.ArrayDataAll.length === 0) {
          this.loadingMessage = 'Sin registros';
        }
        this.loading = false;
        console.log('LOADING:', this.loading);

        console.table(this.ArrayDataAll);
        console.log(this.optionsDropdown);
        if(!this.inicializedTable){
          setTimeout(() => {
                          //inicializams las columnas
        this.columnsFilter = this.initColumnsFilter();
        this.inicializedTable = true;
        this.table =   ($(this.datatable.nativeElement) as any).DataTable({
          language: {
            searchPlaceholder: 'Buscar...',
            sSearch: '',
            info: 'Mostrando _START_ - _END_ de _TOTAL_ registros',
            lengthMenu: 'Mostrar _MENU_',
            paginate:  this.dragable ? {} : {
              previous: 'Anterior',
              next: 'Siguiente',
            } ,
          }
      },500);
      
        })

      }
      });
  }

  shouldShowRow(item: any): boolean {
    if (this.selectedFilters.length === 0) {
      return true;
    }

    for (const filter of this.selectedFilters) {
      if (item[filter]) {
        return true;
      }
    }

    return false;
  }
  handleImageError(event: any) {
    // console.error('Error loading image:', event);
    console.log(event.target);
    
      event.target.src = '../../../assets/img/add-photo.jpg';
    // }
  }
  
  

  toOpenMenu() {
    console.log(this.columnsFilter);
    setTimeout(() => {
      document.body.addEventListener('click', this.toCloseMenu);
    }, 200);
    this.showMenu = true;
  }

  toCloseMenu = () => {
    document.body.removeEventListener('click', this.toCloseMenu);
    if (!this.showOptions) {
      this.showMenu = false;
    }
    this.showOptions = false;
  };

  getData() {
    if (this.dataForSucursales) {
      this.loadDataField(
        this.dataForSucursales.table,
        this.dataForSucursales.Campo,
        this.dataForSucursales.id
      );

      // console.log("HERE GET daTA")
    } else {
      // console.log("HERE CARGAR")
      this.ArrayDataAll = [];

      this.Cargar3()
        .then((Data: any) => {
          this.initRouter()
          this.FieldAndHead.data = Data;
          this.orderData(this.TableName);
          console.log(this.ArrayDataAll);
          this.loading = false;
          console.log(Data);
          setTimeout(() => {
            //@ts-ignore

            if (!this.inicializedTable) {
              this.inicializedTable = true;
              let columns: any[] = [];
              //inicializams las columnas
              columns = this.initColumnsFilter();
              this.columnsFilter = columns;
          this.table =   ($(this.datatable.nativeElement) as any).DataTable({
                language: {
                  searchPlaceholder: 'Buscar...',
                  sSearch: '',
                  info: 'Mostrando _START_ - _END_ de _TOTAL_ registros',
                  lengthMenu: 'Mostrar _MENU_',
                  paginate:  this.dragable ? {} : {
                    previous: 'Anterior',
                    next: 'Siguiente',
                  } ,
                },  
                 columns: columns,
                 order: [[this.orderDefaultTable(this.FieldAndHead.head as TipoInput[]), 'asc']],
              });
            }
            
            this.table.draw();
          }, 500);
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    }
  }

  async createFormSteps() {
    for (const table of this.steps) {
      const estructure = await this._helperApi.GetAllDataCampo(
        'cb_Estructura',
        'TableName',
        table
      );

      const core = {
        TitleHead: this.attributes.title,
        Table: table,
        Estructura: estructure,
        Inputs: [],
        Value: [], //"idUserEmp_3jKgVz"
      };
      this.DataFormOpenArray.push(core);

      // console.log(this.DataFormOpenArray)
    }
  }

  ngAfterViewInit(): void {
    
   
    this.getData();
    this.createFormSteps();
    this.cdr.detectChanges();
  }

  setElement(element: any) {
    console.log('HERE MAN: ', element);
    // console.log("Elemento :::::: ", element);
    element.Tablaselect = this.TableName;
    this.element = element;
  }

  getOptionAndData(event: any) {
    console.log(event);
    this.dataRender.emit(event);
  }

  public drop = (event: CdkDragDrop<any>) => {
    console.log(event);
    try {
      console.log('drop');
      console.log( event.previousIndex);
      console.log(event.currentIndex);
  
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
   
      // this.orderData(this.TableName);
      const arrayOriginal: any[] = [...this.ArrayDataAllOriginal];
      const array: any[]= event.container.data;
      console.log(this.ArrayDataAllOriginal);
      console.log(event.container.data);
      
      if (this.TableName === 'cb_LandingBanner') {
        
        this.ArrayDataAll.forEach((data, index: number) => {
          data.orderBanner = index + 1;
          this._dbFull.UpdateData(this.TableName, this.idColName, data);
     
        });
         this.orderData(this.TableName);
      }
      else if (this.TableName === 'cb_Featured') {
        let objId = arrayOriginal.reduce((obj: any, item:any) => {
          obj[item.idFeatured] = item.id;
          return obj;
        }, {});
        console.log(objId);
        
        array.forEach((data, index: number) => {
          data.orden = index + 1;
          
          console.log(data);
         let auxName: any = data.id;
         data.id = objId[data.idFeatured];
          console.log(data.id);
          this._dbFull.UpdateData(this.TableName, this.idColName, data).then(res=>{console.log(res); data.id=auxName;console.log(data);
          }).catch(console.log)
          

        });
       
      }
      this.orderData(this.TableName);
    } catch (error) {
      console.error(error);
    }
  };
  public orderData(tableName: string){
    let columnOrder: string = ""
    switch(tableName){
      case "cb_LandingBanner":
      columnOrder = "orderBanner"
      break;
      case "cb_Featured":
      columnOrder = "orden"
      break;
    }
    if(columnOrder &&this.ArrayDataAll.length>0 ) this.ArrayDataAll.sort((a, b) => a[columnOrder]-b[columnOrder]);
    
  }
  async getDataElement(idKey: any,value:any){
    try {
      const query = `SELECT * FROM ${this.TableName} WHERE Status=1 AND ${idKey}='${value}'`
      const responseQuery = await this._helperApi.getAnyQuery(environment.bd,query);
      return Promise.resolve(responseQuery);    
    } catch (error) {
      return Promise.reject(error);
    }
  
  }
  async initRouter(){
    if(this.estructuraInputs.length>0){
    console.log(this.estructuraInputs);
    const value =   this.valueObservable ?? this._SesionstorageService.GetSesionStorage("dataRedirection");
    console.log(value);
    const estruc = this.estructuraInputs.find(estructura=>estructura.PK === 1);
    if(estruc && value){
      const paramData = value[estruc.Campo];
      console.log(paramData);
    if(paramData){
     const response: any[] = this.ArrayDataAll.length>0 ? this.ArrayDataAll.find(data=>data[estruc.Campo]===paramData) :  await this.getDataElement(estruc.Campo,paramData);
     console.log(response);
     this.setElement(response);
     let action = this.optionsDropdown.find((option:any)=>option.name=="EDITAR");
     const object = {
      type: "EDITAR",
      option: action,
      element: this.ArrayDataAll.length>0 ? response : response[0]
  }

   console.log(object);
   this.getOptionAndData(object);
  }  
  }

   
    }
 
    
  }
  isNotOrderableColumn(column: string) {
   return column === "Avatar" || column === "Opciones" || column === "Status";
  }
    toggleColumn(columnIndex: number) {
        console.log(this.table);        
        const column = this.table.column(columnIndex);
        
        column.visible(!column.visible());
     
          this.columnsFilter[columnIndex].visible = !this.columnsFilter[columnIndex].visible;
        const areAllInvisible = this.columnsFilter.every(column => !column.visible);
        if (areAllInvisible) {
          this.table.columns().visible(true);
          this.columnsFilter.forEach((column, index) => {
            this.columnsFilter[index].visible = true;
          })
        }
    }
  ngOnDestroy(): void {
    this._SesionstorageService.SetSesionStorage("dataRedirection","");
  }
  initColumnsFilter(): IColumnFilter[] {
    const headers = this.datatable.nativeElement.querySelectorAll('thead th');
  
    const columns: IColumnFilter[] = []
    headers.forEach((header: HTMLElement) => {
      const orderable = header.getAttribute('data-orderable') === 'true';
      const title = header.textContent ?? '';
        columns.push({ orderable, title, visible: true });
    });
    console.log(columns,"columns");
    
    return columns;
  }
  orderDefaultTable(columns: TipoInput[] | any[]): number | undefined {
    for (let i = 0; i < columns.length; i++) {
      if (!this.isNotOrderableColumn(columns[i].TipoDato)) {
        return i;
      }
    }
    return undefined;
  }
}
