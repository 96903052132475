import { Injectable } from '@angular/core';
import { IEstructura2 } from 'src/app/models/Estructure';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';
import { ApiClubService } from '../api-club.service';



interface ValidateOrdenResponse {
  status: boolean;
  message: string;
  campo?: string;
  error?: string;
  table?: string;
}
interface ValidationResponse {
  status: boolean;
  message?: string;
  campo?: string;
  error?: string;
  table?: string;
}
@Injectable({
  providedIn: 'root'
})


export class FormValidationService {
  constructor(private apiclub: ApiClubService) { }

    /**
     * Valida los inputs dinamicos segun los parametros de entrada
     * @param param IEstructura2 con la estructura de los inputs
     * @param formData IFormData con los datos que se estan validando
     * @param targetForm any con el formulario que se esta validando
     * @returns true si hay errores, false si no hay errores
     */
   public async validateInputs(param:IEstructura2, formData: IFormData, targetForm:  any){
      let hasError = false;
      if(param.Order){
       // Inicializamos un array para almacenar los resultados de las validaciones
        const validationResults = await Promise.all(
          param.Order.map(async (input: TipoInput) => {
            let value = targetForm[input.Campo]?.value || Boolean(targetForm[input.Campo]?.checked) || formData.vars[input.Campo];

            // Validamos si el valor cumple con la expresión regular
            const invalidRegExp = Boolean(
              input.ExpresionReg &&
              RegExp(input.ExpresionReg).test(value instanceof Object ? JSON.stringify(value) : String(value)) === false
            );

            // Validamos si el valor tiene la longitud máxima
            const invalidMaxLength = Boolean(input.MaxLen !== null && value?.length > input.MaxLen);

            // Validamos si el valor es requerido
            let invalidRequired = Boolean(
              input.Requerido &&
              (value === undefined || value === null || (typeof value === "string" && !value.length))
            );

            // Validaciones específicas para tipos de datos especiales como eAvatar
            if (input.Requerido && input.TipoDato === "eAvatar") {
              try {
                if (!JSON.parse(value).length) {
                  invalidRequired = true;
                } else {
                  invalidRequired = false;
                }
              } catch (err) {
                invalidRequired = true;
              }
            }

    // Ejecutamos el método que se encarga de realizar las validaciones asíncronas
    let validationsAsynchronus: any = await this.validateInputsAsynchronously(param, formData.vars, input.Campo);

    // Corregimos los datos si hay errores
    if (invalidMaxLength) value = value.slice(0, input.MaxLen);
    if (invalidRegExp) {
      formData.error[input.Campo] = `El valor no cumple con la estructura deseada`;
    } else if (invalidRequired) {
      formData.error[input.Campo] = `Campo requerido*`;
    } else if (
      validationsAsynchronus &&
      validationsAsynchronus.hasOwnProperty('status') &&
      !validationsAsynchronus.status
    ) {
      formData.error[validationsAsynchronus.campo] = validationsAsynchronus.error;
    } else {
      delete formData.error[input.Campo];
      return false; // No hay errores en esta validación
    }

    return true; // Hay un error en esta validación
  })
        );

        // Determinamos si hay algún error global
         hasError = validationResults.some(result => result === true);

        // Si no hay errores, puedes continuar con el flujo normal
        // if (!hasError) {
        //   console.log("Todas las validaciones fueron exitosas.");
        // } else {
        //   console.log("Se encontraron errores en las validaciones.");
        // }
      }
      // param.Order?.forEach(async (input: TipoInput) => {
      //   // TODO: Agregar validaciones para tipos de datos especiales como fechas, horas, etc
      //   let value = targetForm[input.Campo]?.value || Boolean(targetForm[input.Campo]?.checked) || formData.vars[input.Campo];
        
      //   // Validamos si el valor cumple con la expresion regular
      //   const invalidRegExp = Boolean(input.ExpresionReg && RegExp(input.ExpresionReg).test(value instanceof Object ? JSON.stringify(value) : String(value)) === false);
      //   // Validamos si el valor tiene la longitud maxima
      //   const invalidMaxLength = Boolean(input.MaxLen !== null && value?.length > input.MaxLen);
      //   // Validamos si el valor es requerido
      //   let invalidRequired = Boolean(input.Requerido && ((value === undefined || value === null || (typeof value === "string" && !value.length))));
        
      //   //Ejecutamos el metodo que se encarga de realizar las validaciones asincronas
      //   let validationsAsynchronus: any = await this.validateInputsAsynchronously(param, formData.vars);
    
      //   // TODO: Agregar validaciones para tipos de datos especiales como eAvatar
      //   if(input.Requerido && input.TipoDato === "eAvatar") {
      //       try {
      //         if(!JSON.parse(value).length) {
      //           invalidRequired = true;
      //         }
      //         else {
      //           invalidRequired = false;
      //         }
      //       }
      //       catch(err) {
      //         invalidRequired = true;
      //       }
      //   }
        
      //    // Corregimos los datos si hay errores
      //   if(invalidMaxLength) value = value.slice(0, input.MaxLen);
      //   if(invalidRegExp) {
      //     formData.error[input.Campo] = `El valor no cumple con la estructura deseada`;
      //   } else if(invalidRequired) {
      //     formData.error[input.Campo] = `Campo requerido*`
        
      //   } 
      //   else if(validationsAsynchronus &&  validationsAsynchronus.hasOwnProperty('status') && !validationsAsynchronus.status ){
      //     formData.error[validationsAsynchronus.Campo] = validationsAsynchronus.error;
      //   }
      //   else {
      //     delete formData.error[input.Campo];
      //     return; // escape
      //   }
        
      //   hasError = true;
      // })
       // Realizamos validaciones asincrona si es necesario
      //  let validationsAsynchronus: any = await this.validateInputsAsynchronously(param, formData.vars);
      //  if(validationsAsynchronus && validationsAsynchronus.hasOwnProperty('error') && validationsAsynchronus.hasOwnProperty('campo') && validationsAsynchronus.hasOwnProperty('status')  ){
      //   if(!validationsAsynchronus.status)  formData.error[validationsAsynchronus.Campo] = validationsAsynchronus.error;
      //   hasError = true;
      //  }
      return hasError;
    }

   /**
   * Valida los inputs de manera asincrona
   * @param param objeto IEstructura2 que contiene la estructura del formulario
   * @param Data objeto que contiene los datos del formulario
   * @returns Promesa que se resuelve con un objeto que contiene
   *          el estado (boolean), el detalle (string) y la tabla (string) de la validacion
   *
   *          Se utiliza para realizar validaciones asincronas, como por ejemplo, verificar si un campo
   *          existe en la base de datos.
   *          Si quieres introducir mas validaciones para ciertos campos de un formulario, puedes hacerlo
   *          Agregando un nuevo condicional con el nombre de la tabla. Luego dentro del mismo condicional 
   *          Agregas otra condicional con el nombre del campo que deseas validar y agregas la funcion  
   *          Ten en cuenta que el retorno de la respuesta debe ser con la interfaz de ValidationResponse
   *          El status debe estar en FALSE para que indique que hay un error
   */         
  async validateInputsAsynchronously(param:IEstructura2,Data:{[nameVar: string]: any},currentField?:string): Promise<ValidationResponse | void>{
    try {
      if(param.Table == 'cb_Promo'){
        if(currentField && currentField == "CodigoFijo" && Data["CodigoFijo"]!="")  return  await this.validateCodiF(Data);
        else if(currentField && currentField === "FechaFin" && Data["FechaFin"]!="") return  this.validateDatesPromo(Data);
        else return ;
      }
      else if(param.Table == 'cb_Featured' ){
        if(currentField && currentField == "id") return  await this.validateOrden(Data);
        else return ;
      }
      return ;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  /**
   * Valida de manera asincrona el objeto de datos proporcionado para el campo 'CodigoFijo'.
   * Llama al servicio ApiClubService para validar el valor de 'CodigoFijo'.
   * Invierte el estado devuelto por el API y establece un mensaje de error si el estado es falso.
   * 
   * @param Data - Un objeto que contiene los campos 'CodigoFijo', 'idEmpConv' y opcionalmente 'Action'.
   * @returns Una promesa que se resuelve con un objeto que contiene el estado de validacion, el detalle, la tabla, el campo y un mensaje de error opcional.
   */

  private async validateCodiF(Data:{[nameVar: string]: any}): Promise<ValidationResponse>{
    const validationData = {
      CodigoFijo: Data["CodigoFijo"],
      idEmpConv: Data["idEmpConv"],
      Action: Data["Action"] || "Get"
    };
    const res: {status: boolean, detail:string, table: string, campo: string, error?: string}  = await this.apiclub.ValidateCodiF(validationData) as {status: boolean, detail:string, table: string,campo: string} ;
    res.table = "cb_Promo";
    res.campo = "CodigoFijo";
    //Debo invertir el status porque la API lo devuelve invertido
    res.status = !res.status;
    if(!res.status) res.error = "Este código ya se encuentra registrado";
    return res;
  }

  private async validateOrden(Data:{[nameVar: string]: any}): Promise<ValidationResponse>{
    const response: ValidationResponse = await this.apiclub.validateOrden(Data) as ValidateOrdenResponse;
    // console.log(response);
    return response;
  }
  
  private  validateDatesPromo(Data:{[nameVar: string]: any}): ValidationResponse{
    const dateInicio = new Date(Data["FechaIni"]);
    const dateFin = new Date(Data["FechaFin"]);
    if(dateFin<dateInicio){
      return {
        status: false,
        table: "cb_Promo",
        campo: "FechaFin",
        error: "La fecha de fin debe ser posterior a la fecha de inicio"
      }
    }
     return {
      status: true
    }
  }
}
